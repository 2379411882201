import { useAuth0 } from '@auth0/auth0-react'
import { Spacer, Text, TextField, theme } from '@truepill/react-capsule'
import { TpButton } from 'components'
import ErrorBoundary from 'components/ErrorBoundary'
import { Pane, PaneContentWithScroll } from 'components/Pane'
import { AnimatePresence, motion } from 'framer-motion'
import usePane from 'hooks/usePane'
import { useNotesContext } from 'providers/NotesProvider'
import { FC, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

import { useCreateNotesMutation } from '../../generated/graphql'
import { useCustomerContext } from '../../providers/CustomerProvider'
import { logFailureTo } from '../../utils/exceptionHandler'
import { PinnedCustomerNote } from './PinnedCustomerNote'
import { UnpinnedCustomerNote } from './UnpinnedCustomerNote'

type Props = {
  title?: string
}

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`

const NoNotesText = styled(Text)`
  text-align: center;
  display: block;
  color: ${theme.colors['gray-500'].value};
`

export const NotesPanel: FC<Props> = ({ title }) => {
  const { user } = useAuth0()
  const { saveCurrentCustomer } = useCustomerContext()
  const {
    data: { pinnedNotes, unpinnedNotes },
    count: notesCount,
    resource_id,
    resource_type,
  } = useNotesContext()

  const { paneVisible, firstLoad, hidePane } = usePane('Note')
  const hidePaneRef = useRef(hidePane)

  useEffect(() => {
    return hidePaneRef.current
  }, [])

  const [noteText, setNoteText] = useState('')
  const [errorText, setErrorText] = useState('')
  const [isFocused, setFocus] = useState(false)
  const [createNote] = useCreateNotesMutation({ refetchQueries: ['notes'] })

  const postNote = () => {
    if (noteText.trim().length < 3) return setErrorText('Min length 3')
    createNote({
      variables: {
        comment: noteText,
        client: saveCurrentCustomer.customerSlug,
        resource_type,
        resource_id: resource_id || '',
        author_name: user?.name || '',
      },
    }).catch(logFailureTo('create note'))
    setErrorText('')
    setNoteText('')
    setFocus(false)
  }

  const cancel = () => {
    setErrorText('')
    setNoteText('')
    setFocus(false)
  }

  const handleClose = () => {
    setErrorText('')
    setNoteText('')
    setFocus(false)
    hidePane()
  }

  return (
    <ErrorBoundary>
      <Pane firstLoad={firstLoad} visible={paneVisible} onClose={handleClose} title={title ?? 'Notes'}>
        <TextField
          type="textarea"
          css={{
            resize: 'vertical',
          }}
          size="sm"
          placeholder="Enter note"
          value={noteText}
          state={errorText ? 'error' : 'default'}
          helperText={errorText}
          onFocus={() => setFocus(true)}
          onChange={e => setNoteText(e.target.value)}
          onKeyDown={event => event.key === 'Enter' && postNote()}
        />
        <AnimatePresence>
          {isFocused && (
            <>
              <motion.div
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: 1, height: 'auto' }}
                exit={{ opacity: 0, height: 0 }}
              >
                <Spacer />
                <ButtonWrapper>
                  <TpButton variant="primary-text" onClick={cancel}>
                    Cancel
                  </TpButton>
                  <Spacer size="xs" />
                  <TpButton onClick={postNote}>Post</TpButton>
                </ButtonWrapper>
              </motion.div>
            </>
          )}
        </AnimatePresence>
        <Spacer />
        <PaneContentWithScroll
          style={{ paddingRight: '0.25rem', height: isFocused ? 'calc(100vh - 19.5rem)' : 'calc(100vh - 15.3rem)' }}
        >
          {notesCount > 0 ? (
            [
              ...pinnedNotes.map(note => (
                <PinnedCustomerNote noteItem={note} key={`${note.source}-${note.sourceId}`} />
              )),
              ...unpinnedNotes.map(note => (
                <UnpinnedCustomerNote noteItem={note} key={`${note.source}-${note.sourceId}`} />
              )),
            ]
          ) : (
            <>
              <Spacer />
              <NoNotesText variant="body-sm">{`There aren't any notes here yet`}</NoNotesText>
            </>
          )}
        </PaneContentWithScroll>
      </Pane>
    </ErrorBoundary>
  )
}
