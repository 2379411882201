import { Text, theme } from '@truepill/react-capsule'
import { clientsLogos } from 'constants/clientsLogos'
import { useAccountContext } from 'providers/AccountProvider'
import { useCustomerContext } from 'providers/CustomerProvider'
import { FC, ReactNode, useMemo } from 'react'
import styled from 'styled-components'

import { formatLocalizedDateTime } from '../utils'
import { Chip } from './Chip'
import { CustomerLogo } from './CustomerLogo'

type Props = {
  children?: ReactNode
  orderCreatedDate?: Date
}

export const InfoHeader: FC<Props> = ({ children, orderCreatedDate }) => {
  const { saveCurrentCustomer } = useCustomerContext()
  const accountContext = useAccountContext()

  const dateCreated = useMemo(() => {
    const candidateDate = orderCreatedDate || accountContext.createdAt

    if (candidateDate) {
      return formatLocalizedDateTime(orderCreatedDate || accountContext?.createdAt || '')
    }

    return null
  }, [accountContext.createdAt, orderCreatedDate])

  const logo: string = useMemo(() => clientsLogos[saveCurrentCustomer.customerSlug], [saveCurrentCustomer])

  return (
    <AccountViewHeading>
      {logo && <CustomerLogo path={logo} label={saveCurrentCustomer.customerName} />}
      <div>
        <AccountTitle>
          {children || (
            <>
              <Text bold css={{ fontSize: '1.25rem' }}>
                {accountContext.email}
              </Text>
              <Chip state="info" label={saveCurrentCustomer.customerName} />
            </>
          )}
        </AccountTitle>
        {dateCreated && (
          <Text
            variant="body-sm"
            css={{ color: theme.colors['typography-medium'].value, lineHeight: theme.sizes.sm.value }}
          >
            <strong>Created on:</strong> {dateCreated}
          </Text>
        )}
      </div>
    </AccountViewHeading>
  )
}

const AccountViewHeading = styled.div`
  display: flex;
  height: ${theme.space['2xl'].value};
`

const AccountTitle = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.space.xs.value};
`
