import { createContext, FC, ReactNode, useCallback, useContext, useEffect, useReducer } from 'react'
import type { NoteResourceEnum } from 'types'

import { NotesPanel } from '../components'
import { ExternalNoteFragment, PinnedNoteFragment, useNotesLazyQuery } from '../generated/graphql'

export type NoteDataType = {
  pinnedNotes: PinnedNoteFragment[]
  unpinnedNotes: ExternalNoteFragment[]
}

export interface NotesType {
  accountId?: string | null
  patientToken?: string | null
  orderToken?: string | null
  resource_id?: string
  resource_type: keyof typeof NoteResourceEnum
  createdAt?: Date | string | null
}
export interface NotesProviderProps extends NotesType {
  count: number
  data: NoteDataType
  loading: boolean
  loadNotes: (noteType: NotesType) => void
  resetNotes: () => void
}

export const NotesContext = createContext<NotesProviderProps>({
  data: {
    pinnedNotes: [],
    unpinnedNotes: [],
  },
  loading: false,
  count: 0,
  loadNotes: () => undefined,
  resetNotes: () => undefined,
  accountId: '',
  patientToken: '',
  orderToken: '',
  resource_id: '',
  resource_type: 'USER',
})

interface NotesReducerState {
  data: NoteDataType
  count: number
  accountId: string | null
  patientToken: string | null
  orderToken: string | null
  resource_id: string
  resource_type: keyof typeof NoteResourceEnum
}

type NotesAction = {
  type: 'GET_NOTES_DATA' | 'UPDATE_RESOURCE' | 'RESET_NOTES_DATA'
  data?: NoteDataType
  count?: number
  accountId?: string | null
  patientToken?: string | null
  orderToken?: string | null
  resource_id?: string
  resource_type?: keyof typeof NoteResourceEnum
}

export const useNotesContext = () => useContext(NotesContext)

const NotesReducer = (state: NotesReducerState, action: NotesAction) => {
  const {
    type,
    data = {
      pinnedNotes: [],
      unpinnedNotes: [],
    },
    count = 0,
  } = action
  switch (type) {
    case 'RESET_NOTES_DATA':
      return {
        ...state,
        count: 0,
        data: {
          pinnedNotes: [],
          unpinnedNotes: [],
        },
      }
    case 'GET_NOTES_DATA':
      return { ...state, count, data }
    case 'UPDATE_RESOURCE':
      return { ...state, ...action }
    default:
      return state
  }
}

type Props = {
  children: ReactNode
}

export const NotesProvider: FC<Props> = props => {
  const [{ data, count, resource_id, resource_type }, dispatch] = useReducer(NotesReducer, {
    data: {
      pinnedNotes: [],
      unpinnedNotes: [],
    },
    count: 0,
    accountId: '',
    patientToken: '',
    orderToken: '',
    resource_id: '',
    resource_type: 'USER',
  })

  const [getNotes, { data: notes, loading: notesLoading }] = useNotesLazyQuery()

  useEffect(() => {
    if (!notesLoading) {
      dispatch({
        type: 'GET_NOTES_DATA',
        data: {
          pinnedNotes: notes?.notes?.pinnedNotes ?? [],
          unpinnedNotes: notes?.notes?.unpinnedNotes ?? [],
        },
        count: notes?.notes?.notesCount,
      })
    }
  }, [dispatch, notes, notesLoading])

  const loadNotes = useCallback(
    (noteType: NotesType) => {
      dispatch({ type: 'UPDATE_RESOURCE', data, ...noteType })
      getNotes({
        variables: {
          patientToken: noteType.patientToken,
          accountId: noteType.accountId,
          orderToken: noteType.orderToken,
        },
      }).catch(() => {
        console.error('Get Notes failed')
      })
    },
    [data, getNotes]
  )
  const resetNotes = useCallback(() => {
    dispatch({ type: 'RESET_NOTES_DATA' })
  }, [])

  const value: NotesProviderProps = {
    data,
    loading: notesLoading,
    count,
    accountId: '',
    patientToken: '',
    orderToken: '',
    resource_id,
    resource_type,
    loadNotes,
    resetNotes,
  }

  return (
    <NotesContext.Provider value={value} {...props}>
      {props.children}
      <NotesPanel />
    </NotesContext.Provider>
  )
}
