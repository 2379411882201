import { theme } from '@truepill/react-capsule'
import { FC, HTMLAttributes, ReactNode } from 'react'
import styled from 'styled-components'

interface StackProps extends HTMLAttributes<HTMLDivElement> {
  direction?: 'column' | 'row'
  justifyContent?: 'start' | 'end' | 'center' | 'space-between' | 'space-around' | 'space-evenly'
  alignItems?: 'flex-start' | 'flex-end' | 'center' | 'baseline' | 'stretch'
  children: ReactNode
}

export const Stack: FC<StackProps> = props => {
  const { direction = 'column', children, justifyContent = 'start', alignItems = 'flex-start', ...other } = props

  return (
    <StackRoot direction={direction} justifyContent={justifyContent} alignItems={alignItems} {...other}>
      {children}
    </StackRoot>
  )
}

const StackRoot = styled.div<StackProps>`
  display: flex;
  flex-direction: ${props => props.direction};
  justify-content: ${props => props.justifyContent};
  align-items: ${props => props.alignItems};

  > * {
    &:nth-child(odd) {
      margin-right: ${theme.space.md.value};
    }
  }
`
