export const camelCaseToHumanReadable = (text = ''): string => {
  const result = text.replace(/([A-Z\d])/g, ' $1')
  return result.charAt(0).toUpperCase() + result.slice(1)
}

export const snakeCaseToHumanReadable = (text: string): string => {
  const camel = text.replace(/([-_]\w)/g, g => g[1].toUpperCase())
  return camelCaseToHumanReadable(camel)
}

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
})

export const formatCurrency = (value: number): string => formatter.format(value)

export const formatErrorMessage = (message?: string) => {
  if (!message) return ''
  const words = message.split(' ')
  if (words.pop() !== 'field') return message
  const normalizedFieldName = camelCaseToHumanReadable(snakeCaseToHumanReadable(words[0]).toLocaleLowerCase())
  return `${normalizedFieldName} is required`
}
