import { useModalContext } from 'providers/ModalProvider'
import { useEffect } from 'react'

export const Modal = () => {
  const { modals } = useModalContext()

  if (modals.length) {
    return <ModalView />
  }

  return null
}

const ModalView = () => {
  const { modals } = useModalContext()

  useEffect(() => {
    // Blur the initial button that was clicked / element that was selected
    // when this modal appeared
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur()
    }
  }, [])

  const TopModal = modals[0]

  return <TopModal />
}
