// THIS FILE WAS AUTOMATICALLY GENERETED!!!
import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
const defaultOptions = {} as const
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  Date: Date
}

export type Account = {
  __typename?: 'Account'
  addresses?: Maybe<Array<Maybe<Address>>>
  city?: Maybe<Scalars['String']>
  client?: Maybe<Scalars['String']>
  countryCode?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['Date']>
  disabled?: Maybe<Scalars['Boolean']>
  dob?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  firstName: Scalars['String']
  healthProfile?: Maybe<HealthProfile>
  id: Scalars['ID']
  lastName: Scalars['String']
  locationId?: Maybe<Scalars['ID']>
  mobileNumber?: Maybe<Scalars['String']>
  oneTimePassword?: Maybe<Scalars['String']>
  patientToken?: Maybe<Scalars['String']>
  phone?: Maybe<Scalars['String']>
  roles?: Maybe<Array<Maybe<Scalars['String']>>>
  state?: Maybe<Scalars['String']>
  street1?: Maybe<Scalars['String']>
  street2?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['Date']>
  username: Scalars['String']
  zip?: Maybe<Scalars['String']>
}

export type AccountInput = {
  clients?: InputMaybe<Array<ClientAccount>>
  firstName?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['ID']>
  lastName: Scalars['String']
  roles?: InputMaybe<Array<Scalars['String']>>
  username: Scalars['String']
}

export type AccountUser = {
  __typename?: 'AccountUser'
  app_metadata?: Maybe<MetaData>
  blocked?: Maybe<Scalars['Boolean']>
  email?: Maybe<Scalars['String']>
  email_verified?: Maybe<Scalars['Boolean']>
  family_name?: Maybe<Scalars['String']>
  given_name?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  nickname?: Maybe<Scalars['String']>
  permissions: Array<Scalars['String']>
  phone_number?: Maybe<Scalars['String']>
  phone_verified?: Maybe<Scalars['Boolean']>
  picture?: Maybe<Scalars['String']>
  roles?: Maybe<Array<Maybe<Scalars['String']>>>
  user_id?: Maybe<Scalars['String']>
  user_metadata?: Maybe<MetaData>
  username?: Maybe<Scalars['String']>
  verify_email?: Maybe<Scalars['Boolean']>
}

export enum ActiveCopayRequestStatuses {
  Completed = 'completed',
  Rejected = 'rejected',
}

export type Address = {
  __typename?: 'Address'
  city?: Maybe<Scalars['String']>
  communications_opt_in?: Maybe<Scalars['Boolean']>
  company?: Maybe<Scalars['String']>
  country?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  firstName?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['String']>
  isDefault?: Maybe<Scalars['Boolean']>
  lastName?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  phone?: Maybe<Scalars['String']>
  state?: Maybe<Scalars['String']>
  street1?: Maybe<Scalars['String']>
  street2?: Maybe<Scalars['String']>
  zip?: Maybe<Scalars['String']>
}

export type ApiRequestParams = {
  __typename?: 'ApiRequestParams'
  request_params?: Maybe<Scalars['String']>
}

export type CancelAutofillResponse = {
  __typename?: 'CancelAutofillResponse'
  status?: Maybe<Scalars['String']>
}

export type ClientAccount = {
  id?: InputMaybe<Scalars['String']>
  permission?: InputMaybe<Scalars['String']>
}

export enum Clients {
  Astellas = 'astellas',
  Calibrate = 'calibrate',
  Markcuban = 'markcuban',
  Optum = 'optum',
}

export type Copay = {
  __typename?: 'Copay'
  additional_rejection_details: Scalars['String']
  additional_triage_details?: Maybe<Scalars['String']>
  checkout_link?: Maybe<Scalars['String']>
  copay_token: Scalars['String']
  create_checkout_link: Scalars['Boolean']
  created_at: Scalars['String']
  error_code?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['Int']>
  insurance: Array<CopayInsurance>
  insurance_tokens?: Maybe<Array<Maybe<Scalars['String']>>>
  is_checkout_link_active: Scalars['Boolean']
  metadata?: Maybe<Scalars['String']>
  notes?: Maybe<Scalars['String']>
  patient: CopayPatient
  patient_token: Scalars['String']
  prescriptions: Array<CopayPrescription>
  rejection_reason: Scalars['String']
  ship_to_state?: Maybe<Scalars['String']>
  status: Scalars['String']
  triage_reason?: Maybe<Scalars['String']>
  updated_at: Scalars['String']
}

export type CopayInsurance = {
  __typename?: 'CopayInsurance'
  cardholder_id?: Maybe<Scalars['String']>
  is_primary?: Maybe<Scalars['Boolean']>
  payer_name?: Maybe<Scalars['String']>
  pcn?: Maybe<Scalars['String']>
  relationship?: Maybe<Scalars['String']>
  rx_bin?: Maybe<Scalars['String']>
  rx_group?: Maybe<Scalars['String']>
}

export type CopayPatient = {
  __typename?: 'CopayPatient'
  dob: Scalars['String']
  first_name: Scalars['String']
  gender: Scalars['String']
  last_name: Scalars['String']
  prefix?: Maybe<Scalars['String']>
  suffix?: Maybe<Scalars['String']>
  zip?: Maybe<Scalars['String']>
}

export type CopayPrescription = {
  __typename?: 'CopayPrescription'
  additional_rejection_details?: Maybe<Scalars['String']>
  copay: Scalars['String']
  copay_amount?: Maybe<Scalars['Int']>
  error_code?: Maybe<Scalars['String']>
  fill_number?: Maybe<Scalars['Int']>
  medication_name?: Maybe<Scalars['String']>
  prescribed_written_name?: Maybe<Scalars['String']>
  prescriber?: Maybe<Scalars['String']>
  prescription_status?: Maybe<Scalars['String']>
  prescription_token?: Maybe<Scalars['String']>
  quantity?: Maybe<Scalars['Int']>
  rejection_reason?: Maybe<Scalars['String']>
  rx_number?: Maybe<Scalars['String']>
  url_token?: Maybe<Scalars['String']>
}

export type CreateAddressInput = {
  address1: Scalars['String']
  address2?: InputMaybe<Scalars['String']>
  city: Scalars['String']
  firstName: Scalars['String']
  isDefault: Scalars['Boolean']
  lastName: Scalars['String']
  state: Scalars['String']
  userId: Scalars['String']
  zip: Scalars['String']
}

export type CreateCheckoutOrderResponse = {
  __typename?: 'CreateCheckoutOrderResponse'
  status?: Maybe<Scalars['String']>
}

export type CreateCopayResponse = {
  __typename?: 'CreateCopayResponse'
  status?: Maybe<Scalars['Int']>
  statusText?: Maybe<Scalars['String']>
}

export type CreateNoteInput = {
  author_email?: InputMaybe<Scalars['String']>
  author_name: Scalars['String']
  client: Scalars['String']
  comment: Scalars['String']
  deletedAt?: InputMaybe<Scalars['String']>
  resource_id: Scalars['String']
  resource_type: Scalars['String']
}

export type CreatePatientInsuranceInput = {
  insuranceCarrier?: InputMaybe<Scalars['String']>
  insuranceType?: InputMaybe<InsuranceType>
  isPatientDependent?: InputMaybe<Scalars['Boolean']>
  memberId: Scalars['String']
  patientToken: Scalars['String']
  rxBin: Scalars['String']
  rxGroup?: InputMaybe<Scalars['String']>
  rxPcn?: InputMaybe<Scalars['String']>
}

export type CreatePatientInsuranceResponse = {
  __typename?: 'CreatePatientInsuranceResponse'
  added?: Maybe<PatientInsurance>
  ok: Scalars['Boolean']
}

export type Customer = {
  __typename?: 'Customer'
  customerId: Scalars['String']
  customerName: Scalars['String']
  customerSlug: Scalars['String']
  uiConfig: UiConfig
}

export enum DefaultSearchBy {
  Email = 'email',
  Insurance = 'insurance',
  Name = 'name',
  Order = 'order',
}

export type ExternalNote = IExternalNote & {
  __typename?: 'ExternalNote'
  authorName?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['Date']>
  link?: Maybe<Scalars['String']>
  note: Scalars['String']
  source: Scalars['String']
  sourceId: Scalars['String']
  tags?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type FetchOrderInvoiceResponse = {
  __typename?: 'FetchOrderInvoiceResponse'
  invoice: Scalars['String']
}

export type GetLastSubmitDateResponse = {
  __typename?: 'GetLastSubmitDateResponse'
  date?: Maybe<Scalars['String']>
}

export type HealthProfile = {
  __typename?: 'HealthProfile'
  allergies?: Maybe<Array<Maybe<Scalars['String']>>>
  dob?: Maybe<Scalars['String']>
  ethnicity?: Maybe<Scalars['String']>
  firstName?: Maybe<Scalars['String']>
  healthConditions?: Maybe<Array<Maybe<Scalars['String']>>>
  id?: Maybe<Scalars['String']>
  lastName?: Maybe<Scalars['String']>
  medications?: Maybe<Array<Maybe<Scalars['String']>>>
  patientToken?: Maybe<Scalars['String']>
  race?: Maybe<Scalars['String']>
  sex?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['String']>
}

export type IExternalNote = {
  authorName?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['Date']>
  link?: Maybe<Scalars['String']>
  note: Scalars['String']
  source: Scalars['String']
  sourceId: Scalars['String']
  tags?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type InputInsurace = {
  cardholder_id?: InputMaybe<Scalars['String']>
  is_primary?: InputMaybe<Scalars['Boolean']>
  pcn?: InputMaybe<Scalars['String']>
  relationship?: InputMaybe<Scalars['String']>
  rx_bin?: InputMaybe<Scalars['String']>
  rx_group?: InputMaybe<Scalars['String']>
}

export type InsuranceLineItem = {
  __typename?: 'InsuranceLineItem'
  bpe?: Maybe<Scalars['Float']>
  copayCost?: Maybe<Scalars['Float']>
  insurancePayment?: Maybe<Scalars['Float']>
  lineItemId?: Maybe<Scalars['String']>
  primaryInsurance?: Maybe<Array<Maybe<PrimaryInsurance>>>
}

export type InsuranceRejectionCode = {
  __typename?: 'InsuranceRejectionCode'
  code?: Maybe<Scalars['String']>
  message?: Maybe<Scalars['String']>
}

export enum InsuranceType {
  Primary = 'primary',
  Secondary = 'secondary',
  Tertiary = 'tertiary',
}

export type LinkedAccount = {
  __typename?: 'LinkedAccount'
  message?: Maybe<Scalars['String']>
  status?: Maybe<Scalars['Boolean']>
  user?: Maybe<AccountUser>
}

export type MetaData = {
  __typename?: 'MetaData'
  tpcs_clients?: Maybe<Array<Maybe<Scalars['String']>>>
  tpcs_user?: Maybe<Scalars['Boolean']>
}

export type Mutation = {
  __typename?: 'Mutation'
  cancelAutofill?: Maybe<CancelAutofillResponse>
  cancelOrder: OrderCancellation
  cancelUserSubscription?: Maybe<SubscriptionCancelationResponse>
  changePassword: Account
  createAddress?: Maybe<Address>
  createCopay?: Maybe<CreateCopayResponse>
  createLinkedCheckout?: Maybe<CreateCheckoutOrderResponse>
  createNotes?: Maybe<Note>
  createPatientInsurance: CreatePatientInsuranceResponse
  createTeamMember: AccountUser
  getOrderInvoice?: Maybe<FetchOrderInvoiceResponse>
  linkTeamMember: LinkedAccount
  pinNote: PinNoteResponse
  refundOrder?: Maybe<RefundResponce>
  removePatientInsurance: RemovePatientInsuranceResponse
  sendPasswordResetEmail?: Maybe<SendPasswordResetEmailResponse>
  unpinNote: Scalars['Boolean']
  updateAccount?: Maybe<Account>
  updateAddress?: Maybe<Address>
  updateAndInitiateCopayCheck?: Maybe<UpdateCopayResponse>
  updateTeamMember?: Maybe<TeamMember>
}

export type MutationCancelAutofillArgs = {
  prescriptionToken: Scalars['String']
}

export type MutationCancelOrderArgs = {
  orderId: Scalars['String']
}

export type MutationCancelUserSubscriptionArgs = {
  userId: Scalars['String']
}

export type MutationChangePasswordArgs = {
  password: Scalars['String']
}

export type MutationCreateAddressArgs = {
  input?: InputMaybe<CreateAddressInput>
}

export type MutationCreateCopayArgs = {
  customerId: Scalars['String']
  prescriptionToken: Scalars['String']
}

export type MutationCreateLinkedCheckoutArgs = {
  copayToken: Scalars['String']
}

export type MutationCreateNotesArgs = {
  input?: InputMaybe<CreateNoteInput>
}

export type MutationCreatePatientInsuranceArgs = {
  input: CreatePatientInsuranceInput
}

export type MutationCreateTeamMemberArgs = {
  email?: InputMaybe<Scalars['String']>
  roles?: InputMaybe<Array<InputMaybe<TeamMemberRolesInput>>>
}

export type MutationGetOrderInvoiceArgs = {
  orderId: Scalars['String']
}

export type MutationLinkTeamMemberArgs = {
  email: Scalars['String']
}

export type MutationPinNoteArgs = {
  pinnedNoteId: PinnedNoteId
}

export type MutationRefundOrderArgs = {
  orderId: Scalars['String']
  refundedLineItems: Array<InputMaybe<RefundLineItem>>
}

export type MutationRemovePatientInsuranceArgs = {
  patientToken: Scalars['String']
}

export type MutationSendPasswordResetEmailArgs = {
  _id: Scalars['ID']
}

export type MutationUnpinNoteArgs = {
  pinnedNoteId: PinnedNoteId
}

export type MutationUpdateAccountArgs = {
  email?: InputMaybe<Scalars['String']>
  id: Scalars['String']
  mobileNumber?: InputMaybe<Scalars['String']>
}

export type MutationUpdateAddressArgs = {
  input?: InputMaybe<UpdateAddressInput>
}

export type MutationUpdateAndInitiateCopayCheckArgs = {
  copayToken: Scalars['String']
  patientToken: Scalars['String']
  primaryInsurance?: InputMaybe<InputInsurace>
  secondaryInsurance?: InputMaybe<InputInsurace>
}

export type MutationUpdateTeamMemberArgs = {
  blocked?: InputMaybe<Scalars['Boolean']>
  clients?: InputMaybe<Array<InputMaybe<ClientAccount>>>
  family_name?: InputMaybe<Scalars['String']>
  given_name?: InputMaybe<Scalars['String']>
  roles?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  user_id: Scalars['String']
}

export type Note = {
  __typename?: 'Note'
  author_email?: Maybe<Scalars['String']>
  author_name?: Maybe<Scalars['String']>
  client?: Maybe<Scalars['String']>
  comment: Scalars['String']
  createdAt: Scalars['String']
  deletedAt?: Maybe<Scalars['String']>
  id: Scalars['String']
  resource_id: Scalars['String']
  resource_type: Scalars['String']
  updatedAt: Scalars['String']
}

export type Order = {
  __typename?: 'Order'
  address?: Maybe<Address>
  order: OrderDetails
  user: OrderUser
}

export type OrderCancellation = {
  __typename?: 'OrderCancellation'
  details?: Maybe<OrderCancellationDetails>
  request_id?: Maybe<Scalars['String']>
  status?: Maybe<Scalars['String']>
  timestamp?: Maybe<Scalars['String']>
  totalPrice?: Maybe<Scalars['Float']>
}

export type OrderCancellationDetails = {
  __typename?: 'OrderCancellationDetails'
  code?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  message?: Maybe<Scalars['String']>
}

export type OrderDetail = {
  __typename?: 'OrderDetail'
  callbackUrl?: Maybe<Scalars['String']>
  cardNumber?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['Date']>
  discount?: Maybe<Scalars['String']>
  insurance?: Maybe<Array<Maybe<OrderInsurance>>>
  orderId?: Maybe<Scalars['String']>
  products?: Maybe<Array<Maybe<Product>>>
  shippingMethod?: Maybe<Scalars['String']>
  shippingMethodName?: Maybe<Scalars['String']>
  shippingPrice?: Maybe<Scalars['Float']>
  status?: Maybe<Scalars['String']>
  taxesPrice?: Maybe<Scalars['Float']>
  totalPrice?: Maybe<Scalars['Float']>
  trackingUrl?: Maybe<Scalars['String']>
  wildcard?: Maybe<Scalars['String']>
}

export type OrderDetails = {
  __typename?: 'OrderDetails'
  callbackUrl?: Maybe<Scalars['String']>
  cardNumber?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['Date']>
  discount?: Maybe<Scalars['String']>
  orderId: Scalars['String']
  products?: Maybe<Array<Maybe<Product>>>
  shippingMethod?: Maybe<Scalars['String']>
  shippingMethodName?: Maybe<Scalars['String']>
  shippingPrice?: Maybe<Scalars['Float']>
  status: Scalars['String']
  taxesPrice?: Maybe<Scalars['Float']>
  totalPrice: Scalars['Float']
  trackingUrl?: Maybe<Scalars['String']>
  wildcard?: Maybe<Scalars['String']>
}

export type OrderFull = {
  __typename?: 'OrderFull'
  address: Address
  insurance?: Maybe<Array<Maybe<OrderInsurance>>>
  order: OrderFullDetails
  payments?: Maybe<Array<Maybe<Payment>>>
  user: OrderUser
}

export type OrderFullDetails = {
  __typename?: 'OrderFullDetails'
  callbackUrl?: Maybe<Scalars['String']>
  cardNumber?: Maybe<Scalars['String']>
  client?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['Date']>
  discount?: Maybe<Scalars['String']>
  insurance?: Maybe<Array<Maybe<OrderInsurance>>>
  lineItems?: Maybe<Array<Maybe<OrderLineItem>>>
  orderId: Scalars['String']
  products?: Maybe<Array<Maybe<Product>>>
  shippingMethod?: Maybe<Scalars['String']>
  shippingMethodName?: Maybe<Scalars['String']>
  shippingPrice?: Maybe<Scalars['Float']>
  status?: Maybe<Scalars['String']>
  taxesPrice?: Maybe<Scalars['Float']>
  totalPrice?: Maybe<Scalars['Float']>
  trackingUrl?: Maybe<Scalars['String']>
  wildcard?: Maybe<Scalars['String']>
}

export type OrderInsurance = {
  __typename?: 'OrderInsurance'
  copayAmount?: Maybe<Scalars['Float']>
  lineItems?: Maybe<Array<Maybe<InsuranceLineItem>>>
  orderId?: Maybe<Scalars['String']>
  totalInsurancePayment?: Maybe<Scalars['Float']>
}

export type OrderLineItem = {
  __typename?: 'OrderLineItem'
  discountAmount?: Maybe<Scalars['Float']>
  id?: Maybe<Scalars['String']>
  isFsa?: Maybe<Scalars['Boolean']>
  isHsa?: Maybe<Scalars['Boolean']>
  paymentId?: Maybe<Scalars['String']>
  price?: Maybe<Scalars['Float']>
  product?: Maybe<Product>
  quantity?: Maybe<Scalars['Int']>
  sku?: Maybe<Scalars['String']>
  taxAmount?: Maybe<Scalars['Float']>
}

export type OrderPagination = {
  __typename?: 'OrderPagination'
  pageCount: Scalars['Int']
  pageNumber: Scalars['Int']
  total: Scalars['Int']
}

export type OrderSearch = {
  __typename?: 'OrderSearch'
  data: Array<OrderFull>
  pagination: OrderPagination
}

export type OrderSearchResponse = {
  __typename?: 'OrderSearchResponse'
  data?: Maybe<Array<Maybe<Order>>>
  pagination?: Maybe<OrderPagination>
}

export type OrderSuperSetFullfilmentVendors = {
  __typename?: 'OrderSuperSetFullfilmentVendors'
  order_number: Scalars['String']
  vendors_names: Array<Scalars['String']>
}

export type OrderSuperSetItem = {
  __typename?: 'OrderSuperSetItem'
  quantity?: Maybe<Scalars['Int']>
  sku?: Maybe<Scalars['String']>
}

export type OrderSuperSetPackage = {
  __typename?: 'OrderSuperSetPackage'
  shipped_items?: Maybe<Array<Maybe<OrderSuperSetItem>>>
  shipping_info?: Maybe<OrderSuperSetPackageShippingInfo>
}

export type OrderSuperSetPackageShippingInfo = {
  __typename?: 'OrderSuperSetPackageShippingInfo'
  shipment_id?: Maybe<Scalars['String']>
  shipping_method?: Maybe<Scalars['String']>
  shipping_status?: Maybe<Scalars['String']>
  tracking_url?: Maybe<Scalars['String']>
}

export type OrderSuperset = {
  __typename?: 'OrderSuperset'
  cancelled_items: Array<Maybe<OrderSuperSetItem>>
  client?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['Date']>
  fulfillment_vendors?: Maybe<OrderSuperSetFullfilmentVendors>
  id?: Maybe<Scalars['String']>
  line_items: Array<Maybe<OrderSupersetLineItem>>
  order_type?: Maybe<OrderSupersetType>
  packages: Array<Maybe<OrderSuperSetPackage>>
  patient?: Maybe<OrderSupersetPatient>
  payment: Array<Maybe<OrderSupersetPayment>>
  pending_items: Array<Maybe<OrderSuperSetItem>>
  status?: Maybe<Scalars['String']>
  url_token?: Maybe<Scalars['String']>
}

export type OrderSupersetInsuranceInfo = {
  __typename?: 'OrderSupersetInsuranceInfo'
  cardholder_id?: Maybe<Scalars['String']>
  carrier?: Maybe<Scalars['String']>
  pcn?: Maybe<Scalars['String']>
  rx_bin?: Maybe<Scalars['String']>
  rx_group?: Maybe<Scalars['String']>
}

export type OrderSupersetLineItem = {
  __typename?: 'OrderSupersetLineItem'
  copay_price?: Maybe<Scalars['Float']>
  id?: Maybe<Scalars['String']>
  insurance_info?: Maybe<OrderSupersetInsuranceInfo>
  insurance_price?: Maybe<Scalars['Float']>
  isFsa?: Maybe<Scalars['Boolean']>
  isHsa?: Maybe<Scalars['Boolean']>
  name?: Maybe<Scalars['String']>
  price?: Maybe<Scalars['Float']>
  quantity?: Maybe<Scalars['Int']>
  rx_number?: Maybe<Scalars['String']>
  sku?: Maybe<Scalars['String']>
  total_price?: Maybe<Scalars['Float']>
}

export type OrderSupersetPatient = {
  __typename?: 'OrderSupersetPatient'
  account_id?: Maybe<Scalars['String']>
  address?: Maybe<OrderSupersetPatientAddress>
  email?: Maybe<Scalars['String']>
  first_name?: Maybe<Scalars['String']>
  last_name?: Maybe<Scalars['String']>
  patient_token?: Maybe<Scalars['String']>
  phone?: Maybe<Scalars['String']>
}

export type OrderSupersetPatientAddress = {
  __typename?: 'OrderSupersetPatientAddress'
  city?: Maybe<Scalars['String']>
  state?: Maybe<Scalars['String']>
  street1?: Maybe<Scalars['String']>
  street2?: Maybe<Scalars['String']>
  zip?: Maybe<Scalars['String']>
}

export type OrderSupersetPayment = {
  __typename?: 'OrderSupersetPayment'
  discount?: Maybe<Scalars['Int']>
  insurance_payment?: Maybe<Scalars['Float']>
  payment_card?: Maybe<OrderSupersetPaymentCard>
  payment_token?: Maybe<Scalars['String']>
  payment_type?: Maybe<Scalars['String']>
  promotion?: Maybe<Scalars['String']>
  shipping_price?: Maybe<Scalars['Float']>
  status?: Maybe<Scalars['String']>
  sub_total?: Maybe<Scalars['Float']>
  tax_price?: Maybe<Scalars['Float']>
  total_price?: Maybe<Scalars['Float']>
}

export type OrderSupersetPaymentCard = {
  __typename?: 'OrderSupersetPaymentCard'
  brand?: Maybe<Scalars['String']>
  expiration?: Maybe<Scalars['Date']>
  last_digits?: Maybe<Scalars['String']>
}

export type OrderSupersetType = {
  __typename?: 'OrderSupersetType'
  full: Scalars['String']
  short: Scalars['String']
}

export type OrderUser = {
  __typename?: 'OrderUser'
  email?: Maybe<Scalars['String']>
  firstName?: Maybe<Scalars['String']>
  lastName?: Maybe<Scalars['String']>
  phone?: Maybe<Scalars['String']>
  urlToken?: Maybe<Scalars['String']>
  userId?: Maybe<Scalars['String']>
}

export type Pagination = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

export type PatientInsurance = {
  __typename?: 'PatientInsurance'
  insuranceCarrier?: Maybe<Scalars['String']>
  insuranceToken?: Maybe<Scalars['String']>
  insuranceType?: Maybe<InsuranceType>
  memberId?: Maybe<Scalars['String']>
  rxBin?: Maybe<Scalars['String']>
  rxGroup?: Maybe<Scalars['String']>
  rxPcn?: Maybe<Scalars['String']>
}

export type PatientNotes = {
  __typename?: 'PatientNotes'
  notesCount: Scalars['Int']
  pinnedNotes: Array<PinnedNote>
  unpinnedNotes: Array<ExternalNote>
}

export type PatientOrm = {
  __typename?: 'PatientORM'
  address?: Maybe<Address>
  dob?: Maybe<Scalars['String']>
  first_name?: Maybe<Scalars['String']>
  gender?: Maybe<Scalars['String']>
  last_name?: Maybe<Scalars['String']>
  mbi?: Maybe<Scalars['String']>
  patient_token?: Maybe<Scalars['String']>
}

export type Payment = {
  __typename?: 'Payment'
  amount?: Maybe<Scalars['Float']>
  cardBrand?: Maybe<Scalars['String']>
  cardExpirationDate?: Maybe<Scalars['String']>
  cardLast4Digits?: Maybe<Scalars['String']>
  discount?: Maybe<Scalars['Float']>
  id?: Maybe<Scalars['String']>
  paymentIntentId?: Maybe<Scalars['String']>
  shipping?: Maybe<Scalars['Float']>
  status?: Maybe<Scalars['String']>
  statusDetail?: Maybe<Scalars['String']>
  taxes?: Maybe<Scalars['Float']>
}

export enum Permission {
  R = 'r',
  Wr = 'wr',
}

export type PinNoteResponse = {
  __typename?: 'PinNoteResponse'
  isPinned: Scalars['Boolean']
  lastPinnedDate?: Maybe<Scalars['Date']>
}

export type PinnedNote = IExternalNote & {
  __typename?: 'PinnedNote'
  authorName?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['Date']>
  lastPinnedDate: Scalars['Date']
  link?: Maybe<Scalars['String']>
  note: Scalars['String']
  source: Scalars['String']
  sourceId: Scalars['String']
  tags?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type PinnedNoteId = {
  source: Scalars['String']
  sourceId: Scalars['String']
}

export type PrimaryInsurance = {
  __typename?: 'PrimaryInsurance'
  claimStatus?: Maybe<Scalars['String']>
  rejectionCode?: Maybe<InsuranceRejectionCode>
}

export type Product = {
  __typename?: 'Product'
  brand?: Maybe<Array<Maybe<Scalars['String']>>>
  image?: Maybe<Scalars['String']>
  metaDescription?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  price?: Maybe<Scalars['Int']>
  quantity?: Maybe<Scalars['Float']>
  sku?: Maybe<Scalars['String']>
  skuType?: Maybe<Scalars['String']>
  slug?: Maybe<Scalars['String']>
  thumbnail?: Maybe<Scalars['String']>
}

export type Query = {
  __typename?: 'Query'
  getAccounts: Array<Account>
  getAddresses: Array<Address>
  getAllRoles?: Maybe<Array<Maybe<Roles>>>
  getApiRequestParams?: Maybe<ApiRequestParams>
  getCopays: Array<Copay>
  getCustomers: Array<Customer>
  getHealthProfiles: Array<HealthProfile>
  getLastSubmitDate?: Maybe<GetLastSubmitDateResponse>
  getOrder?: Maybe<OrderFull>
  getOrderSuperset?: Maybe<OrderSuperset>
  getOrders?: Maybe<Array<Maybe<Order>>>
  getPatientDetails?: Maybe<PatientOrm>
  getPrescriptions: Array<Tp_Prescription>
  getTMcreatePermissions: Array<CreateTeamPermission>
  getTeamMember?: Maybe<TeamMember>
  getTeamMembers: TeamMemberSearch
  getUser?: Maybe<Account>
  getUserSubscriptions: Array<Subcription>
  getUsers: Array<Maybe<Account>>
  me: AccountUser
  notes: PatientNotes
  patientInsurance: Array<PatientInsurance>
  searchOrders?: Maybe<OrderSearch>
}

export type QueryGetAccountsArgs = {
  dob?: InputMaybe<Scalars['String']>
  email?: InputMaybe<Scalars['String']>
  firstName?: InputMaybe<Scalars['String']>
  gender?: InputMaybe<Scalars['String']>
  lastName?: InputMaybe<Scalars['String']>
  memberId?: InputMaybe<Scalars['String']>
  patientToken?: InputMaybe<Scalars['String']>
}

export type QueryGetAddressesArgs = {
  userId: Scalars['String']
}

export type QueryGetAllRolesArgs = {
  page?: InputMaybe<Scalars['Int']>
  per_page?: InputMaybe<Scalars['Int']>
}

export type QueryGetApiRequestParamsArgs = {
  orderId: Scalars['String']
}

export type QueryGetCopaysArgs = {
  copayStatuses?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  pagination?: InputMaybe<Pagination>
  patientToken: Scalars['String']
}

export type QueryGetHealthProfilesArgs = {
  patientToken?: InputMaybe<Scalars['String']>
  userId?: InputMaybe<Scalars['String']>
}

export type QueryGetLastSubmitDateArgs = {
  prescriptionToken: Scalars['String']
}

export type QueryGetOrderArgs = {
  orderId?: InputMaybe<Scalars['String']>
}

export type QueryGetOrderSupersetArgs = {
  orderId?: InputMaybe<Scalars['String']>
}

export type QueryGetOrdersArgs = {
  patientToken?: InputMaybe<Scalars['String']>
  userId: Scalars['String']
}

export type QueryGetPatientDetailsArgs = {
  patientToken: Scalars['String']
}

export type QueryGetPrescriptionsArgs = {
  patientToken: Scalars['String']
}

export type QueryGetTeamMemberArgs = {
  email: Scalars['String']
}

export type QueryGetTeamMembersArgs = {
  limit?: InputMaybe<Scalars['Int']>
  name?: InputMaybe<Scalars['String']>
  offset?: InputMaybe<Scalars['Int']>
}

export type QueryGetUserArgs = {
  _id: Scalars['ID']
}

export type QueryGetUserSubscriptionsArgs = {
  userId: Scalars['String']
}

export type QueryNotesArgs = {
  accountId?: InputMaybe<Scalars['String']>
  orderToken?: InputMaybe<Scalars['String']>
  patientToken?: InputMaybe<Scalars['String']>
}

export type QueryPatientInsuranceArgs = {
  patientToken: Scalars['String']
}

export type QuerySearchOrdersArgs = {
  descending?: InputMaybe<Scalars['Boolean']>
  email?: InputMaybe<Scalars['String']>
  guestCheckoutOnly?: InputMaybe<Scalars['Boolean']>
  pageCount?: InputMaybe<Scalars['Int']>
  pageNumber?: InputMaybe<Scalars['Int']>
  sortBy?: InputMaybe<Scalars['String']>
  total?: InputMaybe<Scalars['Boolean']>
  userId?: InputMaybe<Scalars['String']>
}

export type RefundLineItem = {
  amount?: InputMaybe<Scalars['Int']>
  lineItemId?: InputMaybe<Scalars['String']>
  quantity?: InputMaybe<Scalars['Int']>
}

export type RefundResponce = {
  __typename?: 'RefundResponce'
  status?: Maybe<Scalars['String']>
}

export type RemovePatientInsuranceResponse = {
  __typename?: 'RemovePatientInsuranceResponse'
  ok?: Maybe<Scalars['Boolean']>
}

export enum Role {
  Admin = 'admin',
  Cslead = 'cslead',
  Csr = 'csr',
  CustomerSupport = 'customerSupport',
  LeadCustomerSupport = 'leadCustomerSupport',
  LeadPharmacist = 'leadPharmacist',
  Pharmacist = 'pharmacist',
  Technician = 'technician',
  Warehouse = 'warehouse',
}

export type Roles = {
  __typename?: 'Roles'
  description?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
}

export type SendPasswordResetEmailResponse = {
  __typename?: 'SendPasswordResetEmailResponse'
  _id?: Maybe<Scalars['ID']>
  status?: Maybe<Scalars['String']>
}

export type Subcription = {
  __typename?: 'Subcription'
  healthProfileId?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['String']>
  plan?: Maybe<SubscriptionPlan>
  planId?: Maybe<Scalars['String']>
  status?: Maybe<Scalars['String']>
  subscriptionType?: Maybe<Scalars['String']>
  userId?: Maybe<Scalars['String']>
}

export type SubscriptionCancelationResponse = {
  __typename?: 'SubscriptionCancelationResponse'
  statusCode?: Maybe<Scalars['String']>
}

export type SubscriptionPlan = {
  __typename?: 'SubscriptionPlan'
  description?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['String']>
  initialInterval?: Maybe<Scalars['String']>
  interval?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  price?: Maybe<Scalars['String']>
  serviceType?: Maybe<Scalars['String']>
}

export type Tp_Prescription = {
  __typename?: 'TP_Prescription'
  autofilledId?: Maybe<Scalars['String']>
  autofilledStatus?: Maybe<Scalars['String']>
  date_filled_utc?: Maybe<Scalars['String']>
  date_written?: Maybe<Scalars['String']>
  days_supply?: Maybe<Scalars['String']>
  isBlocked?: Maybe<Scalars['Boolean']>
  is_refill?: Maybe<Scalars['Int']>
  last_filled_date?: Maybe<Scalars['String']>
  location?: Maybe<Scalars['String']>
  medication_name?: Maybe<Scalars['String']>
  medication_sig?: Maybe<Scalars['String']>
  number_of_refills_allowed?: Maybe<Scalars['Int']>
  prescribed_quantity?: Maybe<Scalars['String']>
  prescriber?: Maybe<Scalars['String']>
  prescription_token?: Maybe<Scalars['String']>
  quantity_remaining?: Maybe<Scalars['String']>
  refills_remaining?: Maybe<Scalars['String']>
  rx_number?: Maybe<Scalars['String']>
  status?: Maybe<Scalars['String']>
}

export type TeamMember = {
  __typename?: 'TeamMember'
  app_metadata?: Maybe<TeamMemberAppMetadata>
  blocked?: Maybe<Scalars['Boolean']>
  created_at?: Maybe<Scalars['String']>
  email: Scalars['String']
  family_name?: Maybe<Scalars['String']>
  given_name?: Maybe<Scalars['String']>
  roles: Array<TeamMemberRoles>
  user_id: Scalars['String']
  username?: Maybe<Scalars['String']>
}

export type TeamMemberAppMetadata = {
  __typename?: 'TeamMemberAppMetadata'
  tpcs_clients?: Maybe<Scalars['String']>
  tpcs_user?: Maybe<Scalars['String']>
}

export type TeamMemberRoles = {
  __typename?: 'TeamMemberRoles'
  description: Scalars['String']
  id: Scalars['String']
  name: Scalars['String']
}

export type TeamMemberRolesInput = {
  client?: InputMaybe<Scalars['String']>
  title?: InputMaybe<Scalars['String']>
}

export type TeamMemberSearch = {
  __typename?: 'TeamMemberSearch'
  data: Array<TeamMember>
  pagination: UserPagination
}

export type UiConfig = {
  __typename?: 'UIConfig'
  accountHasPatientToken: Scalars['Boolean']
  activeCopayRequestStatuses: Array<ActiveCopayRequestStatuses>
  createCopayAllowed: Scalars['Boolean']
  defaultSearchBy: DefaultSearchBy
  downloadInvoiceButton: Scalars['Boolean']
  editAccount: Scalars['Boolean']
  enableDateOfBirth: Scalars['Boolean']
  enableInsurance: Scalars['Boolean']
  enableInvoiceDownload: Scalars['Boolean']
  enableMemberId: Scalars['Boolean']
  enableSearchByName: Scalars['Boolean']
  guestOrdersTable: Scalars['Boolean']
  hideOrderDetailsInsurance: Scalars['Boolean']
  insurance: Scalars['Boolean']
  isCheckoutCTA: Scalars['Boolean']
  isPharmacy: Scalars['Boolean']
  orderCancellationButton: Scalars['Boolean']
  orderTypeTag: Scalars['Boolean']
  resetPasswordButton: Scalars['Boolean']
  searchEnabled: UiConfigSearchEnabled
  shouldManageId: Scalars['Boolean']
  showAccountDeepLink: Scalars['Boolean']
  showCancelAutofill: Scalars['Boolean']
  showOrderDeepLink: Scalars['Boolean']
  showOrderDetails: Scalars['Boolean']
  showPaymentToken: Scalars['Boolean']
  showTabs: UiConfigShowTabs
}

export type UiConfigSearchEnabled = {
  __typename?: 'UIConfigSearchEnabled'
  email: Scalars['Boolean']
  insurance: Scalars['Boolean']
  name: Scalars['Boolean']
  order: Scalars['Boolean']
}

export type UiConfigShowTabs = {
  __typename?: 'UIConfigShowTabs'
  account: Scalars['Boolean']
  addresses: Scalars['Boolean']
  copays: Scalars['Boolean']
  healthProfiles: Scalars['Boolean']
  insurances: Scalars['Boolean']
  orders: Scalars['Boolean']
  subscriptions: Scalars['Boolean']
}

export type UpdateAddressInput = {
  address1: Scalars['String']
  address2?: InputMaybe<Scalars['String']>
  addressId: Scalars['String']
  city: Scalars['String']
  firstName: Scalars['String']
  isDefault: Scalars['Boolean']
  lastName: Scalars['String']
  state: Scalars['String']
  userId: Scalars['String']
  zip: Scalars['String']
}

export type UpdateCopayResponse = {
  __typename?: 'UpdateCopayResponse'
  status?: Maybe<Scalars['String']>
}

export type UserPagination = {
  __typename?: 'UserPagination'
  limit: Scalars['Int']
  offset: Scalars['Int']
  total: Scalars['Int']
}

export type CreateTeamPermission = {
  __typename?: 'createTeamPermission'
  client: Scalars['String']
  role: Scalars['String']
  slug: Scalars['String']
}

export type ChangePasswordMutationVariables = Exact<{
  password: Scalars['String']
}>

export type ChangePasswordMutation = {
  __typename?: 'Mutation'
  changePassword: { __typename?: 'Account'; id: string; roles?: Array<string | null> | null; username: string }
}

export type AccountFragment = {
  __typename?: 'Account'
  id: string
  patientToken?: string | null
  firstName: string
  lastName: string
  dob?: string | null
  email?: string | null
  countryCode?: string | null
  mobileNumber?: string | null
  street1?: string | null
  street2?: string | null
  city?: string | null
  state?: string | null
  zip?: string | null
  createdAt?: Date | null
  client?: string | null
}

export type GetAccountsQueryVariables = Exact<{
  email?: InputMaybe<Scalars['String']>
  firstName?: InputMaybe<Scalars['String']>
  lastName?: InputMaybe<Scalars['String']>
  dob?: InputMaybe<Scalars['String']>
  memberId?: InputMaybe<Scalars['String']>
  patientToken?: InputMaybe<Scalars['String']>
  gender?: InputMaybe<Scalars['String']>
}>

export type GetAccountsQuery = {
  __typename?: 'Query'
  getAccounts: Array<{ __typename?: 'Account' } & AccountFragment>
}

export type HealthProfileFragment = {
  __typename?: 'HealthProfile'
  firstName?: string | null
  lastName?: string | null
  dob?: string | null
  type?: string | null
  sex?: string | null
  allergies?: Array<string | null> | null
  healthConditions?: Array<string | null> | null
  medications?: Array<string | null> | null
  patientToken?: string | null
}

export type GetHealthProfilesQueryVariables = Exact<{
  userId?: InputMaybe<Scalars['String']>
  patientToken?: InputMaybe<Scalars['String']>
}>

export type GetHealthProfilesQuery = {
  __typename?: 'Query'
  getHealthProfiles: Array<{ __typename?: 'HealthProfile' } & HealthProfileFragment>
}

export type SendPasswordResetEmailMutationVariables = Exact<{
  userId: Scalars['ID']
}>

export type SendPasswordResetEmailMutation = {
  __typename?: 'Mutation'
  sendPasswordResetEmail?: {
    __typename?: 'SendPasswordResetEmailResponse'
    _id?: string | null
    status?: string | null
  } | null
}

export type UpdateAccountMutationVariables = Exact<{
  id: Scalars['String']
  email?: InputMaybe<Scalars['String']>
  mobileNumber?: InputMaybe<Scalars['String']>
}>

export type UpdateAccountMutation = {
  __typename?: 'Mutation'
  updateAccount?: {
    __typename?: 'Account'
    id: string
    createdAt?: Date | null
    updatedAt?: Date | null
    firstName: string
    lastName: string
    mobileNumber?: string | null
    countryCode?: string | null
    email?: string | null
    addresses?: Array<{
      __typename: 'Address'
      street1?: string | null
      street2?: string | null
      city?: string | null
      state?: string | null
      zip?: string | null
    } | null> | null
  } | null
}

export type CreateAddressMutationVariables = Exact<{
  userId: Scalars['String']
  isDefault: Scalars['Boolean']
  address1: Scalars['String']
  address2?: InputMaybe<Scalars['String']>
  state: Scalars['String']
  city: Scalars['String']
  firstName: Scalars['String']
  lastName: Scalars['String']
  zip: Scalars['String']
}>

export type CreateAddressMutation = {
  __typename?: 'Mutation'
  createAddress?: {
    __typename?: 'Address'
    id?: string | null
    isDefault?: boolean | null
    street1?: string | null
    street2?: string | null
    state?: string | null
    city?: string | null
    zip?: string | null
  } | null
}

export type AddressFragment = {
  __typename?: 'Address'
  id?: string | null
  isDefault?: boolean | null
  street1?: string | null
  street2?: string | null
  city?: string | null
  state?: string | null
  zip?: string | null
  firstName?: string | null
  lastName?: string | null
}

export type GetAddressesQueryVariables = Exact<{
  userId: Scalars['String']
}>

export type GetAddressesQuery = {
  __typename?: 'Query'
  getAddresses: Array<{ __typename?: 'Address' } & AddressFragment>
}

export type UpdateAddressMutationVariables = Exact<{
  userId: Scalars['String']
  addressId: Scalars['String']
  isDefault: Scalars['Boolean']
  address1: Scalars['String']
  address2?: InputMaybe<Scalars['String']>
  state: Scalars['String']
  city: Scalars['String']
  firstName: Scalars['String']
  lastName: Scalars['String']
  zip: Scalars['String']
}>

export type UpdateAddressMutation = {
  __typename?: 'Mutation'
  updateAddress?: {
    __typename?: 'Address'
    id?: string | null
    isDefault?: boolean | null
    street1?: string | null
    street2?: string | null
    state?: string | null
    city?: string | null
    zip?: string | null
  } | null
}

export type CreateCopayMutationVariables = Exact<{
  customerId: Scalars['String']
  prescriptionToken: Scalars['String']
}>

export type CreateCopayMutation = {
  __typename?: 'Mutation'
  createCopay?: { __typename?: 'CreateCopayResponse'; status?: number | null; statusText?: string | null } | null
}

export type CreateLinkedCheckoutMutationVariables = Exact<{
  copay_token: Scalars['String']
}>

export type CreateLinkedCheckoutMutation = {
  __typename?: 'Mutation'
  createLinkedCheckout?: { __typename?: 'CreateCheckoutOrderResponse'; status?: string | null } | null
}

export type CopayFragment = {
  __typename?: 'Copay'
  id?: number | null
  copay_token: string
  patient_token: string
  status: string
  error_code?: string | null
  notes?: string | null
  insurance_tokens?: Array<string | null> | null
  ship_to_state?: string | null
  rejection_reason: string
  additional_rejection_details: string
  updated_at: string
  created_at: string
  checkout_link?: string | null
  is_checkout_link_active: boolean
  create_checkout_link: boolean
  patient: { __typename?: 'CopayPatient'; last_name: string; first_name: string; gender: string; dob: string }
  insurance: Array<{
    __typename?: 'CopayInsurance'
    cardholder_id?: string | null
    rx_group?: string | null
    rx_bin?: string | null
    pcn?: string | null
    payer_name?: string | null
    relationship?: string | null
    is_primary?: boolean | null
  }>
  prescriptions: Array<{
    __typename?: 'CopayPrescription'
    rx_number?: string | null
    prescriber?: string | null
    medication_name?: string | null
    url_token?: string | null
    prescription_token?: string | null
    rejection_reason?: string | null
    additional_rejection_details?: string | null
    quantity?: number | null
    prescribed_written_name?: string | null
    prescription_status?: string | null
    copay: string
  }>
}

export type GetCopaysQueryVariables = Exact<{
  patientToken: Scalars['String']
  copayStatuses?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>
}>

export type GetCopaysQuery = { __typename?: 'Query'; getCopays: Array<{ __typename?: 'Copay' } & CopayFragment> }

export type GetLastSubmitDateQueryVariables = Exact<{
  prescriptionToken: Scalars['String']
}>

export type GetLastSubmitDateQuery = {
  __typename?: 'Query'
  getLastSubmitDate?: { __typename?: 'GetLastSubmitDateResponse'; date?: string | null } | null
}

export type UpdateAndInitiateCopayCheckMutationVariables = Exact<{
  copayToken: Scalars['String']
  patientToken: Scalars['String']
  primaryInsurance?: InputMaybe<InputInsurace>
  secondaryInsurance?: InputMaybe<InputInsurace>
}>

export type UpdateAndInitiateCopayCheckMutation = {
  __typename?: 'Mutation'
  updateAndInitiateCopayCheck?: { __typename?: 'UpdateCopayResponse'; status?: string | null } | null
}

export type UserInfoFragment = {
  __typename?: 'AccountUser'
  permissions: Array<string>
  picture?: string | null
  email?: string | null
  name?: string | null
  username?: string | null
  email_verified?: boolean | null
  verify_email?: boolean | null
  user_id?: string | null
  nickname?: string | null
  phone_number?: string | null
  given_name?: string | null
  family_name?: string | null
}

export type GetUserInfoQueryVariables = Exact<{ [key: string]: never }>

export type GetUserInfoQuery = { __typename?: 'Query'; me: { __typename?: 'AccountUser' } & UserInfoFragment }

export type CreatePatientInsuranceMutationVariables = Exact<{
  input: CreatePatientInsuranceInput
}>

export type CreatePatientInsuranceMutation = {
  __typename?: 'Mutation'
  createPatientInsurance: {
    __typename?: 'CreatePatientInsuranceResponse'
    ok: boolean
    added?: {
      __typename?: 'PatientInsurance'
      insuranceToken?: string | null
      insuranceCarrier?: string | null
      memberId?: string | null
      rxBin?: string | null
      rxPcn?: string | null
      rxGroup?: string | null
      insuranceType?: InsuranceType | null
    } | null
  }
}

export type RemovePatientInsuranceMutationVariables = Exact<{
  patientToken: Scalars['String']
}>

export type RemovePatientInsuranceMutation = {
  __typename?: 'Mutation'
  removePatientInsurance: { __typename?: 'RemovePatientInsuranceResponse'; ok?: boolean | null }
}

export type GetPatientInsurancesQueryVariables = Exact<{
  patientToken: Scalars['String']
}>

export type GetPatientInsurancesQuery = {
  __typename?: 'Query'
  patientInsurance: Array<{
    __typename?: 'PatientInsurance'
    insuranceCarrier?: string | null
    insuranceToken?: string | null
    insuranceType?: InsuranceType | null
    memberId?: string | null
    rxBin?: string | null
    rxGroup?: string | null
    rxPcn?: string | null
  }>
}

export type CreateNotesMutationVariables = Exact<{
  comment: Scalars['String']
  client: Scalars['String']
  resource_type: Scalars['String']
  resource_id: Scalars['String']
  author_name: Scalars['String']
  author_email?: InputMaybe<Scalars['String']>
}>

export type CreateNotesMutation = {
  __typename?: 'Mutation'
  createNotes?: ({ __typename?: 'Note' } & NoteFragment) | null
}

export type ExternalNoteFragment = {
  __typename?: 'ExternalNote'
  source: string
  sourceId: string
  authorName?: string | null
  note: string
  tags?: Array<string | null> | null
  link?: string | null
  createdAt?: Date | null
}

export type PinnedNoteFragment = {
  __typename?: 'PinnedNote'
  source: string
  sourceId: string
  authorName?: string | null
  note: string
  tags?: Array<string | null> | null
  link?: string | null
  createdAt?: Date | null
  lastPinnedDate: Date
}

export type NoteFragment = {
  __typename?: 'Note'
  id: string
  comment: string
  createdAt: string
  updatedAt: string
  resource_id: string
  resource_type: string
  author_name?: string | null
  author_email?: string | null
  client?: string | null
}

export type NotesQueryVariables = Exact<{
  patientToken?: InputMaybe<Scalars['String']>
  accountId?: InputMaybe<Scalars['String']>
  orderToken?: InputMaybe<Scalars['String']>
}>

export type NotesQuery = {
  __typename?: 'Query'
  notes: {
    __typename?: 'PatientNotes'
    notesCount: number
    pinnedNotes: Array<{ __typename?: 'PinnedNote' } & PinnedNoteFragment>
    unpinnedNotes: Array<{ __typename?: 'ExternalNote' } & ExternalNoteFragment>
  }
}

export type PinNoteMutationVariables = Exact<{
  pinnedNoteId: PinnedNoteId
}>

export type PinNoteMutation = {
  __typename?: 'Mutation'
  pinNote: { __typename?: 'PinNoteResponse'; isPinned: boolean; lastPinnedDate?: Date | null }
}

export type UnpinNoteMutationVariables = Exact<{
  pinnedNoteId: PinnedNoteId
}>

export type UnpinNoteMutation = { __typename?: 'Mutation'; unpinNote: boolean }

export type CancelOrderMutationVariables = Exact<{
  orderId: Scalars['String']
}>

export type CancelOrderMutation = {
  __typename?: 'Mutation'
  cancelOrder: {
    __typename?: 'OrderCancellation'
    request_id?: string | null
    timestamp?: string | null
    status?: string | null
    totalPrice?: number | null
    details?: {
      __typename?: 'OrderCancellationDetails'
      code?: string | null
      description?: string | null
      message?: string | null
    } | null
  }
}

export type PaymentOrderFragment = {
  __typename?: 'Payment'
  id?: string | null
  amount?: number | null
  status?: string | null
  statusDetail?: string | null
  cardLast4Digits?: string | null
  cardBrand?: string | null
  cardExpirationDate?: string | null
  shipping?: number | null
  taxes?: number | null
  discount?: number | null
  paymentIntentId?: string | null
}

export type AddressOrderFragment = {
  __typename?: 'Address'
  street1?: string | null
  street2?: string | null
  city?: string | null
  state?: string | null
  zip?: string | null
}

export type InsuranceOrderFragment = {
  __typename?: 'OrderInsurance'
  orderId?: string | null
  copayAmount?: number | null
  totalInsurancePayment?: number | null
  lineItems?: Array<{
    __typename?: 'InsuranceLineItem'
    lineItemId?: string | null
    copayCost?: number | null
    insurancePayment?: number | null
    bpe?: number | null
    primaryInsurance?: Array<{
      __typename?: 'PrimaryInsurance'
      claimStatus?: string | null
      rejectionCode?: { __typename?: 'InsuranceRejectionCode'; code?: string | null; message?: string | null } | null
    } | null> | null
  } | null> | null
}

export type LineItemOrderFragment = {
  __typename?: 'OrderLineItem'
  id?: string | null
  paymentId?: string | null
  sku?: string | null
  price?: number | null
  isHsa?: boolean | null
  isFsa?: boolean | null
  quantity?: number | null
  taxAmount?: number | null
  discountAmount?: number | null
}

export type OptumOrderDetailFragment = {
  __typename?: 'OrderFullDetails'
  createdAt?: Date | null
  cardNumber?: string | null
  orderId: string
  taxesPrice?: number | null
  totalPrice?: number | null
  status?: string | null
  trackingUrl?: string | null
  shippingPrice?: number | null
  shippingMethod?: string | null
  discount?: string | null
  callbackUrl?: string | null
  shippingMethodName?: string | null
  wildcard?: string | null
}

export type PaginationOrderFragment = {
  __typename?: 'OrderPagination'
  pageNumber: number
  pageCount: number
  total: number
}

export type ProductOrderFragment = {
  __typename?: 'Product'
  slug?: string | null
  sku?: string | null
  skuType?: string | null
  metaDescription?: string | null
  price?: number | null
  brand?: Array<string | null> | null
  image?: string | null
  thumbnail?: string | null
  name?: string | null
  quantity?: number | null
}

export type UserOrderFragment = {
  __typename?: 'OrderUser'
  userId?: string | null
  firstName?: string | null
  lastName?: string | null
  email?: string | null
  phone?: string | null
  urlToken?: string | null
}

export type OrderFullFragment = {
  __typename?: 'OrderFull'
  order: {
    __typename?: 'OrderFullDetails'
    wildcard?: string | null
    products?: Array<({ __typename?: 'Product' } & ProductOrderFragment) | null> | null
    lineItems?: Array<({ __typename?: 'OrderLineItem' } & LineItemOrderFragment) | null> | null
    insurance?: Array<({ __typename?: 'OrderInsurance' } & InsuranceOrderFragment) | null> | null
  } & OptumOrderDetailFragment
  address: { __typename?: 'Address' } & AddressOrderFragment
  insurance?: Array<({ __typename?: 'OrderInsurance' } & InsuranceOrderFragment) | null> | null
  user: { __typename?: 'OrderUser' } & UserOrderFragment
  payments?: Array<({ __typename?: 'Payment' } & PaymentOrderFragment) | null> | null
}

export type GetOrderQueryVariables = Exact<{
  orderId: Scalars['String']
}>

export type GetOrderQuery = {
  __typename?: 'Query'
  getOrder?: ({ __typename?: 'OrderFull' } & OrderFullFragment) | null
}

export type GetOrderInvoiceMutationVariables = Exact<{
  orderId: Scalars['String']
}>

export type GetOrderInvoiceMutation = {
  __typename?: 'Mutation'
  getOrderInvoice?: { __typename?: 'FetchOrderInvoiceResponse'; invoice: string } | null
}

export type GetOrderSupersetQueryVariables = Exact<{
  orderId: Scalars['String']
}>

export type GetOrderSupersetQuery = {
  __typename?: 'Query'
  getOrderSuperset?: {
    __typename?: 'OrderSuperset'
    id?: string | null
    status?: string | null
    url_token?: string | null
    client?: string | null
    created_at?: Date | null
    order_type?: { __typename?: 'OrderSupersetType'; full: string; short: string } | null
    fulfillment_vendors?: {
      __typename?: 'OrderSuperSetFullfilmentVendors'
      vendors_names: Array<string>
      order_number: string
    } | null
    patient?: {
      __typename?: 'OrderSupersetPatient'
      account_id?: string | null
      patient_token?: string | null
      first_name?: string | null
      last_name?: string | null
      email?: string | null
      phone?: string | null
      address?: {
        __typename?: 'OrderSupersetPatientAddress'
        street1?: string | null
        street2?: string | null
        city?: string | null
        state?: string | null
        zip?: string | null
      } | null
    } | null
    payment: Array<{
      __typename?: 'OrderSupersetPayment'
      payment_token?: string | null
      payment_type?: string | null
      status?: string | null
      promotion?: string | null
      sub_total?: number | null
      insurance_payment?: number | null
      tax_price?: number | null
      shipping_price?: number | null
      total_price?: number | null
      payment_card?: {
        __typename?: 'OrderSupersetPaymentCard'
        brand?: string | null
        last_digits?: string | null
        expiration?: Date | null
      } | null
    } | null>
    line_items: Array<{
      __typename?: 'OrderSupersetLineItem'
      id?: string | null
      rx_number?: string | null
      sku?: string | null
      name?: string | null
      quantity?: number | null
      price?: number | null
      insurance_price?: number | null
      copay_price?: number | null
      total_price?: number | null
      isHsa?: boolean | null
      isFsa?: boolean | null
      insurance_info?: {
        __typename?: 'OrderSupersetInsuranceInfo'
        carrier?: string | null
        cardholder_id?: string | null
        rx_group?: string | null
        rx_bin?: string | null
        pcn?: string | null
      } | null
    } | null>
    packages: Array<{
      __typename?: 'OrderSuperSetPackage'
      shipping_info?: {
        __typename?: 'OrderSuperSetPackageShippingInfo'
        shipment_id?: string | null
        tracking_url?: string | null
        shipping_status?: string | null
        shipping_method?: string | null
      } | null
      shipped_items?: Array<{
        __typename?: 'OrderSuperSetItem'
        sku?: string | null
        quantity?: number | null
      } | null> | null
    } | null>
    cancelled_items: Array<{ __typename?: 'OrderSuperSetItem'; sku?: string | null; quantity?: number | null } | null>
    pending_items: Array<{ __typename?: 'OrderSuperSetItem'; sku?: string | null; quantity?: number | null } | null>
  } | null
}

export type OptumOrderFragment = {
  __typename?: 'OrderDetails'
  callbackUrl?: string | null
  cardNumber?: string | null
  createdAt?: Date | null
  discount?: string | null
  shippingMethod?: string | null
  shippingMethodName?: string | null
  shippingPrice?: number | null
  status: string
  taxesPrice?: number | null
  totalPrice: number
  trackingUrl?: string | null
  orderId: string
  wildcard?: string | null
  products?: Array<({ __typename?: 'Product' } & ProductOrderFragment) | null> | null
}

export type OrderFragment = {
  __typename?: 'Order'
  address?: ({ __typename?: 'Address' } & AddressOrderFragment) | null
  order: { __typename?: 'OrderDetails' } & OptumOrderFragment
  user: { __typename?: 'OrderUser' } & UserOrderFragment
}

export type GetOrdersQueryVariables = Exact<{
  userId: Scalars['String']
  patientToken?: InputMaybe<Scalars['String']>
}>

export type GetOrdersQuery = {
  __typename?: 'Query'
  getOrders?: Array<({ __typename?: 'Order' } & OrderFragment) | null> | null
}

export type RefundOrderMutationVariables = Exact<{
  orderId: Scalars['String']
  refundedLineItems: Array<InputMaybe<RefundLineItem>> | InputMaybe<RefundLineItem>
}>

export type RefundOrderMutation = {
  __typename?: 'Mutation'
  refundOrder?: { __typename?: 'RefundResponce'; status?: string | null } | null
}

export type SearchOrderFullDetailsFragment = {
  __typename?: 'OrderFullDetails'
  products?: Array<({ __typename?: 'Product' } & ProductOrderFragment) | null> | null
} & OptumOrderDetailFragment

export type SearchOrderFullFragment = {
  __typename?: 'OrderFull'
  address: { __typename?: 'Address' } & AddressOrderFragment
  order: { __typename?: 'OrderFullDetails' } & SearchOrderFullDetailsFragment
  user: { __typename?: 'OrderUser' } & UserOrderFragment
}

export type SearchOrdersQueryVariables = Exact<{
  userId?: InputMaybe<Scalars['String']>
  email?: InputMaybe<Scalars['String']>
  pageNumber?: InputMaybe<Scalars['Int']>
  pageCount?: InputMaybe<Scalars['Int']>
  total?: InputMaybe<Scalars['Boolean']>
  descending?: InputMaybe<Scalars['Boolean']>
  guestCheckoutOnly?: InputMaybe<Scalars['Boolean']>
}>

export type SearchOrdersQuery = {
  __typename?: 'Query'
  searchOrders?: {
    __typename?: 'OrderSearch'
    data: Array<{ __typename?: 'OrderFull' } & SearchOrderFullFragment>
    pagination: { __typename?: 'OrderPagination' } & PaginationOrderFragment
  } | null
}

export type CancelAutofillMutationVariables = Exact<{
  prescriptionToken: Scalars['String']
}>

export type CancelAutofillMutation = {
  __typename?: 'Mutation'
  cancelAutofill?: { __typename?: 'CancelAutofillResponse'; status?: string | null } | null
}

export type GetPrescriptionsQueryVariables = Exact<{
  patientToken: Scalars['String']
}>

export type GetPrescriptionsQuery = {
  __typename?: 'Query'
  getPrescriptions: Array<{
    __typename?: 'TP_Prescription'
    prescription_token?: string | null
    rx_number?: string | null
    is_refill?: number | null
    days_supply?: string | null
    number_of_refills_allowed?: number | null
    medication_name?: string | null
    medication_sig?: string | null
    prescribed_quantity?: string | null
    quantity_remaining?: string | null
    refills_remaining?: string | null
    prescriber?: string | null
    date_written?: string | null
    last_filled_date?: string | null
    date_filled_utc?: string | null
    autofilledStatus?: string | null
    autofilledId?: string | null
    status?: string | null
    location?: string | null
  }>
}

export type CancelUserSubscriptionMutationVariables = Exact<{
  userId: Scalars['String']
}>

export type CancelUserSubscriptionMutation = {
  __typename?: 'Mutation'
  cancelUserSubscription?: { __typename?: 'SubscriptionCancelationResponse'; statusCode?: string | null } | null
}

export type SubscriptionPlanFragment = {
  __typename?: 'SubscriptionPlan'
  id?: string | null
  interval?: string | null
  initialInterval?: string | null
  name?: string | null
  description?: string | null
  serviceType?: string | null
  price?: string | null
}

export type SubscriptionFragment = {
  __typename?: 'Subcription'
  id?: string | null
  planId?: string | null
  userId?: string | null
  healthProfileId?: string | null
  status?: string | null
  subscriptionType?: string | null
  plan?: ({ __typename?: 'SubscriptionPlan' } & SubscriptionPlanFragment) | null
}

export type GetUserSubscriptionsQueryVariables = Exact<{
  userId: Scalars['String']
}>

export type GetUserSubscriptionsQuery = {
  __typename?: 'Query'
  getUserSubscriptions: Array<{ __typename?: 'Subcription' } & SubscriptionFragment>
}

export type CreateTeamMemberMutationVariables = Exact<{
  email: Scalars['String']
  roles?: InputMaybe<Array<InputMaybe<TeamMemberRolesInput>> | InputMaybe<TeamMemberRolesInput>>
}>

export type CreateTeamMemberMutation = {
  __typename?: 'Mutation'
  createTeamMember: {
    __typename?: 'AccountUser'
    user_id?: string | null
    email?: string | null
    roles?: Array<string | null> | null
    user_metadata?: { __typename?: 'MetaData'; tpcs_user?: boolean | null } | null
    app_metadata?: { __typename?: 'MetaData'; tpcs_user?: boolean | null } | null
  }
}

export type CreateTeamPermissionFragment = {
  __typename?: 'createTeamPermission'
  client: string
  role: string
  slug: string
}

export type GetTMcreatePermissionsQueryVariables = Exact<{ [key: string]: never }>

export type GetTMcreatePermissionsQuery = {
  __typename?: 'Query'
  getTMcreatePermissions: Array<{ __typename?: 'createTeamPermission' } & CreateTeamPermissionFragment>
}

export type TeamMemberRolesFragment = { __typename?: 'TeamMemberRoles'; name: string; description: string; id: string }

export type TeamMemberFragment = {
  __typename?: 'TeamMember'
  user_id: string
  family_name?: string | null
  given_name?: string | null
  email: string
  created_at?: string | null
  blocked?: boolean | null
  roles: Array<{ __typename?: 'TeamMemberRoles' } & TeamMemberRolesFragment>
  app_metadata?: { __typename?: 'TeamMemberAppMetadata'; tpcs_user?: string | null } | null
}

export type UserPaginationFragment = { __typename?: 'UserPagination'; limit: number; offset: number; total: number }

export type GetTeamMembersQueryVariables = Exact<{
  name?: InputMaybe<Scalars['String']>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}>

export type GetTeamMembersQuery = {
  __typename?: 'Query'
  getTeamMembers: {
    __typename?: 'TeamMemberSearch'
    data: Array<{ __typename?: 'TeamMember' } & TeamMemberFragment>
    pagination: { __typename?: 'UserPagination' } & UserPaginationFragment
  }
}

export type LinkTeamMemberMutationVariables = Exact<{
  email: Scalars['String']
}>

export type LinkTeamMemberMutation = {
  __typename?: 'Mutation'
  linkTeamMember: {
    __typename?: 'LinkedAccount'
    status?: boolean | null
    message?: string | null
    user?: {
      __typename?: 'AccountUser'
      roles?: Array<string | null> | null
      email?: string | null
      user_id?: string | null
    } | null
  }
}

export type GetAllRolesQueryVariables = Exact<{ [key: string]: never }>

export type GetAllRolesQuery = {
  __typename?: 'Query'
  getAllRoles?: Array<{
    __typename?: 'Roles'
    id?: string | null
    name?: string | null
    description?: string | null
  } | null> | null
}

export type UpdateTeamMemberMutationVariables = Exact<{
  id: Scalars['String']
  first_name?: InputMaybe<Scalars['String']>
  last_name?: InputMaybe<Scalars['String']>
  blocked?: InputMaybe<Scalars['Boolean']>
  roles?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>
  clients?: InputMaybe<Array<InputMaybe<ClientAccount>> | InputMaybe<ClientAccount>>
}>

export type UpdateTeamMemberMutation = {
  __typename?: 'Mutation'
  updateTeamMember?: {
    __typename?: 'TeamMember'
    user_id: string
    family_name?: string | null
    given_name?: string | null
    email: string
    blocked?: boolean | null
    roles: Array<{ __typename?: 'TeamMemberRoles'; name: string; description: string; id: string }>
    app_metadata?: { __typename?: 'TeamMemberAppMetadata'; tpcs_user?: string | null } | null
  } | null
}

export type UiConfigShowTabsFragment = {
  __typename?: 'UIConfigShowTabs'
  account: boolean
  healthProfiles: boolean
  orders: boolean
  insurances: boolean
  subscriptions: boolean
  addresses: boolean
  copays: boolean
}

export type UiConfigSearchEnabledFragment = {
  __typename?: 'UIConfigSearchEnabled'
  insurance: boolean
  email: boolean
  name: boolean
  order: boolean
}

export type UiConfigFragment = {
  __typename?: 'UIConfig'
  enableInsurance: boolean
  enableSearchByName: boolean
  enableInvoiceDownload: boolean
  enableDateOfBirth: boolean
  enableMemberId: boolean
  showCancelAutofill: boolean
  createCopayAllowed: boolean
  insurance: boolean
  editAccount: boolean
  orderTypeTag: boolean
  orderCancellationButton: boolean
  downloadInvoiceButton: boolean
  resetPasswordButton: boolean
  guestOrdersTable: boolean
  showPaymentToken: boolean
  shouldManageId: boolean
  isCheckoutCTA: boolean
  isPharmacy: boolean
  showOrderDetails: boolean
  activeCopayRequestStatuses: Array<ActiveCopayRequestStatuses>
  showAccountDeepLink: boolean
  showOrderDeepLink: boolean
  accountHasPatientToken: boolean
  defaultSearchBy: DefaultSearchBy
  hideOrderDetailsInsurance: boolean
  showTabs: { __typename?: 'UIConfigShowTabs' } & UiConfigShowTabsFragment
  searchEnabled: { __typename?: 'UIConfigSearchEnabled' } & UiConfigSearchEnabledFragment
}

export type CustomerFragment = {
  __typename?: 'Customer'
  customerId: string
  customerName: string
  customerSlug: string
  uiConfig: { __typename?: 'UIConfig' } & UiConfigFragment
}

export type GetCustomersQueryVariables = Exact<{ [key: string]: never }>

export type GetCustomersQuery = {
  __typename?: 'Query'
  getCustomers: Array<{ __typename?: 'Customer' } & CustomerFragment>
}

export const AccountFragmentDoc = gql`
  fragment Account on Account {
    id
    patientToken
    firstName
    lastName
    dob
    email
    countryCode
    mobileNumber
    street1
    street2
    city
    state
    zip
    createdAt
    client
  }
`
export const HealthProfileFragmentDoc = gql`
  fragment HealthProfile on HealthProfile {
    firstName
    lastName
    dob
    type
    sex
    allergies
    healthConditions
    medications
    patientToken
  }
`
export const AddressFragmentDoc = gql`
  fragment Address on Address {
    id
    isDefault
    street1
    street2
    city
    state
    zip
    firstName
    lastName
  }
`
export const CopayFragmentDoc = gql`
  fragment Copay on Copay {
    id
    copay_token
    patient_token
    status
    error_code
    notes
    insurance_tokens
    ship_to_state
    rejection_reason
    additional_rejection_details
    updated_at
    created_at
    checkout_link
    is_checkout_link_active
    create_checkout_link
    patient {
      last_name
      first_name
      gender
      dob
    }
    insurance {
      cardholder_id
      rx_group
      rx_bin
      pcn
      payer_name
      relationship
      is_primary
    }
    prescriptions {
      rx_number
      prescriber
      medication_name
      url_token
      prescription_token
      rejection_reason
      additional_rejection_details
      quantity
      prescribed_written_name
      prescription_status
      copay
    }
  }
`
export const UserInfoFragmentDoc = gql`
  fragment UserInfo on AccountUser {
    permissions
    picture
    email
    name
    username
    email_verified
    verify_email
    user_id
    nickname
    phone_number
    given_name
    family_name
  }
`
export const ExternalNoteFragmentDoc = gql`
  fragment ExternalNote on ExternalNote {
    source
    sourceId
    authorName
    note
    tags
    link
    createdAt
  }
`
export const PinnedNoteFragmentDoc = gql`
  fragment PinnedNote on PinnedNote {
    source
    sourceId
    authorName
    note
    tags
    link
    createdAt
    lastPinnedDate
  }
`
export const NoteFragmentDoc = gql`
  fragment Note on Note {
    id
    comment
    createdAt
    updatedAt
    resource_id
    resource_type
    author_name
    author_email
    client
  }
`
export const PaginationOrderFragmentDoc = gql`
  fragment PaginationOrder on OrderPagination {
    pageNumber
    pageCount
    total
  }
`
export const OptumOrderDetailFragmentDoc = gql`
  fragment OptumOrderDetail on OrderFullDetails {
    createdAt
    cardNumber
    orderId
    taxesPrice
    totalPrice
    status
    trackingUrl
    shippingPrice
    shippingMethod
    discount
    callbackUrl
    shippingMethodName
    wildcard
  }
`
export const ProductOrderFragmentDoc = gql`
  fragment ProductOrder on Product {
    slug
    sku
    skuType
    metaDescription
    price
    brand
    image
    thumbnail
    name
    quantity
  }
`
export const LineItemOrderFragmentDoc = gql`
  fragment LineItemOrder on OrderLineItem {
    id
    paymentId
    sku
    price
    isHsa
    isFsa
    quantity
    taxAmount
    discountAmount
  }
`
export const InsuranceOrderFragmentDoc = gql`
  fragment InsuranceOrder on OrderInsurance {
    orderId
    lineItems {
      lineItemId
      copayCost
      insurancePayment
      bpe
      primaryInsurance {
        claimStatus
        rejectionCode {
          code
          message
        }
      }
    }
    copayAmount
    totalInsurancePayment
  }
`
export const AddressOrderFragmentDoc = gql`
  fragment AddressOrder on Address {
    street1
    street2
    city
    state
    zip
  }
`
export const UserOrderFragmentDoc = gql`
  fragment UserOrder on OrderUser {
    userId
    firstName
    lastName
    email
    phone
    urlToken
  }
`
export const PaymentOrderFragmentDoc = gql`
  fragment PaymentOrder on Payment {
    id
    amount
    status
    statusDetail
    cardLast4Digits
    cardBrand
    cardExpirationDate
    shipping
    taxes
    discount
    paymentIntentId
  }
`
export const OrderFullFragmentDoc = gql`
  fragment OrderFull on OrderFull {
    order {
      ...OptumOrderDetail
      products {
        ...ProductOrder
      }
      lineItems {
        ...LineItemOrder
      }
      insurance {
        ...InsuranceOrder
      }
      wildcard
    }
    address {
      ...AddressOrder
    }
    insurance {
      ...InsuranceOrder
    }
    user {
      ...UserOrder
    }
    payments {
      ...PaymentOrder
    }
  }
  ${OptumOrderDetailFragmentDoc}
  ${ProductOrderFragmentDoc}
  ${LineItemOrderFragmentDoc}
  ${InsuranceOrderFragmentDoc}
  ${AddressOrderFragmentDoc}
  ${UserOrderFragmentDoc}
  ${PaymentOrderFragmentDoc}
`
export const OptumOrderFragmentDoc = gql`
  fragment OptumOrder on OrderDetails {
    callbackUrl
    cardNumber
    createdAt
    discount
    shippingMethod
    shippingMethodName
    shippingPrice
    status
    taxesPrice
    totalPrice
    trackingUrl
    orderId
    products {
      ...ProductOrder
    }
    wildcard
  }
  ${ProductOrderFragmentDoc}
`
export const OrderFragmentDoc = gql`
  fragment Order on Order {
    address {
      ...AddressOrder
    }
    order {
      ...OptumOrder
    }
    user {
      ...UserOrder
    }
  }
  ${AddressOrderFragmentDoc}
  ${OptumOrderFragmentDoc}
  ${UserOrderFragmentDoc}
`
export const SearchOrderFullDetailsFragmentDoc = gql`
  fragment SearchOrderFullDetails on OrderFullDetails {
    ...OptumOrderDetail
    products {
      ...ProductOrder
    }
  }
  ${OptumOrderDetailFragmentDoc}
  ${ProductOrderFragmentDoc}
`
export const SearchOrderFullFragmentDoc = gql`
  fragment SearchOrderFull on OrderFull {
    address {
      ...AddressOrder
    }
    order {
      ...SearchOrderFullDetails
    }
    user {
      ...UserOrder
    }
  }
  ${AddressOrderFragmentDoc}
  ${SearchOrderFullDetailsFragmentDoc}
  ${UserOrderFragmentDoc}
`
export const SubscriptionPlanFragmentDoc = gql`
  fragment SubscriptionPlan on SubscriptionPlan {
    id
    interval
    initialInterval
    name
    description
    serviceType
    price
  }
`
export const SubscriptionFragmentDoc = gql`
  fragment Subscription on Subcription {
    id
    planId
    plan {
      ...SubscriptionPlan
    }
    userId
    healthProfileId
    status
    subscriptionType
  }
  ${SubscriptionPlanFragmentDoc}
`
export const CreateTeamPermissionFragmentDoc = gql`
  fragment CreateTeamPermission on createTeamPermission {
    client
    role
    slug
  }
`
export const TeamMemberRolesFragmentDoc = gql`
  fragment TeamMemberRoles on TeamMemberRoles {
    name
    description
    id
  }
`
export const TeamMemberFragmentDoc = gql`
  fragment TeamMember on TeamMember {
    user_id
    family_name
    given_name
    email
    roles {
      ...TeamMemberRoles
    }
    created_at
    blocked
    app_metadata {
      tpcs_user
    }
  }
  ${TeamMemberRolesFragmentDoc}
`
export const UserPaginationFragmentDoc = gql`
  fragment UserPagination on UserPagination {
    limit
    offset
    total
  }
`
export const UiConfigShowTabsFragmentDoc = gql`
  fragment UIConfigShowTabs on UIConfigShowTabs {
    account
    healthProfiles
    orders
    insurances
    subscriptions
    addresses
    copays
  }
`
export const UiConfigSearchEnabledFragmentDoc = gql`
  fragment UIConfigSearchEnabled on UIConfigSearchEnabled {
    insurance
    email
    name
    order
  }
`
export const UiConfigFragmentDoc = gql`
  fragment UiConfig on UIConfig {
    enableInsurance
    enableSearchByName
    enableInvoiceDownload
    enableDateOfBirth
    enableMemberId
    showCancelAutofill
    createCopayAllowed
    insurance
    editAccount
    orderTypeTag
    orderCancellationButton
    downloadInvoiceButton
    resetPasswordButton
    guestOrdersTable
    showPaymentToken
    shouldManageId
    isCheckoutCTA
    isPharmacy
    showOrderDetails
    activeCopayRequestStatuses
    showAccountDeepLink
    showOrderDeepLink
    accountHasPatientToken
    showTabs {
      ...UIConfigShowTabs
    }
    defaultSearchBy
    searchEnabled {
      ...UIConfigSearchEnabled
    }
    hideOrderDetailsInsurance
  }
  ${UiConfigShowTabsFragmentDoc}
  ${UiConfigSearchEnabledFragmentDoc}
`
export const CustomerFragmentDoc = gql`
  fragment Customer on Customer {
    customerId
    customerName
    customerSlug
    uiConfig {
      ...UiConfig
    }
  }
  ${UiConfigFragmentDoc}
`
export const ChangePasswordDocument = gql`
  mutation changePassword($password: String!) {
    changePassword(password: $password) {
      id
      roles
      username
    }
  }
`
export type ChangePasswordMutationFn = Apollo.MutationFunction<ChangePasswordMutation, ChangePasswordMutationVariables>

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      password: // value for 'password'
 *   },
 * });
 */
export function useChangePasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<ChangePasswordMutation, ChangePasswordMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<ChangePasswordMutation, ChangePasswordMutationVariables>(ChangePasswordDocument, options)
}
export type ChangePasswordMutationHookResult = ReturnType<typeof useChangePasswordMutation>
export type ChangePasswordMutationResult = Apollo.MutationResult<ChangePasswordMutation>
export type ChangePasswordMutationOptions = Apollo.BaseMutationOptions<
  ChangePasswordMutation,
  ChangePasswordMutationVariables
>
export const GetAccountsDocument = gql`
  query getAccounts(
    $email: String
    $firstName: String
    $lastName: String
    $dob: String
    $memberId: String
    $patientToken: String
    $gender: String
  ) {
    getAccounts(
      email: $email
      firstName: $firstName
      lastName: $lastName
      dob: $dob
      memberId: $memberId
      patientToken: $patientToken
      gender: $gender
    ) {
      ...Account
    }
  }
  ${AccountFragmentDoc}
`

/**
 * __useGetAccountsQuery__
 *
 * To run a query within a React component, call `useGetAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountsQuery({
 *   variables: {
 *      email: // value for 'email'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      dob: // value for 'dob'
 *      memberId: // value for 'memberId'
 *      patientToken: // value for 'patientToken'
 *      gender: // value for 'gender'
 *   },
 * });
 */
export function useGetAccountsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetAccountsQuery, GetAccountsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetAccountsQuery, GetAccountsQueryVariables>(GetAccountsDocument, options)
}
export function useGetAccountsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAccountsQuery, GetAccountsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetAccountsQuery, GetAccountsQueryVariables>(GetAccountsDocument, options)
}
export type GetAccountsQueryHookResult = ReturnType<typeof useGetAccountsQuery>
export type GetAccountsLazyQueryHookResult = ReturnType<typeof useGetAccountsLazyQuery>
export type GetAccountsQueryResult = Apollo.QueryResult<GetAccountsQuery, GetAccountsQueryVariables>
export const GetHealthProfilesDocument = gql`
  query getHealthProfiles($userId: String, $patientToken: String) {
    getHealthProfiles(userId: $userId, patientToken: $patientToken) {
      ...HealthProfile
    }
  }
  ${HealthProfileFragmentDoc}
`

/**
 * __useGetHealthProfilesQuery__
 *
 * To run a query within a React component, call `useGetHealthProfilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHealthProfilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHealthProfilesQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      patientToken: // value for 'patientToken'
 *   },
 * });
 */
export function useGetHealthProfilesQuery(
  baseOptions?: Apollo.QueryHookOptions<GetHealthProfilesQuery, GetHealthProfilesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetHealthProfilesQuery, GetHealthProfilesQueryVariables>(GetHealthProfilesDocument, options)
}
export function useGetHealthProfilesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetHealthProfilesQuery, GetHealthProfilesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetHealthProfilesQuery, GetHealthProfilesQueryVariables>(
    GetHealthProfilesDocument,
    options
  )
}
export type GetHealthProfilesQueryHookResult = ReturnType<typeof useGetHealthProfilesQuery>
export type GetHealthProfilesLazyQueryHookResult = ReturnType<typeof useGetHealthProfilesLazyQuery>
export type GetHealthProfilesQueryResult = Apollo.QueryResult<GetHealthProfilesQuery, GetHealthProfilesQueryVariables>
export const SendPasswordResetEmailDocument = gql`
  mutation sendPasswordResetEmail($userId: ID!) {
    sendPasswordResetEmail(_id: $userId) {
      _id
      status
    }
  }
`
export type SendPasswordResetEmailMutationFn = Apollo.MutationFunction<
  SendPasswordResetEmailMutation,
  SendPasswordResetEmailMutationVariables
>

/**
 * __useSendPasswordResetEmailMutation__
 *
 * To run a mutation, you first call `useSendPasswordResetEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendPasswordResetEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendPasswordResetEmailMutation, { data, loading, error }] = useSendPasswordResetEmailMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useSendPasswordResetEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<SendPasswordResetEmailMutation, SendPasswordResetEmailMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<SendPasswordResetEmailMutation, SendPasswordResetEmailMutationVariables>(
    SendPasswordResetEmailDocument,
    options
  )
}
export type SendPasswordResetEmailMutationHookResult = ReturnType<typeof useSendPasswordResetEmailMutation>
export type SendPasswordResetEmailMutationResult = Apollo.MutationResult<SendPasswordResetEmailMutation>
export type SendPasswordResetEmailMutationOptions = Apollo.BaseMutationOptions<
  SendPasswordResetEmailMutation,
  SendPasswordResetEmailMutationVariables
>
export const UpdateAccountDocument = gql`
  mutation updateAccount($id: String!, $email: String, $mobileNumber: String) {
    updateAccount(id: $id, mobileNumber: $mobileNumber, email: $email) {
      id
      createdAt
      updatedAt
      firstName
      lastName
      mobileNumber
      countryCode
      email
      addresses {
        street1
        street2
        city
        state
        zip
        __typename
      }
    }
  }
`
export type UpdateAccountMutationFn = Apollo.MutationFunction<UpdateAccountMutation, UpdateAccountMutationVariables>

/**
 * __useUpdateAccountMutation__
 *
 * To run a mutation, you first call `useUpdateAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccountMutation, { data, loading, error }] = useUpdateAccountMutation({
 *   variables: {
 *      id: // value for 'id'
 *      email: // value for 'email'
 *      mobileNumber: // value for 'mobileNumber'
 *   },
 * });
 */
export function useUpdateAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateAccountMutation, UpdateAccountMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateAccountMutation, UpdateAccountMutationVariables>(UpdateAccountDocument, options)
}
export type UpdateAccountMutationHookResult = ReturnType<typeof useUpdateAccountMutation>
export type UpdateAccountMutationResult = Apollo.MutationResult<UpdateAccountMutation>
export type UpdateAccountMutationOptions = Apollo.BaseMutationOptions<
  UpdateAccountMutation,
  UpdateAccountMutationVariables
>
export const CreateAddressDocument = gql`
  mutation createAddress(
    $userId: String!
    $isDefault: Boolean!
    $address1: String!
    $address2: String
    $state: String!
    $city: String!
    $firstName: String!
    $lastName: String!
    $zip: String!
  ) {
    createAddress(
      input: {
        userId: $userId
        isDefault: $isDefault
        address1: $address1
        address2: $address2
        state: $state
        city: $city
        firstName: $firstName
        lastName: $lastName
        zip: $zip
      }
    ) {
      id
      isDefault
      street1
      street2
      state
      city
      zip
    }
  }
`
export type CreateAddressMutationFn = Apollo.MutationFunction<CreateAddressMutation, CreateAddressMutationVariables>

/**
 * __useCreateAddressMutation__
 *
 * To run a mutation, you first call `useCreateAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAddressMutation, { data, loading, error }] = useCreateAddressMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      isDefault: // value for 'isDefault'
 *      address1: // value for 'address1'
 *      address2: // value for 'address2'
 *      state: // value for 'state'
 *      city: // value for 'city'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      zip: // value for 'zip'
 *   },
 * });
 */
export function useCreateAddressMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateAddressMutation, CreateAddressMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateAddressMutation, CreateAddressMutationVariables>(CreateAddressDocument, options)
}
export type CreateAddressMutationHookResult = ReturnType<typeof useCreateAddressMutation>
export type CreateAddressMutationResult = Apollo.MutationResult<CreateAddressMutation>
export type CreateAddressMutationOptions = Apollo.BaseMutationOptions<
  CreateAddressMutation,
  CreateAddressMutationVariables
>
export const GetAddressesDocument = gql`
  query getAddresses($userId: String!) {
    getAddresses(userId: $userId) {
      ...Address
    }
  }
  ${AddressFragmentDoc}
`

/**
 * __useGetAddressesQuery__
 *
 * To run a query within a React component, call `useGetAddressesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAddressesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAddressesQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetAddressesQuery(
  baseOptions: Apollo.QueryHookOptions<GetAddressesQuery, GetAddressesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetAddressesQuery, GetAddressesQueryVariables>(GetAddressesDocument, options)
}
export function useGetAddressesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAddressesQuery, GetAddressesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetAddressesQuery, GetAddressesQueryVariables>(GetAddressesDocument, options)
}
export type GetAddressesQueryHookResult = ReturnType<typeof useGetAddressesQuery>
export type GetAddressesLazyQueryHookResult = ReturnType<typeof useGetAddressesLazyQuery>
export type GetAddressesQueryResult = Apollo.QueryResult<GetAddressesQuery, GetAddressesQueryVariables>
export const UpdateAddressDocument = gql`
  mutation updateAddress(
    $userId: String!
    $addressId: String!
    $isDefault: Boolean!
    $address1: String!
    $address2: String
    $state: String!
    $city: String!
    $firstName: String!
    $lastName: String!
    $zip: String!
  ) {
    updateAddress(
      input: {
        userId: $userId
        addressId: $addressId
        isDefault: $isDefault
        address1: $address1
        address2: $address2
        state: $state
        city: $city
        firstName: $firstName
        lastName: $lastName
        zip: $zip
      }
    ) {
      id
      isDefault
      street1
      street2
      state
      city
      zip
    }
  }
`
export type UpdateAddressMutationFn = Apollo.MutationFunction<UpdateAddressMutation, UpdateAddressMutationVariables>

/**
 * __useUpdateAddressMutation__
 *
 * To run a mutation, you first call `useUpdateAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAddressMutation, { data, loading, error }] = useUpdateAddressMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      addressId: // value for 'addressId'
 *      isDefault: // value for 'isDefault'
 *      address1: // value for 'address1'
 *      address2: // value for 'address2'
 *      state: // value for 'state'
 *      city: // value for 'city'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      zip: // value for 'zip'
 *   },
 * });
 */
export function useUpdateAddressMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateAddressMutation, UpdateAddressMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateAddressMutation, UpdateAddressMutationVariables>(UpdateAddressDocument, options)
}
export type UpdateAddressMutationHookResult = ReturnType<typeof useUpdateAddressMutation>
export type UpdateAddressMutationResult = Apollo.MutationResult<UpdateAddressMutation>
export type UpdateAddressMutationOptions = Apollo.BaseMutationOptions<
  UpdateAddressMutation,
  UpdateAddressMutationVariables
>
export const CreateCopayDocument = gql`
  mutation createCopay($customerId: String!, $prescriptionToken: String!) {
    createCopay(customerId: $customerId, prescriptionToken: $prescriptionToken) {
      status
      statusText
    }
  }
`
export type CreateCopayMutationFn = Apollo.MutationFunction<CreateCopayMutation, CreateCopayMutationVariables>

/**
 * __useCreateCopayMutation__
 *
 * To run a mutation, you first call `useCreateCopayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCopayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCopayMutation, { data, loading, error }] = useCreateCopayMutation({
 *   variables: {
 *      customerId: // value for 'customerId'
 *      prescriptionToken: // value for 'prescriptionToken'
 *   },
 * });
 */
export function useCreateCopayMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateCopayMutation, CreateCopayMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateCopayMutation, CreateCopayMutationVariables>(CreateCopayDocument, options)
}
export type CreateCopayMutationHookResult = ReturnType<typeof useCreateCopayMutation>
export type CreateCopayMutationResult = Apollo.MutationResult<CreateCopayMutation>
export type CreateCopayMutationOptions = Apollo.BaseMutationOptions<CreateCopayMutation, CreateCopayMutationVariables>
export const CreateLinkedCheckoutDocument = gql`
  mutation createLinkedCheckout($copay_token: String!) {
    createLinkedCheckout(copayToken: $copay_token) {
      status
    }
  }
`
export type CreateLinkedCheckoutMutationFn = Apollo.MutationFunction<
  CreateLinkedCheckoutMutation,
  CreateLinkedCheckoutMutationVariables
>

/**
 * __useCreateLinkedCheckoutMutation__
 *
 * To run a mutation, you first call `useCreateLinkedCheckoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLinkedCheckoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLinkedCheckoutMutation, { data, loading, error }] = useCreateLinkedCheckoutMutation({
 *   variables: {
 *      copay_token: // value for 'copay_token'
 *   },
 * });
 */
export function useCreateLinkedCheckoutMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateLinkedCheckoutMutation, CreateLinkedCheckoutMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateLinkedCheckoutMutation, CreateLinkedCheckoutMutationVariables>(
    CreateLinkedCheckoutDocument,
    options
  )
}
export type CreateLinkedCheckoutMutationHookResult = ReturnType<typeof useCreateLinkedCheckoutMutation>
export type CreateLinkedCheckoutMutationResult = Apollo.MutationResult<CreateLinkedCheckoutMutation>
export type CreateLinkedCheckoutMutationOptions = Apollo.BaseMutationOptions<
  CreateLinkedCheckoutMutation,
  CreateLinkedCheckoutMutationVariables
>
export const GetCopaysDocument = gql`
  query getCopays($patientToken: String!, $copayStatuses: [String]) {
    getCopays(patientToken: $patientToken, copayStatuses: $copayStatuses) {
      ...Copay
    }
  }
  ${CopayFragmentDoc}
`

/**
 * __useGetCopaysQuery__
 *
 * To run a query within a React component, call `useGetCopaysQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCopaysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCopaysQuery({
 *   variables: {
 *      patientToken: // value for 'patientToken'
 *      copayStatuses: // value for 'copayStatuses'
 *   },
 * });
 */
export function useGetCopaysQuery(baseOptions: Apollo.QueryHookOptions<GetCopaysQuery, GetCopaysQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetCopaysQuery, GetCopaysQueryVariables>(GetCopaysDocument, options)
}
export function useGetCopaysLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCopaysQuery, GetCopaysQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetCopaysQuery, GetCopaysQueryVariables>(GetCopaysDocument, options)
}
export type GetCopaysQueryHookResult = ReturnType<typeof useGetCopaysQuery>
export type GetCopaysLazyQueryHookResult = ReturnType<typeof useGetCopaysLazyQuery>
export type GetCopaysQueryResult = Apollo.QueryResult<GetCopaysQuery, GetCopaysQueryVariables>
export const GetLastSubmitDateDocument = gql`
  query getLastSubmitDate($prescriptionToken: String!) {
    getLastSubmitDate(prescriptionToken: $prescriptionToken) {
      date
    }
  }
`

/**
 * __useGetLastSubmitDateQuery__
 *
 * To run a query within a React component, call `useGetLastSubmitDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLastSubmitDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLastSubmitDateQuery({
 *   variables: {
 *      prescriptionToken: // value for 'prescriptionToken'
 *   },
 * });
 */
export function useGetLastSubmitDateQuery(
  baseOptions: Apollo.QueryHookOptions<GetLastSubmitDateQuery, GetLastSubmitDateQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetLastSubmitDateQuery, GetLastSubmitDateQueryVariables>(GetLastSubmitDateDocument, options)
}
export function useGetLastSubmitDateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetLastSubmitDateQuery, GetLastSubmitDateQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetLastSubmitDateQuery, GetLastSubmitDateQueryVariables>(
    GetLastSubmitDateDocument,
    options
  )
}
export type GetLastSubmitDateQueryHookResult = ReturnType<typeof useGetLastSubmitDateQuery>
export type GetLastSubmitDateLazyQueryHookResult = ReturnType<typeof useGetLastSubmitDateLazyQuery>
export type GetLastSubmitDateQueryResult = Apollo.QueryResult<GetLastSubmitDateQuery, GetLastSubmitDateQueryVariables>
export const UpdateAndInitiateCopayCheckDocument = gql`
  mutation updateAndInitiateCopayCheck(
    $copayToken: String!
    $patientToken: String!
    $primaryInsurance: InputInsurace
    $secondaryInsurance: InputInsurace
  ) {
    updateAndInitiateCopayCheck(
      copayToken: $copayToken
      patientToken: $patientToken
      primaryInsurance: $primaryInsurance
      secondaryInsurance: $secondaryInsurance
    ) {
      status
    }
  }
`
export type UpdateAndInitiateCopayCheckMutationFn = Apollo.MutationFunction<
  UpdateAndInitiateCopayCheckMutation,
  UpdateAndInitiateCopayCheckMutationVariables
>

/**
 * __useUpdateAndInitiateCopayCheckMutation__
 *
 * To run a mutation, you first call `useUpdateAndInitiateCopayCheckMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAndInitiateCopayCheckMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAndInitiateCopayCheckMutation, { data, loading, error }] = useUpdateAndInitiateCopayCheckMutation({
 *   variables: {
 *      copayToken: // value for 'copayToken'
 *      patientToken: // value for 'patientToken'
 *      primaryInsurance: // value for 'primaryInsurance'
 *      secondaryInsurance: // value for 'secondaryInsurance'
 *   },
 * });
 */
export function useUpdateAndInitiateCopayCheckMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAndInitiateCopayCheckMutation,
    UpdateAndInitiateCopayCheckMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateAndInitiateCopayCheckMutation, UpdateAndInitiateCopayCheckMutationVariables>(
    UpdateAndInitiateCopayCheckDocument,
    options
  )
}
export type UpdateAndInitiateCopayCheckMutationHookResult = ReturnType<typeof useUpdateAndInitiateCopayCheckMutation>
export type UpdateAndInitiateCopayCheckMutationResult = Apollo.MutationResult<UpdateAndInitiateCopayCheckMutation>
export type UpdateAndInitiateCopayCheckMutationOptions = Apollo.BaseMutationOptions<
  UpdateAndInitiateCopayCheckMutation,
  UpdateAndInitiateCopayCheckMutationVariables
>
export const GetUserInfoDocument = gql`
  query getUserInfo {
    me {
      ...UserInfo
    }
  }
  ${UserInfoFragmentDoc}
`

/**
 * __useGetUserInfoQuery__
 *
 * To run a query within a React component, call `useGetUserInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserInfoQuery(
  baseOptions?: Apollo.QueryHookOptions<GetUserInfoQuery, GetUserInfoQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetUserInfoQuery, GetUserInfoQueryVariables>(GetUserInfoDocument, options)
}
export function useGetUserInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetUserInfoQuery, GetUserInfoQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetUserInfoQuery, GetUserInfoQueryVariables>(GetUserInfoDocument, options)
}
export type GetUserInfoQueryHookResult = ReturnType<typeof useGetUserInfoQuery>
export type GetUserInfoLazyQueryHookResult = ReturnType<typeof useGetUserInfoLazyQuery>
export type GetUserInfoQueryResult = Apollo.QueryResult<GetUserInfoQuery, GetUserInfoQueryVariables>
export const CreatePatientInsuranceDocument = gql`
  mutation CreatePatientInsurance($input: CreatePatientInsuranceInput!) {
    createPatientInsurance(input: $input) {
      ok
      added {
        insuranceToken
        insuranceCarrier
        memberId
        rxBin
        rxPcn
        rxGroup
        insuranceType
      }
    }
  }
`
export type CreatePatientInsuranceMutationFn = Apollo.MutationFunction<
  CreatePatientInsuranceMutation,
  CreatePatientInsuranceMutationVariables
>

/**
 * __useCreatePatientInsuranceMutation__
 *
 * To run a mutation, you first call `useCreatePatientInsuranceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePatientInsuranceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPatientInsuranceMutation, { data, loading, error }] = useCreatePatientInsuranceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePatientInsuranceMutation(
  baseOptions?: Apollo.MutationHookOptions<CreatePatientInsuranceMutation, CreatePatientInsuranceMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreatePatientInsuranceMutation, CreatePatientInsuranceMutationVariables>(
    CreatePatientInsuranceDocument,
    options
  )
}
export type CreatePatientInsuranceMutationHookResult = ReturnType<typeof useCreatePatientInsuranceMutation>
export type CreatePatientInsuranceMutationResult = Apollo.MutationResult<CreatePatientInsuranceMutation>
export type CreatePatientInsuranceMutationOptions = Apollo.BaseMutationOptions<
  CreatePatientInsuranceMutation,
  CreatePatientInsuranceMutationVariables
>
export const RemovePatientInsuranceDocument = gql`
  mutation RemovePatientInsurance($patientToken: String!) {
    removePatientInsurance(patientToken: $patientToken) {
      ok
    }
  }
`
export type RemovePatientInsuranceMutationFn = Apollo.MutationFunction<
  RemovePatientInsuranceMutation,
  RemovePatientInsuranceMutationVariables
>

/**
 * __useRemovePatientInsuranceMutation__
 *
 * To run a mutation, you first call `useRemovePatientInsuranceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemovePatientInsuranceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removePatientInsuranceMutation, { data, loading, error }] = useRemovePatientInsuranceMutation({
 *   variables: {
 *      patientToken: // value for 'patientToken'
 *   },
 * });
 */
export function useRemovePatientInsuranceMutation(
  baseOptions?: Apollo.MutationHookOptions<RemovePatientInsuranceMutation, RemovePatientInsuranceMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<RemovePatientInsuranceMutation, RemovePatientInsuranceMutationVariables>(
    RemovePatientInsuranceDocument,
    options
  )
}
export type RemovePatientInsuranceMutationHookResult = ReturnType<typeof useRemovePatientInsuranceMutation>
export type RemovePatientInsuranceMutationResult = Apollo.MutationResult<RemovePatientInsuranceMutation>
export type RemovePatientInsuranceMutationOptions = Apollo.BaseMutationOptions<
  RemovePatientInsuranceMutation,
  RemovePatientInsuranceMutationVariables
>
export const GetPatientInsurancesDocument = gql`
  query getPatientInsurances($patientToken: String!) {
    patientInsurance(patientToken: $patientToken) {
      insuranceCarrier
      insuranceToken
      insuranceType
      memberId
      rxBin
      rxGroup
      rxPcn
    }
  }
`

/**
 * __useGetPatientInsurancesQuery__
 *
 * To run a query within a React component, call `useGetPatientInsurancesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPatientInsurancesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPatientInsurancesQuery({
 *   variables: {
 *      patientToken: // value for 'patientToken'
 *   },
 * });
 */
export function useGetPatientInsurancesQuery(
  baseOptions: Apollo.QueryHookOptions<GetPatientInsurancesQuery, GetPatientInsurancesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetPatientInsurancesQuery, GetPatientInsurancesQueryVariables>(
    GetPatientInsurancesDocument,
    options
  )
}
export function useGetPatientInsurancesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetPatientInsurancesQuery, GetPatientInsurancesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetPatientInsurancesQuery, GetPatientInsurancesQueryVariables>(
    GetPatientInsurancesDocument,
    options
  )
}
export type GetPatientInsurancesQueryHookResult = ReturnType<typeof useGetPatientInsurancesQuery>
export type GetPatientInsurancesLazyQueryHookResult = ReturnType<typeof useGetPatientInsurancesLazyQuery>
export type GetPatientInsurancesQueryResult = Apollo.QueryResult<
  GetPatientInsurancesQuery,
  GetPatientInsurancesQueryVariables
>
export const CreateNotesDocument = gql`
  mutation createNotes(
    $comment: String!
    $client: String!
    $resource_type: String!
    $resource_id: String!
    $author_name: String!
    $author_email: String
  ) {
    createNotes(
      input: {
        comment: $comment
        client: $client
        resource_type: $resource_type
        resource_id: $resource_id
        author_name: $author_name
        author_email: $author_email
      }
    ) {
      ...Note
    }
  }
  ${NoteFragmentDoc}
`
export type CreateNotesMutationFn = Apollo.MutationFunction<CreateNotesMutation, CreateNotesMutationVariables>

/**
 * __useCreateNotesMutation__
 *
 * To run a mutation, you first call `useCreateNotesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNotesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNotesMutation, { data, loading, error }] = useCreateNotesMutation({
 *   variables: {
 *      comment: // value for 'comment'
 *      client: // value for 'client'
 *      resource_type: // value for 'resource_type'
 *      resource_id: // value for 'resource_id'
 *      author_name: // value for 'author_name'
 *      author_email: // value for 'author_email'
 *   },
 * });
 */
export function useCreateNotesMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateNotesMutation, CreateNotesMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateNotesMutation, CreateNotesMutationVariables>(CreateNotesDocument, options)
}
export type CreateNotesMutationHookResult = ReturnType<typeof useCreateNotesMutation>
export type CreateNotesMutationResult = Apollo.MutationResult<CreateNotesMutation>
export type CreateNotesMutationOptions = Apollo.BaseMutationOptions<CreateNotesMutation, CreateNotesMutationVariables>
export const NotesDocument = gql`
  query notes($patientToken: String, $accountId: String, $orderToken: String) {
    notes(patientToken: $patientToken, accountId: $accountId, orderToken: $orderToken) {
      pinnedNotes {
        ...PinnedNote
      }
      unpinnedNotes {
        ...ExternalNote
      }
      notesCount
    }
  }
  ${PinnedNoteFragmentDoc}
  ${ExternalNoteFragmentDoc}
`

/**
 * __useNotesQuery__
 *
 * To run a query within a React component, call `useNotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotesQuery({
 *   variables: {
 *      patientToken: // value for 'patientToken'
 *      accountId: // value for 'accountId'
 *      orderToken: // value for 'orderToken'
 *   },
 * });
 */
export function useNotesQuery(baseOptions?: Apollo.QueryHookOptions<NotesQuery, NotesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<NotesQuery, NotesQueryVariables>(NotesDocument, options)
}
export function useNotesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NotesQuery, NotesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<NotesQuery, NotesQueryVariables>(NotesDocument, options)
}
export type NotesQueryHookResult = ReturnType<typeof useNotesQuery>
export type NotesLazyQueryHookResult = ReturnType<typeof useNotesLazyQuery>
export type NotesQueryResult = Apollo.QueryResult<NotesQuery, NotesQueryVariables>
export const PinNoteDocument = gql`
  mutation pinNote($pinnedNoteId: PinnedNoteId!) {
    pinNote(pinnedNoteId: $pinnedNoteId) {
      isPinned
      lastPinnedDate
    }
  }
`
export type PinNoteMutationFn = Apollo.MutationFunction<PinNoteMutation, PinNoteMutationVariables>

/**
 * __usePinNoteMutation__
 *
 * To run a mutation, you first call `usePinNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePinNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pinNoteMutation, { data, loading, error }] = usePinNoteMutation({
 *   variables: {
 *      pinnedNoteId: // value for 'pinnedNoteId'
 *   },
 * });
 */
export function usePinNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<PinNoteMutation, PinNoteMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<PinNoteMutation, PinNoteMutationVariables>(PinNoteDocument, options)
}
export type PinNoteMutationHookResult = ReturnType<typeof usePinNoteMutation>
export type PinNoteMutationResult = Apollo.MutationResult<PinNoteMutation>
export type PinNoteMutationOptions = Apollo.BaseMutationOptions<PinNoteMutation, PinNoteMutationVariables>
export const UnpinNoteDocument = gql`
  mutation unpinNote($pinnedNoteId: PinnedNoteId!) {
    unpinNote(pinnedNoteId: $pinnedNoteId)
  }
`
export type UnpinNoteMutationFn = Apollo.MutationFunction<UnpinNoteMutation, UnpinNoteMutationVariables>

/**
 * __useUnpinNoteMutation__
 *
 * To run a mutation, you first call `useUnpinNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnpinNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unpinNoteMutation, { data, loading, error }] = useUnpinNoteMutation({
 *   variables: {
 *      pinnedNoteId: // value for 'pinnedNoteId'
 *   },
 * });
 */
export function useUnpinNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<UnpinNoteMutation, UnpinNoteMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UnpinNoteMutation, UnpinNoteMutationVariables>(UnpinNoteDocument, options)
}
export type UnpinNoteMutationHookResult = ReturnType<typeof useUnpinNoteMutation>
export type UnpinNoteMutationResult = Apollo.MutationResult<UnpinNoteMutation>
export type UnpinNoteMutationOptions = Apollo.BaseMutationOptions<UnpinNoteMutation, UnpinNoteMutationVariables>
export const CancelOrderDocument = gql`
  mutation cancelOrder($orderId: String!) {
    cancelOrder(orderId: $orderId) {
      request_id
      timestamp
      status
      totalPrice
      details {
        code
        description
        message
      }
    }
  }
`
export type CancelOrderMutationFn = Apollo.MutationFunction<CancelOrderMutation, CancelOrderMutationVariables>

/**
 * __useCancelOrderMutation__
 *
 * To run a mutation, you first call `useCancelOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelOrderMutation, { data, loading, error }] = useCancelOrderMutation({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useCancelOrderMutation(
  baseOptions?: Apollo.MutationHookOptions<CancelOrderMutation, CancelOrderMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CancelOrderMutation, CancelOrderMutationVariables>(CancelOrderDocument, options)
}
export type CancelOrderMutationHookResult = ReturnType<typeof useCancelOrderMutation>
export type CancelOrderMutationResult = Apollo.MutationResult<CancelOrderMutation>
export type CancelOrderMutationOptions = Apollo.BaseMutationOptions<CancelOrderMutation, CancelOrderMutationVariables>
export const GetOrderDocument = gql`
  query getOrder($orderId: String!) {
    getOrder(orderId: $orderId) {
      ...OrderFull
    }
  }
  ${OrderFullFragmentDoc}
`

/**
 * __useGetOrderQuery__
 *
 * To run a query within a React component, call `useGetOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrderQuery({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useGetOrderQuery(baseOptions: Apollo.QueryHookOptions<GetOrderQuery, GetOrderQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetOrderQuery, GetOrderQueryVariables>(GetOrderDocument, options)
}
export function useGetOrderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrderQuery, GetOrderQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetOrderQuery, GetOrderQueryVariables>(GetOrderDocument, options)
}
export type GetOrderQueryHookResult = ReturnType<typeof useGetOrderQuery>
export type GetOrderLazyQueryHookResult = ReturnType<typeof useGetOrderLazyQuery>
export type GetOrderQueryResult = Apollo.QueryResult<GetOrderQuery, GetOrderQueryVariables>
export const GetOrderInvoiceDocument = gql`
  mutation getOrderInvoice($orderId: String!) {
    getOrderInvoice(orderId: $orderId) {
      invoice
    }
  }
`
export type GetOrderInvoiceMutationFn = Apollo.MutationFunction<
  GetOrderInvoiceMutation,
  GetOrderInvoiceMutationVariables
>

/**
 * __useGetOrderInvoiceMutation__
 *
 * To run a mutation, you first call `useGetOrderInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetOrderInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getOrderInvoiceMutation, { data, loading, error }] = useGetOrderInvoiceMutation({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useGetOrderInvoiceMutation(
  baseOptions?: Apollo.MutationHookOptions<GetOrderInvoiceMutation, GetOrderInvoiceMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<GetOrderInvoiceMutation, GetOrderInvoiceMutationVariables>(GetOrderInvoiceDocument, options)
}
export type GetOrderInvoiceMutationHookResult = ReturnType<typeof useGetOrderInvoiceMutation>
export type GetOrderInvoiceMutationResult = Apollo.MutationResult<GetOrderInvoiceMutation>
export type GetOrderInvoiceMutationOptions = Apollo.BaseMutationOptions<
  GetOrderInvoiceMutation,
  GetOrderInvoiceMutationVariables
>
export const GetOrderSupersetDocument = gql`
  query getOrderSuperset($orderId: String!) {
    getOrderSuperset(orderId: $orderId) {
      id
      status
      url_token
      client
      created_at
      order_type {
        full
        short
      }
      fulfillment_vendors {
        vendors_names
        order_number
      }
      patient {
        account_id
        patient_token
        first_name
        last_name
        email
        phone
        address {
          street1
          street2
          city
          state
          zip
        }
      }
      payment {
        payment_token
        payment_type
        status
        promotion
        sub_total
        insurance_payment
        tax_price
        shipping_price
        total_price
        payment_card {
          brand
          last_digits
          expiration
        }
      }
      line_items {
        id
        rx_number
        sku
        name
        quantity
        price
        insurance_price
        insurance_info {
          carrier
          cardholder_id
          rx_group
          rx_bin
          pcn
        }
        copay_price
        total_price
        isHsa
        isFsa
      }
      packages {
        shipping_info {
          shipment_id
          tracking_url
          shipping_status
          shipping_method
        }
        shipped_items {
          sku
          quantity
        }
      }
      cancelled_items {
        sku
        quantity
      }
      pending_items {
        sku
        quantity
      }
    }
  }
`

/**
 * __useGetOrderSupersetQuery__
 *
 * To run a query within a React component, call `useGetOrderSupersetQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrderSupersetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrderSupersetQuery({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useGetOrderSupersetQuery(
  baseOptions: Apollo.QueryHookOptions<GetOrderSupersetQuery, GetOrderSupersetQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetOrderSupersetQuery, GetOrderSupersetQueryVariables>(GetOrderSupersetDocument, options)
}
export function useGetOrderSupersetLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetOrderSupersetQuery, GetOrderSupersetQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetOrderSupersetQuery, GetOrderSupersetQueryVariables>(GetOrderSupersetDocument, options)
}
export type GetOrderSupersetQueryHookResult = ReturnType<typeof useGetOrderSupersetQuery>
export type GetOrderSupersetLazyQueryHookResult = ReturnType<typeof useGetOrderSupersetLazyQuery>
export type GetOrderSupersetQueryResult = Apollo.QueryResult<GetOrderSupersetQuery, GetOrderSupersetQueryVariables>
export const GetOrdersDocument = gql`
  query getOrders($userId: String!, $patientToken: String) {
    getOrders(userId: $userId, patientToken: $patientToken) {
      ...Order
    }
  }
  ${OrderFragmentDoc}
`

/**
 * __useGetOrdersQuery__
 *
 * To run a query within a React component, call `useGetOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrdersQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      patientToken: // value for 'patientToken'
 *   },
 * });
 */
export function useGetOrdersQuery(baseOptions: Apollo.QueryHookOptions<GetOrdersQuery, GetOrdersQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetOrdersQuery, GetOrdersQueryVariables>(GetOrdersDocument, options)
}
export function useGetOrdersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetOrdersQuery, GetOrdersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetOrdersQuery, GetOrdersQueryVariables>(GetOrdersDocument, options)
}
export type GetOrdersQueryHookResult = ReturnType<typeof useGetOrdersQuery>
export type GetOrdersLazyQueryHookResult = ReturnType<typeof useGetOrdersLazyQuery>
export type GetOrdersQueryResult = Apollo.QueryResult<GetOrdersQuery, GetOrdersQueryVariables>
export const RefundOrderDocument = gql`
  mutation refundOrder($orderId: String!, $refundedLineItems: [RefundLineItem]!) {
    refundOrder(orderId: $orderId, refundedLineItems: $refundedLineItems) {
      status
    }
  }
`
export type RefundOrderMutationFn = Apollo.MutationFunction<RefundOrderMutation, RefundOrderMutationVariables>

/**
 * __useRefundOrderMutation__
 *
 * To run a mutation, you first call `useRefundOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefundOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refundOrderMutation, { data, loading, error }] = useRefundOrderMutation({
 *   variables: {
 *      orderId: // value for 'orderId'
 *      refundedLineItems: // value for 'refundedLineItems'
 *   },
 * });
 */
export function useRefundOrderMutation(
  baseOptions?: Apollo.MutationHookOptions<RefundOrderMutation, RefundOrderMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<RefundOrderMutation, RefundOrderMutationVariables>(RefundOrderDocument, options)
}
export type RefundOrderMutationHookResult = ReturnType<typeof useRefundOrderMutation>
export type RefundOrderMutationResult = Apollo.MutationResult<RefundOrderMutation>
export type RefundOrderMutationOptions = Apollo.BaseMutationOptions<RefundOrderMutation, RefundOrderMutationVariables>
export const SearchOrdersDocument = gql`
  query searchOrders(
    $userId: String
    $email: String
    $pageNumber: Int
    $pageCount: Int
    $total: Boolean
    $descending: Boolean
    $guestCheckoutOnly: Boolean
  ) {
    searchOrders(
      userId: $userId
      email: $email
      pageNumber: $pageNumber
      pageCount: $pageCount
      total: $total
      descending: $descending
      guestCheckoutOnly: $guestCheckoutOnly
    ) {
      data {
        ...SearchOrderFull
      }
      pagination {
        ...PaginationOrder
      }
    }
  }
  ${SearchOrderFullFragmentDoc}
  ${PaginationOrderFragmentDoc}
`

/**
 * __useSearchOrdersQuery__
 *
 * To run a query within a React component, call `useSearchOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchOrdersQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      email: // value for 'email'
 *      pageNumber: // value for 'pageNumber'
 *      pageCount: // value for 'pageCount'
 *      total: // value for 'total'
 *      descending: // value for 'descending'
 *      guestCheckoutOnly: // value for 'guestCheckoutOnly'
 *   },
 * });
 */
export function useSearchOrdersQuery(
  baseOptions?: Apollo.QueryHookOptions<SearchOrdersQuery, SearchOrdersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<SearchOrdersQuery, SearchOrdersQueryVariables>(SearchOrdersDocument, options)
}
export function useSearchOrdersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SearchOrdersQuery, SearchOrdersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<SearchOrdersQuery, SearchOrdersQueryVariables>(SearchOrdersDocument, options)
}
export type SearchOrdersQueryHookResult = ReturnType<typeof useSearchOrdersQuery>
export type SearchOrdersLazyQueryHookResult = ReturnType<typeof useSearchOrdersLazyQuery>
export type SearchOrdersQueryResult = Apollo.QueryResult<SearchOrdersQuery, SearchOrdersQueryVariables>
export const CancelAutofillDocument = gql`
  mutation cancelAutofill($prescriptionToken: String!) {
    cancelAutofill(prescriptionToken: $prescriptionToken) {
      status
    }
  }
`
export type CancelAutofillMutationFn = Apollo.MutationFunction<CancelAutofillMutation, CancelAutofillMutationVariables>

/**
 * __useCancelAutofillMutation__
 *
 * To run a mutation, you first call `useCancelAutofillMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelAutofillMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelAutofillMutation, { data, loading, error }] = useCancelAutofillMutation({
 *   variables: {
 *      prescriptionToken: // value for 'prescriptionToken'
 *   },
 * });
 */
export function useCancelAutofillMutation(
  baseOptions?: Apollo.MutationHookOptions<CancelAutofillMutation, CancelAutofillMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CancelAutofillMutation, CancelAutofillMutationVariables>(CancelAutofillDocument, options)
}
export type CancelAutofillMutationHookResult = ReturnType<typeof useCancelAutofillMutation>
export type CancelAutofillMutationResult = Apollo.MutationResult<CancelAutofillMutation>
export type CancelAutofillMutationOptions = Apollo.BaseMutationOptions<
  CancelAutofillMutation,
  CancelAutofillMutationVariables
>
export const GetPrescriptionsDocument = gql`
  query getPrescriptions($patientToken: String!) {
    getPrescriptions(patientToken: $patientToken) {
      prescription_token
      rx_number
      is_refill
      days_supply
      number_of_refills_allowed
      medication_name
      medication_sig
      prescribed_quantity
      quantity_remaining
      refills_remaining
      prescriber
      date_written
      last_filled_date
      date_filled_utc
      autofilledStatus
      autofilledId
      status
      location
    }
  }
`

/**
 * __useGetPrescriptionsQuery__
 *
 * To run a query within a React component, call `useGetPrescriptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPrescriptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPrescriptionsQuery({
 *   variables: {
 *      patientToken: // value for 'patientToken'
 *   },
 * });
 */
export function useGetPrescriptionsQuery(
  baseOptions: Apollo.QueryHookOptions<GetPrescriptionsQuery, GetPrescriptionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetPrescriptionsQuery, GetPrescriptionsQueryVariables>(GetPrescriptionsDocument, options)
}
export function useGetPrescriptionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetPrescriptionsQuery, GetPrescriptionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetPrescriptionsQuery, GetPrescriptionsQueryVariables>(GetPrescriptionsDocument, options)
}
export type GetPrescriptionsQueryHookResult = ReturnType<typeof useGetPrescriptionsQuery>
export type GetPrescriptionsLazyQueryHookResult = ReturnType<typeof useGetPrescriptionsLazyQuery>
export type GetPrescriptionsQueryResult = Apollo.QueryResult<GetPrescriptionsQuery, GetPrescriptionsQueryVariables>
export const CancelUserSubscriptionDocument = gql`
  mutation cancelUserSubscription($userId: String!) {
    cancelUserSubscription(userId: $userId) {
      statusCode
    }
  }
`
export type CancelUserSubscriptionMutationFn = Apollo.MutationFunction<
  CancelUserSubscriptionMutation,
  CancelUserSubscriptionMutationVariables
>

/**
 * __useCancelUserSubscriptionMutation__
 *
 * To run a mutation, you first call `useCancelUserSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelUserSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelUserSubscriptionMutation, { data, loading, error }] = useCancelUserSubscriptionMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useCancelUserSubscriptionMutation(
  baseOptions?: Apollo.MutationHookOptions<CancelUserSubscriptionMutation, CancelUserSubscriptionMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CancelUserSubscriptionMutation, CancelUserSubscriptionMutationVariables>(
    CancelUserSubscriptionDocument,
    options
  )
}
export type CancelUserSubscriptionMutationHookResult = ReturnType<typeof useCancelUserSubscriptionMutation>
export type CancelUserSubscriptionMutationResult = Apollo.MutationResult<CancelUserSubscriptionMutation>
export type CancelUserSubscriptionMutationOptions = Apollo.BaseMutationOptions<
  CancelUserSubscriptionMutation,
  CancelUserSubscriptionMutationVariables
>
export const GetUserSubscriptionsDocument = gql`
  query getUserSubscriptions($userId: String!) {
    getUserSubscriptions(userId: $userId) {
      ...Subscription
    }
  }
  ${SubscriptionFragmentDoc}
`

/**
 * __useGetUserSubscriptionsQuery__
 *
 * To run a query within a React component, call `useGetUserSubscriptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserSubscriptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserSubscriptionsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetUserSubscriptionsQuery(
  baseOptions: Apollo.QueryHookOptions<GetUserSubscriptionsQuery, GetUserSubscriptionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetUserSubscriptionsQuery, GetUserSubscriptionsQueryVariables>(
    GetUserSubscriptionsDocument,
    options
  )
}
export function useGetUserSubscriptionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetUserSubscriptionsQuery, GetUserSubscriptionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetUserSubscriptionsQuery, GetUserSubscriptionsQueryVariables>(
    GetUserSubscriptionsDocument,
    options
  )
}
export type GetUserSubscriptionsQueryHookResult = ReturnType<typeof useGetUserSubscriptionsQuery>
export type GetUserSubscriptionsLazyQueryHookResult = ReturnType<typeof useGetUserSubscriptionsLazyQuery>
export type GetUserSubscriptionsQueryResult = Apollo.QueryResult<
  GetUserSubscriptionsQuery,
  GetUserSubscriptionsQueryVariables
>
export const CreateTeamMemberDocument = gql`
  mutation createTeamMember($email: String!, $roles: [TeamMemberRolesInput]) {
    createTeamMember(email: $email, roles: $roles) {
      user_id
      email
      roles
      user_metadata {
        tpcs_user
      }
      app_metadata {
        tpcs_user
      }
    }
  }
`
export type CreateTeamMemberMutationFn = Apollo.MutationFunction<
  CreateTeamMemberMutation,
  CreateTeamMemberMutationVariables
>

/**
 * __useCreateTeamMemberMutation__
 *
 * To run a mutation, you first call `useCreateTeamMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTeamMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTeamMemberMutation, { data, loading, error }] = useCreateTeamMemberMutation({
 *   variables: {
 *      email: // value for 'email'
 *      roles: // value for 'roles'
 *   },
 * });
 */
export function useCreateTeamMemberMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateTeamMemberMutation, CreateTeamMemberMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateTeamMemberMutation, CreateTeamMemberMutationVariables>(
    CreateTeamMemberDocument,
    options
  )
}
export type CreateTeamMemberMutationHookResult = ReturnType<typeof useCreateTeamMemberMutation>
export type CreateTeamMemberMutationResult = Apollo.MutationResult<CreateTeamMemberMutation>
export type CreateTeamMemberMutationOptions = Apollo.BaseMutationOptions<
  CreateTeamMemberMutation,
  CreateTeamMemberMutationVariables
>
export const GetTMcreatePermissionsDocument = gql`
  query getTMcreatePermissions {
    getTMcreatePermissions {
      ...CreateTeamPermission
    }
  }
  ${CreateTeamPermissionFragmentDoc}
`

/**
 * __useGetTMcreatePermissionsQuery__
 *
 * To run a query within a React component, call `useGetTMcreatePermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTMcreatePermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTMcreatePermissionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTMcreatePermissionsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetTMcreatePermissionsQuery, GetTMcreatePermissionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetTMcreatePermissionsQuery, GetTMcreatePermissionsQueryVariables>(
    GetTMcreatePermissionsDocument,
    options
  )
}
export function useGetTMcreatePermissionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetTMcreatePermissionsQuery, GetTMcreatePermissionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetTMcreatePermissionsQuery, GetTMcreatePermissionsQueryVariables>(
    GetTMcreatePermissionsDocument,
    options
  )
}
export type GetTMcreatePermissionsQueryHookResult = ReturnType<typeof useGetTMcreatePermissionsQuery>
export type GetTMcreatePermissionsLazyQueryHookResult = ReturnType<typeof useGetTMcreatePermissionsLazyQuery>
export type GetTMcreatePermissionsQueryResult = Apollo.QueryResult<
  GetTMcreatePermissionsQuery,
  GetTMcreatePermissionsQueryVariables
>
export const GetTeamMembersDocument = gql`
  query getTeamMembers($name: String = "", $limit: Int = 10, $offset: Int = 0) {
    getTeamMembers(name: $name, limit: $limit, offset: $offset) {
      data {
        ...TeamMember
      }
      pagination {
        ...UserPagination
      }
    }
  }
  ${TeamMemberFragmentDoc}
  ${UserPaginationFragmentDoc}
`

/**
 * __useGetTeamMembersQuery__
 *
 * To run a query within a React component, call `useGetTeamMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeamMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeamMembersQuery({
 *   variables: {
 *      name: // value for 'name'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetTeamMembersQuery(
  baseOptions?: Apollo.QueryHookOptions<GetTeamMembersQuery, GetTeamMembersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetTeamMembersQuery, GetTeamMembersQueryVariables>(GetTeamMembersDocument, options)
}
export function useGetTeamMembersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetTeamMembersQuery, GetTeamMembersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetTeamMembersQuery, GetTeamMembersQueryVariables>(GetTeamMembersDocument, options)
}
export type GetTeamMembersQueryHookResult = ReturnType<typeof useGetTeamMembersQuery>
export type GetTeamMembersLazyQueryHookResult = ReturnType<typeof useGetTeamMembersLazyQuery>
export type GetTeamMembersQueryResult = Apollo.QueryResult<GetTeamMembersQuery, GetTeamMembersQueryVariables>
export const LinkTeamMemberDocument = gql`
  mutation linkTeamMember($email: String!) {
    linkTeamMember(email: $email) {
      status
      message
      user {
        roles
        email
        user_id
      }
    }
  }
`
export type LinkTeamMemberMutationFn = Apollo.MutationFunction<LinkTeamMemberMutation, LinkTeamMemberMutationVariables>

/**
 * __useLinkTeamMemberMutation__
 *
 * To run a mutation, you first call `useLinkTeamMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLinkTeamMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [linkTeamMemberMutation, { data, loading, error }] = useLinkTeamMemberMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useLinkTeamMemberMutation(
  baseOptions?: Apollo.MutationHookOptions<LinkTeamMemberMutation, LinkTeamMemberMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<LinkTeamMemberMutation, LinkTeamMemberMutationVariables>(LinkTeamMemberDocument, options)
}
export type LinkTeamMemberMutationHookResult = ReturnType<typeof useLinkTeamMemberMutation>
export type LinkTeamMemberMutationResult = Apollo.MutationResult<LinkTeamMemberMutation>
export type LinkTeamMemberMutationOptions = Apollo.BaseMutationOptions<
  LinkTeamMemberMutation,
  LinkTeamMemberMutationVariables
>
export const GetAllRolesDocument = gql`
  query getAllRoles {
    getAllRoles {
      id
      name
      description
    }
  }
`

/**
 * __useGetAllRolesQuery__
 *
 * To run a query within a React component, call `useGetAllRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllRolesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllRolesQuery(
  baseOptions?: Apollo.QueryHookOptions<GetAllRolesQuery, GetAllRolesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetAllRolesQuery, GetAllRolesQueryVariables>(GetAllRolesDocument, options)
}
export function useGetAllRolesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAllRolesQuery, GetAllRolesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetAllRolesQuery, GetAllRolesQueryVariables>(GetAllRolesDocument, options)
}
export type GetAllRolesQueryHookResult = ReturnType<typeof useGetAllRolesQuery>
export type GetAllRolesLazyQueryHookResult = ReturnType<typeof useGetAllRolesLazyQuery>
export type GetAllRolesQueryResult = Apollo.QueryResult<GetAllRolesQuery, GetAllRolesQueryVariables>
export const UpdateTeamMemberDocument = gql`
  mutation updateTeamMember(
    $id: String!
    $first_name: String
    $last_name: String
    $blocked: Boolean
    $roles: [String]
    $clients: [ClientAccount]
  ) {
    updateTeamMember(
      user_id: $id
      given_name: $first_name
      family_name: $last_name
      blocked: $blocked
      roles: $roles
      clients: $clients
    ) {
      user_id
      family_name
      given_name
      email
      blocked
      roles {
        name
        description
        id
      }
      app_metadata {
        tpcs_user
      }
    }
  }
`
export type UpdateTeamMemberMutationFn = Apollo.MutationFunction<
  UpdateTeamMemberMutation,
  UpdateTeamMemberMutationVariables
>

/**
 * __useUpdateTeamMemberMutation__
 *
 * To run a mutation, you first call `useUpdateTeamMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTeamMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTeamMemberMutation, { data, loading, error }] = useUpdateTeamMemberMutation({
 *   variables: {
 *      id: // value for 'id'
 *      first_name: // value for 'first_name'
 *      last_name: // value for 'last_name'
 *      blocked: // value for 'blocked'
 *      roles: // value for 'roles'
 *      clients: // value for 'clients'
 *   },
 * });
 */
export function useUpdateTeamMemberMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateTeamMemberMutation, UpdateTeamMemberMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateTeamMemberMutation, UpdateTeamMemberMutationVariables>(
    UpdateTeamMemberDocument,
    options
  )
}
export type UpdateTeamMemberMutationHookResult = ReturnType<typeof useUpdateTeamMemberMutation>
export type UpdateTeamMemberMutationResult = Apollo.MutationResult<UpdateTeamMemberMutation>
export type UpdateTeamMemberMutationOptions = Apollo.BaseMutationOptions<
  UpdateTeamMemberMutation,
  UpdateTeamMemberMutationVariables
>
export const GetCustomersDocument = gql`
  query getCustomers {
    getCustomers {
      ...Customer
    }
  }
  ${CustomerFragmentDoc}
`

/**
 * __useGetCustomersQuery__
 *
 * To run a query within a React component, call `useGetCustomersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCustomersQuery(
  baseOptions?: Apollo.QueryHookOptions<GetCustomersQuery, GetCustomersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetCustomersQuery, GetCustomersQueryVariables>(GetCustomersDocument, options)
}
export function useGetCustomersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCustomersQuery, GetCustomersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetCustomersQuery, GetCustomersQueryVariables>(GetCustomersDocument, options)
}
export type GetCustomersQueryHookResult = ReturnType<typeof useGetCustomersQuery>
export type GetCustomersLazyQueryHookResult = ReturnType<typeof useGetCustomersLazyQuery>
export type GetCustomersQueryResult = Apollo.QueryResult<GetCustomersQuery, GetCustomersQueryVariables>
