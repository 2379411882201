import { useAuth0 } from '@auth0/auth0-react'
import { Avatar, theme } from '@truepill/react-capsule'
import { RefObject, useContext, useRef, useState } from 'react'
import styled, { css, keyframes } from 'styled-components/macro'

import { DropDownContainer, DropDownOption, SimpleModal } from '../../components'
import { useAuth } from '../../hooks'
import { ModalContext } from '../../providers/ModalProvider'

export const UserAvatar = () => {
  const [showMenu, setShowMenu] = useState(false)
  const { handleLogout } = useAuth()
  const { user } = useAuth0()

  const { showModal } = useContext(ModalContext)

  const ref = useRef<HTMLDivElement>(null)

  if (!user) {
    return null
  }

  return (
    <StyledRollInContainer data-testid="user-icon-container">
      <AnimatedAvatar
        alt={user.name || ''}
        image={user.picture || ''}
        size="xs"
        data-testid="user-badge"
        onClick={() => setShowMenu(!showMenu)}
      />
      {showMenu && (
        <DropDownContainer
          data-testid="user-dropdown"
          minWidth={170}
          onClickOutside={() => setShowMenu(false)}
          preventScrolling={false}
          anchorToRight={true}
        >
          <MenuItems data-testid="user-menu">
            <DropDownOption
              onClick={() => {
                setShowMenu(false)
                showModal(() => (
                  <SimpleModal
                    title={'End session'}
                    confirmationCallback={handleLogout}
                    confirmButtonLabel={'End session'}
                  >
                    <p>By ending this session you will lose all unsaved changes.</p>
                  </SimpleModal>
                ))
              }}
            >
              End session
            </DropDownOption>
          </MenuItems>
        </DropDownContainer>
      )}
      <DropAnchor ref={ref} />
    </StyledRollInContainer>
  )
}

const DropAnchor = styled.div`
  position: absolute;
  right: ${theme.space.lg.value};
  top: ${theme.space['2xl'].value};
`

const MenuItems = styled.ul`
  min-width: 10rem;
  padding-top: ${theme.space['2xs'].value};
  padding-bottom: ${theme.space['2xs'].value};
  border-radius: ${theme.radii['2xs'].value};
  > li {
    font-weight: 500;
  }
`

const rollIn = keyframes`
  0% {
    left: ${theme.space['3xl'].value};
    transform: rotate(180deg);
  }
  75% {
    left: -${theme.space['2xs'].value};
    transform: rotate(-5deg);
  }
  100% {
    left: ${theme.space.none.value};
    transform: rotate(-0deg);
  }
`

const StyledRollInContainer = styled.div<{
  ref?: RefObject<HTMLDivElement>
}>`
  position: relative;
  width: 2.625rem;
  height: 2.5rem;
  display: flex;
  justify-items: center;
  align-items: center;
`

const RollInAnimation = css`
  animation: ${rollIn} 500ms;
`

const AnimatedAvatar = styled(Avatar)`
  position: absolute;
  animation-direction: forwards;
  cursor: pointer;
  ${RollInAnimation}
`
