import { theme } from '@truepill/react-capsule'
import { ReactComponent as UnpinnedNoteIcon } from 'assets/icons/pinInactive.svg'
import { ExternalNoteFragment, usePinNoteMutation } from 'generated/graphql'
import { FC, useCallback } from 'react'
import { logFailureTo } from 'utils/exceptionHandler'

import { CustomerNote, NoteDisplayProps } from './CustomerNote'

type UnpinnedCustomerNoteProps = {
  noteItem: ExternalNoteFragment
}

const unpinnedNoteDisplay: NoteDisplayProps = {
  noteWrapperStyle: {
    backgroundColor: theme.colors.white.value,
    hoverBackgroundColor: theme.colors['gray-300'].value,
  },
  pinIcon: UnpinnedNoteIcon,
  tooltipLabel: 'Pin to the top',
}

export const UnpinnedCustomerNote: FC<UnpinnedCustomerNoteProps> = ({ noteItem }) => {
  const { source, sourceId } = noteItem

  const [pinNoteMutation, { loading }] = usePinNoteMutation({ refetchQueries: ['notes'], awaitRefetchQueries: true })

  const pinNote = useCallback(() => {
    pinNoteMutation({
      variables: {
        pinnedNoteId: { source, sourceId },
      },
    }).catch(logFailureTo('pin note'))
  }, [pinNoteMutation, source, sourceId])

  const pinAction = {
    callback: pinNote,
    loading,
  }

  return <CustomerNote noteItem={noteItem} noteDisplay={unpinnedNoteDisplay} pinAction={pinAction} />
}
