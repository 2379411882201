import { Button, LoadingSpinner, Spacer, Text, theme, Tooltip } from '@truepill/react-capsule'
import { Chip, HyperLink } from 'components'
import { FC } from 'react'
import styled from 'styled-components'
import { formatNoteTimestamp } from 'utils'

import { ExternalNoteFragment, PinnedNoteFragment } from '../../generated/graphql'

export type NoteWrapperProps = {
  backgroundColor: string
  hoverBackgroundColor: string
}

export type NoteDisplayProps = {
  noteWrapperStyle: NoteWrapperProps
  pinIcon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
  tooltipLabel: string
}

export type PinActionProps = {
  callback: () => void
  loading: boolean
}

export type CustomerNoteProps = {
  noteItem: PinnedNoteFragment | ExternalNoteFragment
  noteDisplay: NoteDisplayProps
  pinAction: PinActionProps
}

const NoteWrapper = styled.a.attrs((props: NoteWrapperProps) => props)`
  border-radius: ${theme.radii.md.value};
  display: block;
  padding: ${theme.space.xs.value};
  margin-bottom: ${theme.space.xs.value};
  margin-top: ${theme.space.xs.value};
  background: ${({ backgroundColor }) => backgroundColor};

  &:hover {
    background: ${({ hoverBackgroundColor }) => hoverBackgroundColor};
  }
`
const FirstLineWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`
const TagsWrapper = styled.div`
  display: flex;
`
const AuthorName = styled(Text)`
  font-weight: 700;
`

const PinPanel = styled.div`
  display: flex;
  margin-left: auto;
`

export const CustomerNote: FC<CustomerNoteProps> = ({ noteItem, noteDisplay, pinAction }) => {
  const { note, authorName, source, tags, link, createdAt } = noteItem

  const { noteWrapperStyle, pinIcon: PinIcon, tooltipLabel } = noteDisplay

  const { callback: pinCallback, loading: pinLoading } = pinAction

  return (
    <NoteWrapper
      backgroundColor={noteWrapperStyle.backgroundColor}
      hoverBackgroundColor={noteWrapperStyle.hoverBackgroundColor}
    >
      <FirstLineWrapper>
        {authorName && <AuthorName as="span">{authorName}</AuthorName>}
        {link && (
          <>
            <Spacer size="4xs" />
            <HyperLink href={link} externalIcon />
          </>
        )}
        {createdAt && (
          <>
            <Spacer size="xs" />
            <Text as={'span'} css={{ color: theme.colors['typography-medium'].value }}>
              {formatNoteTimestamp(createdAt)}
            </Text>
          </>
        )}
        <PinPanel>
          {pinLoading && (
            <span style={{ display: 'flex', height: '20px', width: '20px' }}>
              <LoadingSpinner
                size="auto"
                css={{
                  '[data-spinner-background]': {
                    borderWidth: 2,
                  },
                  '[data-spinner-animated-bar]': {
                    borderWidth: 2,
                  },
                }}
              />
            </span>
          )}
          <Button size="2xs" variant="primary-text" onClick={pinCallback}>
            <Tooltip label={tooltipLabel} variant="brand-dark" position="left">
              <PinIcon />
            </Tooltip>
          </Button>
        </PinPanel>
      </FirstLineWrapper>
      <Spacer size="xs" />
      <TagsWrapper>
        {source && (
          <>
            <Chip state="neutral" label={source} />
            <Spacer size="xs" />
          </>
        )}
        {tags?.map((tag, index) =>
          tag ? (
            <>
              <Chip key={`chip-${index}`} label={tag} state={tag.toLowerCase() === 'critical' ? 'error' : 'primary'} />
              <Spacer size="xs" />
            </>
          ) : null
        )}
      </TagsWrapper>
      <Spacer size="xs" />
      <Text>{note}</Text>
    </NoteWrapper>
  )
}
