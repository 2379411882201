import noop from 'lodash/noop'
import { createContext, ReactNode, useContext, useState } from 'react'

type ModalRenderFun = () => JSX.Element

export interface ModalProviderProps {
  showModal: (renderFun: ModalRenderFun) => void
  modals: ModalRenderFun[]
  dismissModal: VoidFunction
}

export const ModalContext = createContext<ModalProviderProps>({
  modals: [],
  showModal: noop,
  dismissModal: noop,
})

export const ModalProvider = (props: { children: ReactNode }) => {
  const [modals, setModals] = useState<ModalRenderFun[]>([])

  const value = {
    showModal: (newModal: ModalRenderFun) => {
      setModals([newModal, ...modals])
    },
    dismissModal: () => {
      setModals(modals.slice(1, modals.length - 1))
    },
    modals,
  }

  return <ModalContext.Provider value={value} {...props} />
}

export const useModalContext = (): ModalProviderProps => {
  const modalContext = useContext(ModalContext)

  if (modalContext === undefined) {
    throw new Error('Attempting to read ModalContext outside a Provider hierarchy')
  }

  return modalContext
}
