import { Auth0Provider, Auth0ProviderOptions } from '@auth0/auth0-react'
import { FC } from 'react'

import { AUTH0_API } from '../../config'

export const AuthProvider: FC = ({ children }) => {
  const providerConfig: Auth0ProviderOptions = {
    domain: AUTH0_API.domain,
    clientId: AUTH0_API.clientId,
    audience: AUTH0_API.audience,
    redirectUri: window.location.origin + AUTH0_API.redirectUrl,
  }

  return <Auth0Provider {...providerConfig}>{children}</Auth0Provider>
}
