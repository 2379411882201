import { theme } from '@truepill/react-capsule'
import { FC } from 'react'
import { NavLink } from 'react-router-dom'
import styled, { css } from 'styled-components'

interface NavigationLinkProps {
  path: string
  external?: boolean
  title: string
}

export const NavigationLink: FC<NavigationLinkProps> = ({ external, path, title }) => {
  const linkContent = <span>{title}</span>

  const link = external ? (
    <StyledAnchor href={path}>{linkContent}</StyledAnchor>
  ) : (
    <StyledNavLink to={path}>{linkContent}</StyledNavLink>
  )

  return <li style={{ display: 'flex' }}>{link}</li>
}

const linkStyles = css`
  text-decoration: none;
  align-items: center;
  display: flex;
  flex-direction: row;
  border-bottom: 0.25rem solid ${theme.colors.white.value};

  span {
    font-weight: 700;
    color: ${theme.colors['gray-900'].value};
  }
`

const StyledNavLink = styled(NavLink)`
  ${linkStyles}

  &.active {
    border-bottom: 0.25rem solid ${theme.colors['primary-700'].value};

    span {
      color: ${theme.colors['primary-700'].value};
    }
  }
`

const StyledAnchor = styled.a`
  ${linkStyles}
`
