import { Grid, GridItem, LoadingSpinner, theme } from '@truepill/react-capsule'
import { useCustomerContext } from 'providers/CustomerProvider'
import { createContext, FC, ReactNode, useContext, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

import { AccountFragment, useGetAccountsQuery } from '../generated/graphql'

type AccountProviderValue = AccountFragment & { manageAccountId: string }

const defaultContext: AccountProviderValue = {
  firstName: '',
  id: '',
  lastName: '',
  manageAccountId: '',
}

export const AccountContext = createContext<AccountProviderValue>(defaultContext)

type Props = {
  children: ReactNode
}
export const AccountProvider: FC<Props> = ({ children }) => {
  const { userId } = useParams<{ userId: string }>()
  const { customerUiConfig } = useCustomerContext()

  const [manageAccountId, setManageAccountId] = useState('')

  const { data, loading } = useGetAccountsQuery({
    variables: {
      patientToken: userId,
    },
  })

  const userData = useMemo(() => data?.getAccounts[0] || defaultContext, [data?.getAccounts])

  useEffect(() => {
    const userId = customerUiConfig?.shouldManageId ? userData.id : userData.patientToken

    setManageAccountId(userId as string)
  }, [customerUiConfig?.shouldManageId, setManageAccountId, userData.id, userData.patientToken])

  if (loading) {
    return (
      <Grid alignItems="center" justifyItems="center" css={{ height: `calc(100vh - ${theme.space['5xl'].value})` }}>
        <GridItem desktop={12} tablet={8}>
          <LoadingSpinner size="md" />
        </GridItem>
      </Grid>
    )
  }

  return <AccountContext.Provider value={{ manageAccountId, ...userData }}>{children}</AccountContext.Provider>
}

export const useAccountContext = () => {
  return useContext(AccountContext)
}
