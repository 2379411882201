import { Grid, GridItem, Header, Modal, Spacer } from '@truepill/react-capsule'
import { ModalContext } from 'providers/ModalProvider'
import { FC, ReactNode, useContext } from 'react'

import { TpButton } from './Buttons'

interface ModalProps {
  confirmationCallback?: () => Promise<unknown> | void
  cancelCallback?: () => void
  extraActionCallback?: () => void
  title?: string | ReactNode
  confirmButtonLabel?: string | ReactNode
  cancelButtonLabel?: string
  extraActionButtonLabel?: string
  constrainWidth?: boolean
  hideCancel?: boolean
  hideConfirm?: boolean
  showExtraAction?: boolean
  disabled?: boolean
  loading?: boolean
  hideFooter?: boolean
}

export const SimpleModal: FC<ModalProps> = ({
  title,
  confirmButtonLabel = 'OK',
  cancelButtonLabel = 'Cancel',
  extraActionButtonLabel = '',
  confirmationCallback,
  cancelCallback,
  extraActionCallback,
  showExtraAction,
  hideCancel,
  hideConfirm,
  children,
  disabled,
  loading,
  hideFooter,
}) => {
  const { dismissModal } = useContext(ModalContext)

  return (
    <Modal isOpen={true} onDismiss={dismissModal} aria-label="modal-dialog">
      <Grid spacing="md">
        <GridItem desktop={12} tablet={8} mobile={4}>
          <Header variant="4xl">{title}</Header>
        </GridItem>
        <GridItem desktop={12} tablet={8} mobile={4}>
          {children}
        </GridItem>
        {!hideFooter && (
          <GridItem desktop={12} tablet={8} mobile={4}>
            <Grid spacing="none" css={{ display: 'flex', justifyContent: 'flex-end' }}>
              {showExtraAction && (
                <TpButton
                  css={{
                    marginRight: 'auto',
                  }}
                  variant={'primary-outline'}
                  onClick={() => {
                    extraActionCallback && extraActionCallback()
                  }}
                >
                  {extraActionButtonLabel}
                </TpButton>
              )}
              {!hideCancel && (
                <TpButton
                  variant={'primary-text'}
                  onClick={() => {
                    dismissModal()
                    cancelCallback && cancelCallback()
                  }}
                >
                  {cancelButtonLabel}
                </TpButton>
              )}
              <Spacer />
              {!hideConfirm && (
                <TpButton
                  loading={loading}
                  disabled={disabled || loading}
                  onClick={() => {
                    confirmationCallback && confirmationCallback()
                  }}
                >
                  {confirmButtonLabel}
                </TpButton>
              )}
            </Grid>
          </GridItem>
        )}
      </Grid>
    </Modal>
  )
}
