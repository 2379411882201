/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Spacer, theme } from '@truepill/react-capsule'
import { ReactComponent as PageArrowIcon } from 'assets/icons/pageArrow.svg'
import { TpPagination } from 'components'
import { useCallback, useEffect, useMemo, useRef } from 'react'
import {
  Cell,
  Column,
  HeaderGroup,
  Row,
  useFlexLayout,
  usePagination,
  useResizeColumns,
  useSortBy,
  useTable,
} from 'react-table'
import styled from 'styled-components'
import { IPagination } from 'types'

import { GridOverlays } from './partials/GridOverlays'

const FiChevronDown = () => <PageArrowIcon style={{ transform: 'rotate(90deg)' }} />
const FiChevronUp = () => <PageArrowIcon style={{ transform: 'rotate(-90deg)' }} />

export type SortOrder = 'asc' | 'desc'
export interface ISortParams {
  column: string
  order: SortOrder
}

const isRequiredForPagination = () => {
  throw new Error('Param is required if you want use pagination!')
}

interface ITableProps<T extends Record<string, unknown>> {
  data: T[]
  columns: Column<T>[]
  hiddenColumns?: string[]
  onSortByChange?: (sortParams: ISortParams) => void
  sortParams?: ISortParams
  loading?: boolean
  withPagination?: boolean
  pagination?: IPagination
  onPageChange?: (page: number) => void
  onPerPageChange?: (value: number) => void
  disablePerPageSelect?: boolean
  defaultCanSortProps?: boolean
  noRowsMessage?: React.ReactText
  onRowClick?: (row: Row<Partial<T>>['original']) => void
  onCellClick?: (cell: Cell<Partial<T>>, row?: Row<Partial<T>>['original']) => void
  title?: string
  noBorderBottom?: boolean
  isHeaderSort?: boolean
  isResize?: boolean
  noResultText?: string
  isFetchingCompleted?: boolean
  perPageSelectOptions?: string[]
}

export const TpDataTable = <T extends Record<string, any>>(props: ITableProps<T>) => {
  const {
    data = [],
    columns = [],
    hiddenColumns,
    onSortByChange,
    sortParams,
    withPagination = false,
    pagination: serverPagination,
    onPageChange = props.pagination && isRequiredForPagination(),
    onPerPageChange = props.pagination && isRequiredForPagination(),
    disablePerPageSelect = false,
    isResize = false,
    isHeaderSort = false,
    loading,
    noResultText,
    title,
    defaultCanSortProps = false,
    noBorderBottom = false,
    onRowClick,
    onCellClick,
    isFetchingCompleted = true,
    perPageSelectOptions,
  } = props

  const defaultColumn = useMemo(
    () => ({
      maxWidth: 850,
      width: 90,
      minWidth: 40,
    }),
    []
  )
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setHiddenColumns,
    setSortBy,
    pageCount,
    gotoPage,
    setPageSize,
    state: { pageIndex, pageSize },
    page,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      manualPagination: serverPagination && Object.keys(serverPagination).length !== 0,
      autoResetPage: false,
      autoResetHiddenColumns: false,
      initialState: {
        pageIndex: 0,
        pageSize: 10,
      },
      manualSortBy: true,
      defaultCanSort: defaultCanSortProps,
      disableSortRemove: true,
      autoResetSortBy: false,
      disableSortBy: true,
    },
    useSortBy,
    usePagination,
    useFlexLayout,
    useResizeColumns
  )

  const hasData = useMemo(() => rows.length > 0, [rows])
  const tableRef = useRef<HTMLDivElement>(null)

  const to = useMemo(() => {
    const val = (pageIndex + 1) * pageSize

    if (val > data?.length) return data?.length

    return val
  }, [pageIndex, pageSize, data])

  const clientPagination = useMemo(() => {
    return {
      currentPage: pageIndex + 1,
      from: Math.max(0, (pageIndex + 1) * pageSize - pageSize),
      lastPage: pageCount,
      perPage: pageSize,
      to,
      total: data?.length,
    }
  }, [pageIndex, pageSize, pageCount, data, to])

  useEffect(() => {
    const { total, perPage } = clientPagination
    if (pageIndex > 0 && total <= perPage * pageIndex) {
      gotoPage(pageIndex - 1)
    }
  }, [gotoPage, clientPagination, pageIndex])

  const paginationActions = useMemo(() => {
    if (serverPagination && onPageChange && onPerPageChange) {
      return {
        onPageChange,
        onPerPageChange,
      }
    }

    return {
      onPageChange: (val: number) => gotoPage(val - 1),
      onPerPageChange: setPageSize,
    }
  }, [gotoPage, onPerPageChange, serverPagination, setPageSize, onPageChange])

  useEffect(() => {
    setHiddenColumns(hiddenColumns ?? [])
  }, [hiddenColumns, setHiddenColumns])

  useEffect(() => {
    if (sortParams && hasData) {
      setSortBy([{ id: sortParams.column, desc: sortParams.order === 'desc' }])
    }
  }, [sortParams, setSortBy, hasData])

  const tableRows = useMemo(() => (serverPagination ? rows : page), [serverPagination, rows, page])

  const tablePagination = useMemo(() => serverPagination || clientPagination, [serverPagination, clientPagination])

  const onThSortHandleClick = useCallback(
    (column: HeaderGroup<T>) => {
      if (column.canSort) {
        if (onSortByChange) {
          const order = sortParams?.column === column.id && sortParams?.order === 'asc' ? 'desc' : 'asc'
          onSortByChange({ column: column.id, order })
        } else {
          column.toggleSortBy(!column.isSortedDesc)
        }
      }
    },
    [sortParams, onSortByChange]
  )

  const renderSort = ({ isSorted, isSortedDesc }: HeaderGroup<T>) => {
    if (isSorted) {
      if (isSortedDesc) {
        return <FiChevronDown />
      }

      return <FiChevronUp />
    }
    return ''
  }

  return (
    <TableWrapper>
      {
        <>
          {title && <Title>{title}</Title>}
          <StyledTableContainer ref={tableRef}>
            <StyledTable {...getTableProps()} noBorderBottom={noBorderBottom} loading={loading ? 1 : 0}>
              <thead>
                {headerGroups.map(headerGroup => {
                  const { key: headerGroupKey, ...restHeaderGroupProps } = headerGroup.getHeaderGroupProps()
                  return (
                    <tr key={headerGroupKey} {...restHeaderGroupProps}>
                      {headerGroup.headers.map(column => {
                        const { key: headerKey, ...restHeaderProps } = column.getHeaderProps(
                          column.getSortByToggleProps({
                            title: column.canSort ? `Sort by ${column.Header?.toString() ?? ''}` : '',
                          })
                        )

                        return (
                          <th key={headerKey} {...restHeaderProps} onClick={() => onThSortHandleClick(column)}>
                            <StyledThWrapper>
                              {column.render('Header')}
                              {isHeaderSort && renderSort(column)}
                              {isResize && <div {...column.getResizerProps()} className="resizer"></div>}
                            </StyledThWrapper>
                          </th>
                        )
                      })}
                    </tr>
                  )
                })}
              </thead>
              <tbody {...getTableBodyProps()}>
                {tableRows.map((row: Row<T>) => {
                  prepareRow(row)
                  const { key: rowKey, ...restRowProps } = row.getRowProps()
                  return (
                    <tr
                      key={rowKey}
                      {...restRowProps}
                      className={onRowClick ? 'hover' : ''}
                      onClick={() => onRowClick && onRowClick(row.original)}
                    >
                      {row.cells.map(cell => {
                        const { key: cellKey, ...restCellProps } = cell.getCellProps()
                        return (
                          // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
                          <td
                            key={cellKey}
                            {...restCellProps}
                            title={cell.value as string}
                            // TODO: Figure out correct typing for this method
                            // eslint-disable-next-line
                            onClick={() => onCellClick && onCellClick(cell.value, row.original)}
                          >
                            {cell.render('Cell')}
                          </td>
                        )
                      })}
                    </tr>
                  )
                })}
                <GridOverlays
                  loading={loading}
                  totalRowCount={rows.length}
                  isFechingCompleted={isFetchingCompleted}
                  content={noResultText}
                />
              </tbody>
            </StyledTable>
          </StyledTableContainer>
          {withPagination && (
            <>
              <Spacer size="xs" />
              <TpPagination
                tableRef={tableRef}
                pagination={tablePagination}
                perPageSelectOptions={perPageSelectOptions}
                onPageChange={paginationActions.onPageChange}
                onPerPageChange={paginationActions.onPerPageChange}
                disablePerPageSelect={disablePerPageSelect}
              />
            </>
          )}
        </>
      }
    </TableWrapper>
  )
}
const TableWrapper = styled.div`
  overflow-x: auto;
  font-family: ${theme.fonts.base.value};
`
const basePadding = '0.3125rem'
const Title = styled.h2`
  font-size: ${theme.fontSizes.md.value}
  width: 100%;
  margin-bottom: 0.8rem;
`
const StyledTableContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
`

const StyledTable = styled.table<{ noBorderBottom?: boolean; loading?: number }>`
  position: relative;
  width: 100%;
  height: auto;
  border-spacing: 0;
  border-bottom: ${({ noBorderBottom, loading }) => (noBorderBottom || loading ? '0' : '1px')} solid
    ${theme.colors['gray-500'].value};
  overflow: auto;
  border-collapse: separate;

  #spinner {
    position: relative;
    z-index: 16;
  }

  th {
    font-weight: bold;
  }
  thead {
    font-weight: 700;

    th {
      padding: 0 0 ${theme.space.xs.value} ${theme.space.sm.value};
      font-weight: bold;
      text-align: start;
      border-bottom: 1px solid ${theme.colors['gray-500'].value};
      button {
        padding: 0;
      }
    }
  }
  tbody {
    td {
      padding: ${theme.space.sm.value} 0 ${theme.space.sm.value} ${theme.space.sm.value};
      display: table-cell;
      margin: auto;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      vertical-align: text-bottom;
    }
    td * {
      display: initial;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    tr {
      &:nth-child(even) {
        background: ${theme.colors['gray-300'].value};
      }
      &:last-child {
        background: ${({ loading }) => (loading ? 'transparent' : '')};
      }
    }
    tr.hover {
      &:hover {
        background: ${theme.colors['gray-100'].value};
        cursor: pointer;
      }
    }
  }
`
const StyledThWrapper = styled.div`
  display: block;
  svg {
    margin-left: ${basePadding};
    margin-right: ${basePadding};
  }
  .resizer {
    display: inline-block;
    width: 5px;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    transform: translateX(50%);
    z-index: 1;
    touch-action: none;
  }
`
