import { ReactComponent as PinnedNoteIcon } from 'assets/icons/pinActive.svg'
import { PinnedNoteFragment, useUnpinNoteMutation } from 'generated/graphql'
import { FC, useCallback } from 'react'
import { logFailureTo } from 'utils/exceptionHandler'

import { CustomerNote, NoteDisplayProps } from './CustomerNote'

type PinnedCustomerNoteProps = {
  noteItem: PinnedNoteFragment
}

const pinnedNoteDisplay: NoteDisplayProps = {
  noteWrapperStyle: {
    backgroundColor: '#FFF6E8',
    hoverBackgroundColor: '#FCE0B1',
  },
  pinIcon: PinnedNoteIcon,
  tooltipLabel: 'Unpin',
}

export const PinnedCustomerNote: FC<PinnedCustomerNoteProps> = ({ noteItem }) => {
  const { source, sourceId } = noteItem

  const [unpinNoteMutation, { loading }] = useUnpinNoteMutation({
    refetchQueries: ['notes'],
    awaitRefetchQueries: true,
  })

  const unpinNote = useCallback(() => {
    unpinNoteMutation({
      variables: {
        pinnedNoteId: { source, sourceId },
      },
    }).catch(logFailureTo('unpin note'))
  }, [unpinNoteMutation, source, sourceId])

  const unpinAction = {
    callback: unpinNote,
    loading,
  }

  return <CustomerNote noteItem={noteItem} noteDisplay={pinnedNoteDisplay} pinAction={unpinAction} />
}
