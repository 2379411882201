import { useAuth0 } from '@auth0/auth0-react'
import { range } from 'lodash'
import React, { FC, lazy } from 'react'
import { Navigate, useRoutes } from 'react-router-dom'

import { LoadingScreen } from '../components'
import { Permissions } from '../constants'
import { useGetUserInfoQuery } from '../generated/graphql'
import { AuthGuard, GuestGuard, PermissionBasedGuard } from '../guards'
import useNavigation from '../hooks/useNavigation'
import { LayoutMain, LogoOnlyLayout } from '../layouts'
import Page500 from '../pages/Page500'
import { CustomerProvider } from '../providers/CustomerProvider'
import { useModalContext } from '../providers/ModalProvider'
import { NotesProvider } from '../providers/NotesProvider'
import { UserInfoProvider } from '../providers/UserInfoProvider'
import { Loadable } from './Loadable'

// IMPORT COMPONENTS

// Authentication
const LoginPage = Loadable(lazy(() => import('../pages/authCallback')))
const UnauthorizedPage = Loadable(lazy(() => import('../pages/unauthorized')))
// Main
const TeamListPage = Loadable(lazy(() => import('../pages/team')))
const SearchPage = Loadable(lazy(() => import('../pages/tpcs/Search')))
const AccountPage = Loadable(lazy(() => import('../pages/tpcs/Account')))
const EditAccountPage = Loadable(lazy(() => import('../pages/tpcs/EditAccount')))
const OrdersPage = Loadable(lazy(() => import('../pages/tpcs/Order')))
const Page404 = Loadable(lazy(() => import('../pages/Page404')))

const AccountDetailsPage = Loadable(lazy(() => import('../pages/tpcs/Account/AccountDetails')))
const AccountHealthProfilePage = Loadable(lazy(() => import('../pages/tpcs/Account/HealthProfile')))
const AccountOrderListPage = Loadable(lazy(() => import('../pages/tpcs/Account/OrdersList')))
const AccountInsurancesListPage = Loadable(lazy(() => import('../pages/tpcs/Account/Insurances')))
const AccountSubscriptionPage = Loadable(lazy(() => import('../pages/tpcs/Account/Subscriptions')))
const AccountAddressListPage = Loadable(lazy(() => import('../pages/tpcs/Account/AddressList')))
const AccountCopaysPage = Loadable(lazy(() => import('../pages/tpcs/Account/Copays')))

const RouterControl: FC = () => {
  // Each time the path changes, dismiss all modals.
  const { dismissModal, modals } = useModalContext()

  useNavigation(() => {
    range(0, modals.length).forEach(() => dismissModal())
  })

  return useRoutes([
    {
      path: 'unauthorized',
      element: (
        <GuestGuard>
          <UnauthorizedPage />
        </GuestGuard>
      ),
    },
    {
      path: 'callback',
      element: (
        <GuestGuard>
          <LoginPage />
        </GuestGuard>
      ),
    },
    {
      path: 'tpcs',
      element: (
        <AuthGuard>
          <CustomerProvider>
            <NotesProvider>
              <LayoutMain />
            </NotesProvider>
          </CustomerProvider>
        </AuthGuard>
      ),
      children: [
        {
          index: true,
          element: (
            <PermissionBasedGuard requiredPermissions={Permissions.tpcsUser} hasContent>
              <SearchPage />
            </PermissionBasedGuard>
          ),
        },
        {
          path: 'users',
          children: [
            {
              path: ':userId',
              element: <AccountPage />,
              children: [
                { index: true, element: <AccountDetailsPage /> },
                { path: 'health-profiles', element: <AccountHealthProfilePage /> },
                { path: 'orders', element: <AccountOrderListPage /> },
                { path: 'subscriptions', element: <AccountSubscriptionPage /> },
                { path: 'insurances', element: <AccountInsurancesListPage /> },
                { path: 'addresses', element: <AccountAddressListPage /> },
                { path: 'copays', element: <AccountCopaysPage /> },
              ],
            },
            { path: ':userId/edit', element: <EditAccountPage /> },
          ],
        },
        { path: 'orders/:orderId', element: <OrdersPage /> },
      ],
    },
    {
      path: 'team',
      element: (
        <AuthGuard>
          <LayoutMain />
        </AuthGuard>
      ),
      children: [
        {
          index: true,
          element: (
            <PermissionBasedGuard requiredPermissions={Permissions.listUsers} hasContent>
              <TeamListPage />
            </PermissionBasedGuard>
          ),
        },
      ],
    },
    { path: '/', element: <Navigate to={'/tpcs'} replace /> },
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to={'/404'} replace /> },
      ],
    },
  ])
}

export const Router: FC = () => {
  const { isLoading, isAuthenticated } = useAuth0()

  const {
    error,
    data,
    loading: loadingUserInfo,
  } = useGetUserInfoQuery({
    skip: !isAuthenticated,
  })

  if (isLoading || loadingUserInfo) {
    return <LoadingScreen />
  }

  if (error) {
    return <Page500 />
  }

  return (
    <UserInfoProvider userInfo={data?.me || null}>
      <RouterControl />
    </UserInfoProvider>
  )
}
