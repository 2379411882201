import { Location } from 'history'
import React, { FC, ReactNode, useMemo } from 'react'
import { Location as RouterLocation, useLocation, useNavigate } from 'react-router-dom'
import { QueryParamProvider as CustomQueryParamProvider } from 'use-query-params'

interface RouteAdapterProps {
  children: FC<{
    history: {
      replace(location: Location): void
      push(location: Location): void
    }
    location: RouterLocation
  }>
}

const RouteAdapter: FC<RouteAdapterProps> = ({ children }) => {
  const navigate = useNavigate()
  const routerLocation = useLocation()

  const adaptedHistory = useMemo(
    () => ({
      replace(location: Location) {
        navigate(location, { replace: true, state: location.state })
      },
      push(location: Location) {
        navigate(location, { replace: false, state: location.state })
      },
    }),
    [navigate]
  )

  if (!children) {
    return null
  }

  return children({ history: adaptedHistory, location: routerLocation })
}

interface Props {
  children: ReactNode
}
// We need to add `as unknown as FunctionComponent` to make TypeScript happy
// https://github.com/pbeshai/use-query-params/issues/196#issuecomment-996893750
export const QueryParamProvider: FC<Props> = ({ children }) => {
  return (
    <CustomQueryParamProvider ReactRouterRoute={RouteAdapter as unknown as FC}>{children}</CustomQueryParamProvider>
  )
}
