import { useCustomerContext } from 'providers/CustomerProvider'
import { FC, ReactNode } from 'react'

import { UiConfigFragment, UiConfigShowTabsFragment } from '../generated/graphql'

interface Props {
  children: ReactNode
  tabName?: keyof UiConfigShowTabsFragment
  uiRule?: keyof UiConfigFragment
}

export const UiCustomerGuard: FC<Props> = ({ children, tabName, uiRule }) => {
  const { customerUiConfig } = useCustomerContext()

  if (!customerUiConfig) {
    return null
  }

  if (tabName) {
    const shouldRenderTab = customerUiConfig.showTabs[tabName]

    if (!shouldRenderTab) {
      return null
    }
  }

  if (uiRule) {
    const shouldRenderTab = customerUiConfig[uiRule]

    if (!shouldRenderTab) {
      return null
    }
  }

  return <>{children}</>
}
