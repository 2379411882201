import { Text } from '@truepill/react-capsule'
import { ComponentProps, FC } from 'react'
import { Link as ReactLink } from 'react-router-dom'

interface LinkProps extends ComponentProps<typeof ReactLink> {
  title: string
}

export const Link: FC<LinkProps> = ({ title, ...props }) => {
  return (
    <ReactLink {...props}>
      <Text>{title}</Text>
    </ReactLink>
  )
}
