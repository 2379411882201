import { Popover, PopoverContent, PopoverTrigger, Spacer, Text, theme } from '@truepill/react-capsule'
import { CSSProperties, FC, Fragment, ReactNode } from 'react'
import { Icon as ReactFeatherIcon, IconProps as ReactFeatherIconProps, Info as InfoIcon } from 'react-feather'

interface InfoPopover {
  Icon?: ReactFeatherIcon
  iconStyle?: CSSProperties
  iconProps?: ReactFeatherIconProps
  labeledContent?: Record<string, ReactNode>
}

export const InfoPopover: FC<InfoPopover> = ({
  Icon = InfoIcon,
  iconStyle,
  iconProps = { size: theme.sizes.md.value, color: theme.colors['primary-700'].value },
  labeledContent = {},
}: InfoPopover) => {
  const entries = Object.entries(labeledContent)

  const entriesLastIndex = entries.length - 1

  if (entriesLastIndex === -1) {
    return <></>
  }

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Icon style={iconStyle} {...iconProps} />
      </PopoverTrigger>
      <PopoverContent side="right">
        {entries.map(([label, content], index) => (
          <Fragment key={index}>
            {typeof content === 'string' ? (
              <Text bold>{`${label}: ${content}`}</Text>
            ) : (
              <>
                <Text bold as="span">{`${label}: `}</Text>
                {content}
              </>
            )}
            {entriesLastIndex !== index && <Spacer />}
          </Fragment>
        ))}
      </PopoverContent>
    </Popover>
  )
}
