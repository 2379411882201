import { theme } from '@truepill/react-capsule'
import { Fab } from 'components'
import usePane from 'hooks/usePane'

export const NotesButton = ({ count, isLoading }: { count?: number; isLoading?: boolean }) => {
  const { togglePane } = usePane('Note')

  return (
    <Fab
      icon={'Book'}
      badge={count}
      toolTipProps={{ label: 'Notes' }}
      onClick={togglePane}
      isLoading={isLoading}
      css={{ backgroundColor: theme.colors['gray-300'].value }}
    />
  )
}
