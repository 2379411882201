import { Select, Spacer, Text, theme } from '@truepill/react-capsule'
import { perPageSelectOptions as perPageSelectOptionsDefault } from 'constants/PerPageSelectOption'
import { FC, RefObject } from 'react'
import { ChevronLeft as ChevronLeftIcon, ChevronRight as ChevronRightIcon } from 'react-feather'
import ReactPaginate from 'react-paginate'
import styled from 'styled-components'
import { contrastBackgroundColor } from 'styles/styleVariables'
import { IPagination } from 'types'

interface IProps {
  pagination: IPagination
  onPageChange: (page: number) => void
  onPerPageChange: (value: number) => void
  disablePerPageSelect?: boolean
  tableRef?: RefObject<HTMLDivElement>
  perPageSelectOptions?: string[]
}

const DEFAULT_PAGE_COUNT = 1

export const TpPagination: FC<IProps> = ({
  pagination,
  onPageChange,
  onPerPageChange,
  disablePerPageSelect = false,
  perPageSelectOptions = perPageSelectOptionsDefault,
  tableRef,
}) => {
  const pageCount = pagination.lastPage || DEFAULT_PAGE_COUNT

  const _scrollTableIntoView = (page: number): void => {
    onPageChange(page + 1)

    if (page === pagination.lastPage - 1 && tableRef?.current) {
      tableRef?.current.scrollIntoView()
    }
  }

  return (
    <StyledPaginationContainer>
      {pagination.total ? (
        <Text bold variant="body">
          <span>
            {pagination.from + 1} - {pagination.to}
          </span>
          <span className="pagination-total"> of {pagination.total}</span>
        </Text>
      ) : (
        <Spacer size="8xl" />
      )}
      {pageCount > 1 && (
        <>
          <ReactPaginate
            breakLabel="..."
            nextLabel={
              <StyledSpan id="next-btn">
                <ChevronRightIcon size="1.25rem" />
              </StyledSpan>
            }
            forcePage={pagination.currentPage - 1}
            onPageChange={({ selected: page }) => {
              _scrollTableIntoView(page)
            }}
            pageRangeDisplayed={3}
            pageCount={pageCount}
            previousLabel={
              <StyledSpan id="prev-btn">
                <ChevronLeftIcon size="1.25rem" />
              </StyledSpan>
            }
            marginPagesDisplayed={1}
            containerClassName="tp-pagination"
            pageClassName={`tp-page-item ${pageCount === DEFAULT_PAGE_COUNT ? 'not-active' : ''}`}
            pageLinkClassName="tp-page-link"
            previousClassName="tp-page-item prev"
            nextClassName={`tp-page-item next ${pageCount === DEFAULT_PAGE_COUNT ? 'disabled' : ''}`}
            breakClassName="tp-page-item"
          />
          <StyledPerPageWrapper>
            {!disablePerPageSelect && (
              <>
                <Text>Show: </Text>
                <Select
                  value={perPageSelectOptions.find(el => Number(el) === pagination.perPage)}
                  onChange={item => {
                    onPerPageChange(Number(item))
                    if (tableRef?.current) {
                      tableRef?.current.scrollIntoView()
                    }
                  }}
                  options={perPageSelectOptions}
                  className="pagination-select"
                  label=""
                />
                <Text>entries</Text>
              </>
            )}
          </StyledPerPageWrapper>
        </>
      )}
    </StyledPaginationContainer>
  )
}

const StyledPaginationContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  ol,
  ul {
    list-style: none;
  }

  & .tp-pagination {
    display: flex;
    align-items: center;

    [role='button'] {
      cursor: pointer;
    }

    .tp-page-item {
      margin: 0 ${theme.space['2xs'].value};

      &.disabled a {
        color: ${theme.colors['gray-500'].value};
        pointer-events: none;
      }

      &.prev a,
      &.next a {
        display: flex;
        align-items: center;
      }

      &.prev,
      &.next {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: ${contrastBackgroundColor};
        border-radius: ${theme.space.md.value};
        height: ${theme.space.xl.value};
        min-width: ${theme.space.xl.value};
      }

      &.prev:not(.disabled):hover a,
      &.next:not(.disabled):hover a {
        text-decoration: underline;
      }

      &.prev a svg {
        margin-right: ${theme.space['2xs'].value};
      }

      &.next a svg {
        margin-left: ${theme.space['2xs'].value};
      }

      .tp-page-link {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: ${theme.space.xl.value};
        height: ${theme.space.xl.value};
        padding: 0 0.25rem;
        line-height: ${theme.space.xl.value};
        text-align: center;
        border-radius: ${theme.space.md.value};
        background-color: ${contrastBackgroundColor};
      }

      &.selected .tp-page-link,
      &:hover .tp-page-link {
        color: ${theme.colors.white.value};
        background-color: ${theme.colors['primary-700'].value};
      }

      &.not-active .tp-page-link {
        pointer-events: none;
      }
    }
  }

  span.pagination-total {
    font-weight: normal;
    color: ${theme.colors['gray-500'].value};
  }
`

const StyledPerPageWrapper = styled.div`
  display: flex;
  gap: ${theme.space.xs.value};
  align-items: center;

  label {
    display: none;
  }

  button {
    width: ${theme.space['5xl'].value};
    height: ${theme.space['2xl'].value};
    padding: ${theme.space.sm.value};
  }

  ul {
    bottom: ${theme.space['2xl'].value};
  }

  li:hover {
    cursor: pointer;
  }
`

const StyledSpan = styled(Text)`
  display: inherit;
  align-items: inherit;
`
