import { DefaultSearchBy } from '../../generated/graphql'
import { Option } from '../../types'
import { CustomerProviderProps } from './types'

export const searchOptions: Option<DefaultSearchBy>[] = [
  { label: 'Insurance', value: DefaultSearchBy.Insurance },
  { label: 'Email', value: DefaultSearchBy.Email },
  { label: 'Name', value: DefaultSearchBy.Name },
  { label: 'Order', value: DefaultSearchBy.Order },
]

export const DEFAULT_CUSTOMER = {
  customerId: '87',
  customerName: 'Optum',
  customerSlug: 'optum',
}

export const DEFAULT_CONTEXT: CustomerProviderProps = {
  saveCurrentCustomer: DEFAULT_CUSTOMER,
  handleChangeCustomer: () => undefined,
  customersMap: new Map(),
  customerCollection: [],
  customerUiConfig: null,
  optionByCustomer: [searchOptions[0]],
}
