import { theme } from '@truepill/react-capsule'
import { FC } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { primaryBackgroundColor } from 'styles/styleVariables'

type TTabItemVariant = 'primary' | 'secondary' | 'inset'

interface ITabItem {
  title?: string
  path?: string
  active?: boolean
  end?: boolean
  variant?: TTabItemVariant
  onClick?: () => void
}

export const TabItem: FC<ITabItem> = props => {
  const { title, active, path, variant = 'primary', end = false, ...rest } = props
  const { search } = useLocation()

  if (path) {
    return (
      <TabItemStyled variant={variant} {...rest}>
        <NavLink to={`${path}${search}`} end={end}>
          {title || ''}
        </NavLink>
      </TabItemStyled>
    )
  }

  return (
    <TabItemStyled className={active ? 'active' : ''} variant={variant} {...rest}>
      {title}
    </TabItemStyled>
  )
}

const TabItemDefaultStyle = css`
  cursor: pointer;
  transition: all 0.3s;
  text-align: center;
  font-weight: 700;
  font-size: 0.875rem;
  text-decoration: none;

  & > a {
    text-decoration: none;
    display: block;
    color: ${theme.colors['typography-dark'].value};
  }
`
const TabItemPrimary = css`
  color: ${theme.colors['typography-dark'].value};

  &.active {
    border-bottom: ${theme.space['4xs'].value} ${theme.colors['primary-700'].value};
    color: ${theme.colors['primary-700'].value};
  }

  &:hover {
    color: ${theme.colors['primary-700'].value};
  }
  & > a {
    padding: ${theme.space.xs.value};
    border-bottom: ${theme.space['4xs'].value} solid transparent;

    &.active {
      border-color: ${theme.colors['primary-700'].value};
      color: ${theme.colors['primary-700'].value};
    }
  }
`
const TabItemSecondary = css`
  border-radius: ${theme.radii.xl.value};
  padding: ${theme.space.xs.value} ${theme.space.md.value};

  &.active {
    background: ${theme.colors['primary-700'].value};
    color: ${theme.colors.white.value};

    &:hover {
      background: ${theme.colors['primary-700'].value};
    }
  }
  &:hover {
    background: ${theme.colors['primary-300'].value};
  }
`

const TabItemInset = css`
  border-radius: 8px 8px 0px 0px;

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  gap: 6px;
  color: #4e5d70;

  &.active {
    color: #034d83;
    background: ${primaryBackgroundColor};
  }
`

const TabItemStyled = styled.div<{ variant?: TTabItemVariant }>`
  ${TabItemDefaultStyle};
  ${({ variant = 'primary' }) =>
    variant === 'primary' ? TabItemPrimary : variant === 'secondary' ? TabItemSecondary : TabItemInset}
`
