import { Chip as CapsuleChip, theme } from '@truepill/react-capsule'
import { ComponentProps, FC, useMemo } from 'react'

import { ChipState } from '../types'

type Modify<T, R> = Omit<T, keyof R> & R

type ChipProps = Modify<
  ComponentProps<typeof CapsuleChip>,
  {
    state?: ChipState
    label: string
  }
>

export const Chip: FC<ChipProps> = props => {
  const { label, state, css, ...otherProps } = props

  const customization = useMemo(() => {
    let customStyles = {}
    let customState

    if (state === 'disabled') {
      customStyles = {
        color: `${theme.colors['typography-medium'].value}`,
        backgroundColor: `${theme.colors['gray-300'].value}`,
      }
    } else if (state === 'neutral') {
      customStyles = {
        color: `${theme.colors['typography-white'].value}`,
        backgroundColor: `${theme.colors['gray-500'].value}`,
      }
    } else {
      customState = state
    }

    return { customState, customStyles }
  }, [state])

  return (
    <CapsuleChip {...otherProps} state={customization.customState} css={{ ...css, ...customization.customStyles }}>
      {label}
    </CapsuleChip>
  )
}
