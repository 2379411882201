import { Modal } from 'components/Modal'
import { FC } from 'react'
import { Outlet } from 'react-router-dom'
import styled from 'styled-components'

import { Navigation } from './components/Navigation'

export const LayoutMain: FC = () => {
  return (
    <>
      <Modal />
      <Navigation />
      <MainContainer id="MainContainer">
        <Outlet />
      </MainContainer>
    </>
  )
}

const MainContainer = styled.div`
  height: calc(100vh - 4rem);

  overflow-y: auto;
  overflow-x: hidden;
`
