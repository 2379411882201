import { Text } from '@truepill/react-capsule'
import { useCallback } from 'react'
import styled from 'styled-components'

import { logFailureTo } from '../../utils/exceptionHandler'
import { Fab } from '../Buttons'

interface ClipboardRowProps {
  value?: string | null
  tooltip?: string | null
}

export const ClipboardRow = ({ value, tooltip }: ClipboardRowProps) => {
  const clipboardValue = value ?? 'No information'

  const label = tooltip ?? 'No information'

  const handleCreateClipBoard = useCallback(() => {
    navigator.clipboard.writeText(clipboardValue).catch(logFailureTo('Writing to the clipboard'))
  }, [clipboardValue])

  return (
    <StyledText>
      <Text>{clipboardValue}</Text>
      {value && <Fab icon="Copy" toolTipProps={{ label }} onClick={handleCreateClipBoard} round={false} />}
    </StyledText>
  )
}

const StyledText = styled(Text)`
  display: flex;
  flex-wrap: nowrap;
  align-items: baseline;
  justify-content: space-between;
`
