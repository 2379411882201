import { useCallback, useEffect, useMemo } from 'react'
import { useSessionStorage } from 'react-use'
import { StringParam, useQueryParam, useQueryParams, withDefault } from 'use-query-params'
import { getObjFromQueryString } from 'utils/queryStringProcessor'

import { LS_CURRENT_CUSTOMER } from '../../config'
import { CustomerFragment, UiConfigFragment, useGetCustomersQuery } from '../../generated/graphql'
import { DEFAULT_CUSTOMER } from './constants'
import { Customer } from './types'

type HookReturn = {
  saveCurrentCustomer: Customer
  customerUiConfig: UiConfigFragment | null
  handleChangeCustomer: (customer: Customer) => void
  customersMap: Map<string, CustomerFragment>
  customerCollection: CustomerFragment[]
}

export function useCurrentCustomer(): HookReturn {
  const { data } = useGetCustomersQuery()
  const queryParams = getObjFromQueryString(window.location.search)
  let newDefaultCustomer = null
  if (queryParams?.customerId) {
    newDefaultCustomer = {
      customerId: queryParams?.customerId,
      customerName: queryParams?.customerName,
      customerSlug: queryParams?.customerSlug,
    }
  } else {
    newDefaultCustomer = DEFAULT_CUSTOMER
  }

  const [saveCurrentCustomer, setCurrentCustomer] = useSessionStorage<Customer>(
    LS_CURRENT_CUSTOMER,
    newDefaultCustomer as Customer
  )

  const [queryCustomerId, setQueryCustomerId] = useQueryParam(
    'customerId',
    withDefault(StringParam, saveCurrentCustomer?.customerId)
  )
  const [, setQuery] = useQueryParams({
    customerId: StringParam,
    customerName: StringParam,
    customerSlug: StringParam,
  })

  const handleChangeCustomer = useCallback(
    (customer: Customer) => {
      if (data?.getCustomers) {
        const candidate = data.getCustomers.find(c => c.customerId === customer.customerId)

        if (candidate) {
          const validCandidate = 'customerName' in customer && 'customerSlug' in customer && 'uiConfig' in customer
          if (validCandidate) {
            setCurrentCustomer(customer)

            setQueryCustomerId(customer.customerId)
            setQuery({
              customerId: customer.customerId,
              customerName: customer.customerName,
              customerSlug: customer.customerSlug,
            })
          } else {
            alert('Could not find valid customer')
          }
        }
      }
    },
    [data?.getCustomers, setCurrentCustomer, setQuery, setQueryCustomerId]
  )

  useEffect(() => {
    if (saveCurrentCustomer?.customerId !== queryCustomerId) {
      const candidate = data?.getCustomers?.find(c => c.customerId === queryCustomerId)

      if (candidate) {
        setCurrentCustomer(candidate)
      }
    }
  }, [data?.getCustomers, queryCustomerId, saveCurrentCustomer?.customerId, setCurrentCustomer])

  const customersMap = useMemo(() => {
    const map: Map<string, CustomerFragment> = new Map()

    if (data?.getCustomers) {
      data.getCustomers.forEach(customer => {
        map.set(customer.customerId, customer)
      })
    }

    return map
  }, [data?.getCustomers])

  const customerCollection = useMemo(() => {
    if (data?.getCustomers) {
      return data.getCustomers
    }

    return []
  }, [data?.getCustomers])

  const customerUiConfig = useMemo(() => {
    return customersMap.get(saveCurrentCustomer?.customerId)?.uiConfig || null
  }, [customersMap, saveCurrentCustomer?.customerId])

  return {
    saveCurrentCustomer: saveCurrentCustomer || DEFAULT_CUSTOMER,
    customerUiConfig,
    handleChangeCustomer,
    customersMap,
    customerCollection,
  }
}
