import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  createHttpLink,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { useAuth0 } from '@auth0/auth0-react'
import Cookies from 'js-cookie'
import React, { FC, ReactNode } from 'react'
import { getObjFromQueryString } from 'utils/queryStringProcessor'

import packageJson from '../../../package.json'
import { LS_CURRENT_CUSTOMER } from '../../config'
import { CustomerFragment } from '../../generated/graphql'
import { getBackendUrl, getSessionStorageCache } from '../../utils'

const JWT_COOKIE = 'jwtToken' as const

interface Props {
  children: ReactNode
}

export const ApolloProviderWithAuth0: FC<Props> = ({ children }) => {
  const { getAccessTokenSilently, logout } = useAuth0()
  const path =
    getBackendUrl() || `${window.location.protocol}//${window.location.hostname}:${window.location.port}/graphql`

  const httpLink = createHttpLink({
    uri: path,
  })

  const authLink = setContext(async (_, { headers, ...context }) => {
    const token = await getAccessTokenSilently()
    let customerId = null
    const queryParams = getObjFromQueryString(window.location.search)
    if (queryParams?.customerId) {
      customerId = queryParams.customerId
    } else {
      customerId = (getSessionStorageCache(LS_CURRENT_CUSTOMER) as CustomerFragment)?.customerId
    }
    const newContext: { headers: Record<string, string | null> } = {
      headers: {
        ...(headers as Record<string, string>),
        ...(token ? { Authorization: `Bearer ${token}` } : {}),
        'pharmacy-customer-id': customerId || null,
      },
      ...(context as Record<string, string>),
    }
    return newContext
  })

  const errorMiddleware = onError(({ graphQLErrors }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ extensions }) => {
        if (extensions?.code === 'SESSION_EXPIRED' || extensions?.code === 'NOT_AUTHENTICATED') {
          logout()
          // remove any stored cookie and reload the page
          Cookies.remove(JWT_COOKIE)

          location.reload()
        }
      })
    }
  })

  const client: ApolloClient<NormalizedCacheObject> = new ApolloClient({
    cache: new InMemoryCache(),
    link: ApolloLink.from([errorMiddleware, authLink, httpLink]),
    name: packageJson.name,
    version: packageJson.version,
  })

  return <ApolloProvider client={client}>{children}</ApolloProvider>
}
