import { BannerAlert, Grid, GridItem, Header, Text, theme } from '@truepill/react-capsule'
import usePane from 'hooks/usePane'
import { useNotesContext } from 'providers/NotesProvider'
import React, { FC, ReactNode, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'

import { NAV_MENU_BREAKPOINT } from '../styles/styleVariables'

interface PageProps {
  title: string
  meta?: ReactNode
  header?: string | boolean
  children: ReactNode
}

export const Page: FC<PageProps> = props => {
  const { title, meta, header = false, children } = props

  const [visible, setVisible] = useState(false)

  const { data, loading: notesLoading } = useNotesContext()
  const { togglePane } = usePane('Note')
  const { pathname } = useLocation()

  const {
    pinnedNotes: { length: pinnedNotesCount },
  } = data

  useEffect(() => {
    if (pinnedNotesCount > 0) {
      setVisible(true)
    }
  }, [pinnedNotesCount])

  return (
    <>
      <Helmet>
        <title>{`${title} | TPCS`}</title>
        {meta}
      </Helmet>

      <StyledWrap>
        {header && (
          <GridItem mobile={4} tablet={8} desktop={12}>
            <Header variant="4xl" as={'h1' as React.ElementType} bold>
              {title}
            </Header>
          </GridItem>
        )}

        {!notesLoading && pinnedNotesCount > 0 && pathname !== '/tpcs' && (
          <GridItem mobile={4} tablet={8} desktop={12}>
            <BannerAlert state="warning" visible={visible} onDismiss={() => setVisible(false)}>
              <Text>
                {pinnedNotesCount === 1
                  ? `There is ${pinnedNotesCount} pinned note for patients on this account. `
                  : `There are ${pinnedNotesCount} pinned notes for patients on this account. `}
                <StyledLink onClick={() => togglePane()}>View notes.</StyledLink>
              </Text>
            </BannerAlert>
          </GridItem>
        )}

        <GridItem mobile={4} tablet={8} desktop={12}>
          {children}
        </GridItem>
      </StyledWrap>
    </>
  )
}

const StyledWrap = styled(Grid)`
  padding: ${theme.space.md.value} ${theme.space.md.value} ${theme.space['2xl'].value};

  @media screen and (min-width: ${NAV_MENU_BREAKPOINT}) {
    padding: ${theme.space.md.value} ${theme.space.xl.value} ${theme.space['2xl'].value};
  }
`

const StyledLink = styled.button`
  padding: 0;
  color: inherit;
  text-decoration: underline;
  outline: none;
  background: none;
  cursor: pointer;
  border: none;
`
