import { useAuth0 } from '@auth0/auth0-react'
import { useEffect } from 'react'

import { useLinkTeamMemberMutation } from '../generated/graphql'
import { useUserInfo } from '../providers/UserInfoProvider'
import { logFailureTo } from '../utils/exceptionHandler'

/**
 * It checks if the user is authenticated, has no permissions, and has a Google OAuth2 account, and if so, it links the
 * user's Google OAuth2 account to their primary account
 * @returns A loading state.
 */
export function useMergeUser() {
  const { isAuthenticated, user } = useAuth0()
  const { permissions } = useUserInfo()

  const [linkTeamMember, { loading }] = useLinkTeamMemberMutation({
    onCompleted: data => {
      if (data?.linkTeamMember.status) {
        window.location.replace('/')
      }
    },
    onError: error => {
      window.location.replace('/unauthorized')

      console.error(error)
    },
  })

  useEffect(() => {
    const hasSecondaryAccount = !permissions.length && user?.sub?.startsWith('google-oauth2')

    if (isAuthenticated && hasSecondaryAccount && user?.email) {
      linkTeamMember({
        variables: {
          email: user.email,
        },
      }).catch(logFailureTo('link team member'))
    }
  }, [isAuthenticated, linkTeamMember, permissions.length, user])

  return {
    loading,
  }
}
