import { generatePath } from 'react-router-dom'

export const basePath = '/tpcs'

export const RoutePath = {
  Search: basePath,
  ViewUserHealthProfile: basePath + '/users/:userId/health-profiles',
  EditAccount: basePath + '/users/:userId/edit',
  ViewOrderDetails: basePath + '/orders/:orderId',
  ViewUserAddresses: basePath + '/users/:userId/addresses',
  ViewUserOrders: basePath + '/users/:userId/orders',
  ViewUserInsurances: basePath + '/users/:userId/insurances',
  ViewUserSubcriptions: basePath + '/users/:userId/subscriptions',
  ViewUser: basePath + '/users/:userId',
  ViewUserCopays: basePath + '/users/:userId/copays',
  SearchTeam: '/team',

  Unauthorized: '/unauthorized',
  Callback: '/callback',
}

type UserId = {
  userId: string
}
export const goToUserHealthProfile = ({ userId }: UserId) => generatePath(RoutePath.ViewUserHealthProfile, { userId })

export const goToUserAddresses = ({ userId }: UserId) => generatePath(RoutePath.ViewUserAddresses, { userId })

export const goToUserOrders = ({ userId }: UserId) => generatePath(RoutePath.ViewUserOrders, { userId })

export const goToUserInsurances = ({ userId }: UserId) => generatePath(RoutePath.ViewUserInsurances, { userId })

export const goToUserSubscriptions = ({ userId }: UserId) => generatePath(RoutePath.ViewUserSubcriptions, { userId })

export const goToUserCopays = ({ userId }: UserId) => generatePath(RoutePath.ViewUserCopays, { userId })

export const goToViewUser = ({ userId }: UserId) => {
  return generatePath(RoutePath.ViewUser, { userId })
}

export const goToEditAccount = ({ userId }: UserId) => generatePath(RoutePath.EditAccount, { userId })

export const goToOrderDetails = ({ orderId }: { orderId: string }) =>
  generatePath(RoutePath.ViewOrderDetails, { orderId })
