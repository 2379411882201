import { Logo } from 'components'
import { FC } from 'react'
import styled from 'styled-components'

import { NAV_MENU_BREAKPOINT } from '../../../styles/styleVariables'
import { UserAvatar } from '../UserAvatar'
import { NavButtonList } from './NavButtonList'

export const Navigation: FC = () => {
  return (
    <AppNav>
      <NavBox>
        <Logo />
        <NavButtonList />
      </NavBox>
      <RightBox>
        <UserAvatar />
      </RightBox>
    </AppNav>
  )
}

const RightBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  > button {
    margin-right: 0.625rem;
  }
`

const AppNav = styled.div`
  height: 4rem;
  padding: 0 20px;
  @media screen and (min-width: ${NAV_MENU_BREAKPOINT}) {
    padding: 0 2rem;
  }
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  user-select: none;
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.2);
  position: relative;
  z-index: 10;
`

const NavBox = styled.div`
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  @media screen and (min-width: ${NAV_MENU_BREAKPOINT}) {
    justify-content: left;
  }
  gap: 3rem;
`
