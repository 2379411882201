import { PaneContext, PaneProviderProps, PaneType } from 'providers/PaneProvider'
import { useContext } from 'react'

const usePane = (inputPaneType: PaneType) => {
  const { paneVisible, firstLoad, togglePane, hidePane, showPane, paneType } = useContext(
    PaneContext
  ) as PaneProviderProps

  return {
    paneType,
    paneVisible: paneVisible && paneType === inputPaneType,
    firstLoad,
    togglePane: () => {
      togglePane(inputPaneType)
    },
    showPane: () => {
      showPane(inputPaneType)
    },
    hidePane: () => {
      hidePane(inputPaneType)
    },
  }
}

export default usePane
