import 'typeface-roboto'
import '@reach/tooltip/styles.css'
import '@reach/dialog/styles.css'

import { StrictMode } from 'react'
import * as ReactDOM from 'react-dom'
import { HelmetProvider } from 'react-helmet-async'
import { BrowserRouter } from 'react-router-dom'

import { App } from './App'
import { ApolloProviderWithAuth0 } from './libs/apollo'
import { AuthProvider } from './libs/auth0'
import { QueryParamProvider } from './libs/query-params'
import * as serviceWorker from './serviceWorker'
ReactDOM.render(
  <HelmetProvider>
    <BrowserRouter>
      <QueryParamProvider>
        <AuthProvider>
          <ApolloProviderWithAuth0>
            <StrictMode>
              <App />
            </StrictMode>
          </ApolloProviderWithAuth0>
        </AuthProvider>
      </QueryParamProvider>
    </BrowserRouter>
  </HelmetProvider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below.
// Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
