import { useIsMountedRef } from 'hooks/useIsMountedRef'
import { createContext, ReactNode, useEffect, useReducer } from 'react'

interface Funcs {
  togglePane: (paneType: PaneType) => void
  showPane: (paneType: PaneType) => void
  hidePane: (paneType: PaneType) => void
  setFirstLoad: (newFirstLoad: boolean) => void
}

export interface PaneProviderProps extends Funcs {
  paneType: PaneType
  paneVisible: boolean
  firstLoad: boolean
}

export const PaneContext = createContext<Partial<PaneProviderProps>>({
  paneVisible: false,
  firstLoad: true,
})

export type PaneType = 'Message' | 'Image' | 'Note' | 'Attachments'

interface PaneReducerState {
  visible: boolean
  paneType: PaneType
  firstLoad: boolean
}

type PaneAction = {
  type: 'hide' | 'show' | 'toggle' | 'setFirstLoad'
  paneType: PaneType
}

const PaneReducer = (state: PaneReducerState, action: PaneAction) => {
  const { visible, paneType: currentPaneType } = state
  const { type, paneType } = action

  switch (type) {
    case 'hide':
      return { visible: false, paneType, firstLoad: false }
    case 'toggle':
      // Toggling from a different pane type should always show the pane
      // otherwise no change
      if (paneType === currentPaneType) {
        return { visible: !visible, paneType, firstLoad: false }
      } else {
        return { visible: true, paneType, firstLoad: false }
      }
    case 'show':
      return { visible: true, paneType, firstLoad: false }
    case 'setFirstLoad':
      return { ...state, firstLoad: true }
    default:
      return state
  }
}

const PaneProvider = (props: { children: ReactNode }) => {
  const isMountedRef = useIsMountedRef()
  const [{ visible, paneType, firstLoad }, dispatch] = useReducer(PaneReducer, {
    visible: false,
    paneType: 'Message',
    firstLoad: true,
  })

  useEffect(() => {
    if (isMountedRef.current) {
      dispatch({ type: 'setFirstLoad', paneType: 'Message' })
    }
  }, [isMountedRef, dispatch])

  const value = {
    paneType,
    paneVisible: visible,
    firstLoad,
    togglePane: (paneType: PaneType) => {
      dispatch({ type: 'toggle', paneType })
    },
    showPane: (paneType: PaneType) => {
      dispatch({ type: 'show', paneType })
    },
    hidePane: (paneType: PaneType) => {
      dispatch({ type: 'hide', paneType })
    },
  }

  return <PaneContext.Provider value={value} {...props} />
}

export default PaneProvider
