import { useUserInfo } from 'providers/UserInfoProvider'
import { FC, ReactNode, useMemo } from 'react'

import { Permissions } from '../../constants'
import Unauthorized from '../../pages/unauthorized'

type Props = {
  hasContent?: boolean
  requiredPermissions: Permissions | Permissions[]
  children: ReactNode
}

/* A React component that checks if the user has the required permissions to access the content. */
export const PermissionBasedGuard: FC<Props> = ({ hasContent = false, requiredPermissions, children }) => {
  const { permissions } = useUserInfo()

  const hasAccess: boolean = useMemo(() => {
    if (requiredPermissions instanceof Array) {
      return requiredPermissions.every(permission => permissions.includes(permission))
    }

    return permissions.includes(requiredPermissions)
  }, [permissions, requiredPermissions])

  if (!hasAccess) {
    return hasContent ? <Unauthorized /> : null
  }

  return <>{children}</>
}
