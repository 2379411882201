import { Header, Spacer, Text } from '@truepill/react-capsule'
import React, { FC } from 'react'
import styled from 'styled-components'

import { ServerErrorIllustration } from '../assets'
import { Page } from '../components'

const StyledRoot = styled('div')({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  height: '100%',
  paddingTop: '120px',
})

const StyledContainer = styled('div')({
  maxWidth: '480px',
  margin: 'auto',
  textAlign: 'center',
})

const StyledIllustration = styled('div')({
  height: '260px',
})

const Page500: FC = () => {
  return (
    <Page title="500 Server Error">
      <StyledRoot>
        <StyledContainer>
          <Header variant="4xl">Sorry, the server is not available!</Header>
          <Spacer />
          <Text>Please try again later. If the problem persists, please contact the site administrator.</Text>
          <Spacer />
          <StyledIllustration>
            <ServerErrorIllustration />
          </StyledIllustration>

          <Spacer />
        </StyledContainer>
      </StyledRoot>
    </Page>
  )
}

export default Page500
