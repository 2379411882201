import { Text } from '@truepill/react-capsule'
import { FC } from 'react'

import { SimpleModal } from '../components'
import { useAuth } from '../hooks'
import { useModalContext } from '../providers/ModalProvider'

const Unauthorized: FC = () => {
  const { dismissModal } = useModalContext()
  const { handleLogout } = useAuth()

  const handleReLogin = () => {
    dismissModal()
    handleLogout()
  }

  return (
    <SimpleModal title="Unauthorized access" confirmButtonLabel="Login" confirmationCallback={handleReLogin}>
      <Text>
        Sorry, you don&apos;t have permissions to access this site. Please contact an administrator if you have received
        this message in error.
      </Text>
    </SimpleModal>
  )
}

export default Unauthorized
